import { GenericPage } from "../../../../src/components/mdx/page";
import { StockMarketChart, BondMarketChart, CommoditiesChart, CurrenciesChart, EconomyChart } from "../../../../src/components/widgets/indicators";
import styled from "styled-components";
import { Breadcrumbs } from "../../../../src/components/widgets/breadcrumbs";
import { Aside } from "../../../../src/components/widgets/aside";
import { Border } from "../../../../src/components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../src/components/widgets/button";
import * as React from 'react';
export default {
  GenericPage,
  StockMarketChart,
  BondMarketChart,
  CommoditiesChart,
  CurrenciesChart,
  EconomyChart,
  styled,
  Breadcrumbs,
  Aside,
  Border,
  ButtonPrimary,
  ButtonSecondary,
  React
};