//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/mdx/shortcodes.js
// Description: Default shortcodes for MDX page layouts.
// Created:     FUB, Apr 19, 2022
//==============================================================================

//import React from "react"
import { Link } from "../widgets/link"

const mappings = {
    // p	Paragraph	
    // h1	Heading 1	#
    // h2	Heading 2	##
    // h3	Heading 3	###
    // h4	Heading 4	####
    // h5	Heading 5	#####
    // h6	Heading 6	######
    // thematicBreak	Thematic break	***
    // blockquote	Blockquote	>
    // ul	List	-
    // ol	Ordered list	1.
    // li	List item	
    // table	Table	`---
    // tr	Table row	`This
    // td/th	Table cell	
    // pre	Pre	
    // code	Code	
    // em	Emphasis	_emphasis_
    // strong	Strong	**strong**
    // delete	Delete	~~strikethrough~~
    // inlineCode	InlineCode	
    // hr	Break	---
    // a	Link	<https://mdxjs.com> or [MDX](https://mdxjs.com)
    a: Link, 
    // img	Image	![alt](https://mdx-logo.now.sh)
}

const shortcodes = {
    // nothing here, yet
}

export const components = {
    ...mappings,
    ...shortcodes,
}

//==============================================================================
// end of file
