import { StaticImage } from "gatsby-plugin-image";
import { GenericPage } from "../../../../src/components/mdx/page";
import { Accordion } from "../../../../src/components/widgets/accordion";
import { HelpPopup } from "../../../../src/components/widgets/help-popup";
import { Logo } from "../../../../src/components/widgets/tt-branding";
import { ContentPlusDecor } from "../../../../src/components/widgets/content-plus-decor";
import * as React from 'react';
export default {
  StaticImage,
  GenericPage,
  Accordion,
  HelpPopup,
  Logo,
  ContentPlusDecor,
  React
};