//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/popup
// Description: Popup dialog
// Created:     FUB, May 05, 2022
//==============================================================================

import React from "react"

import { Container } from "./container"

//------------------------------------------------------------------------------
export const Popup = ({ title, children, onCancel, className }) => {
    const clickHandler = () => onCancel()
    const theTitle = title ? title :
        (onCancel ? (<span>&nbsp;</span>) : null)

    return (<>
        {onCancel && (<div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            css={`
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                background-color: rgba(0, 0, 0, 0.6);
                /*background-color: pink;*/ /* set this for screenshots */
                z-index: 999;
            `}
            onClick={clickHandler} />
        )}

        <div css={`
            position: fixed;
            top: 50%;
            left:50%;
            transform: translate(-50%, -50%);
            /*box-shadow: 0px 0px 10px white, 0px 0px 50vw 10vw black;*/
            /*box-shadow: 0px 0px 10px white, 0px 0px 50vw 15vw black;*/
            box-shadow: ${props => props.theme.shadows.popup};/* remove this for screenshots */
            min-height: 10vh;
            /*width: 50%;*/
            min-width: 250px;
            max-width: 90%;
            background-color: ${props => props.theme.colors.bgCanvas};
            border: ${props => props.theme.dimensions.lineThickness} solid ${props => props.theme.colors.active};
            border-radius: ${props => props.theme.dimensions.cornerRadius};
            overflow: hidden;
            z-index: 999;
            `}>
            <div css={`
                position: relative;
                color: ${props => props.theme.colors.accent};
                background: ${props => props.theme.gradients.light};
                padding-left: ${props => props.theme.spacings.normal};
                font-weight: bold;
                border-bottom: 1px solid ${props => props.theme.colors.decorative};
            `}>
                {theTitle}
                {onCancel && (<div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    css={`
                        position: absolute;
                        top: 0;
                        right: 0.25em;
                        color: ${props => props.theme.colors.active};
                        cursor: pointer;
                    `}
                    onClick={clickHandler}
                >X</div>)}
            </div>

            <Container className={className}>
                {children}
            </Container>
        </div>
    </>)
}

//==============================================================================
// end of file
