//==============================================================================
// Project:     www.TuringTrader.com
// Name:        utils/fetch-helpers.js
// Description: Utility functions for fetch.
// History:     2022v04, FUB, created
//==============================================================================

// see https://developers.google.com/web/updates/2015/03/introduction-to-fetch
export const status = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response)
    } else {
        return Promise.reject(new Error(response.statusText))
    }
}

export const json = (response) => {
    return response.json()
}

export const text = (response) => {
    return response.text()
}

//==============================================================================
// end of file
