import { useState, useEffect } from "react";
import { GenericPage } from "../../../../src/components/mdx/page";
import { Breadcrumbs } from "../../../../src/components/widgets/breadcrumbs";
import { ContentPlusDecor } from "../../../../src/components/widgets/content-plus-decor";
import { FaEnvelope } from "react-icons/fa";
import { ButtonPrimary } from "../../../../src/components/widgets/button";
import { Accordion } from "../../../../src/components/widgets/accordion";
import * as React from 'react';
export default {
  useState,
  useEffect,
  GenericPage,
  Breadcrumbs,
  ContentPlusDecor,
  FaEnvelope,
  ButtonPrimary,
  Accordion,
  React
};