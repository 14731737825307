//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/theme.js
// Description: Global layout theme.
// Created:     FUB, Apr 16, 2022
//==============================================================================

/*
// color-palette creator: https://paletton.com/#uid=30s0u0kw0w0jyC+oRxVy4oIDfjr
// base RGB: FF5900 (hue 20deg)
// color scheme: Triad w/ 30deg distance
// preset: full colors
const colorsOrange = {
    // orange
    base: "#FF5900",
    lighter: ["#FF7E39", "#FF9A63"],
    darker: ["#C54500", "#9B3600"],
}
const colorsBlue = {
    // original TT-Blue:
    //     #0366d6 - "hsl(212,97%,43%)"
    //     #3584de lighter
    //     #024795 darker
    base: "#0D56A6",
    lighter: ["#316BAC", "#5385BD"], 
    darker: ["#0A4281", "#063365"], 
}
const colorsGreen = {
    // original TT-Green:
    // #04cc50 - hsl(143, 96%, 41%)
    base: "#4DDE00",
    lighter: ["#6FE032", "#8BE65A"],
    darker: ["#3CAB00", "#2F8700"]
}
/**/

/**/
// color-palette creator: https://paletton.com/#uid=30s0u0kw0w0jyC+oRxVy4oIDfjr
// base RGB: FF6F00 (hue 28deg)
// color scheme: Triad w/ 30deg distance
// preset: full colors
const colorsOrange = {
    // orange
    base: "#ff6f00", // hsl(26, 100%, 50%)
    lighter: [
        "#ff8f39", // hsl(26, 100%, 61%)
        "#ffa763", // hsl(26, 100%, 69%)
    ],
    darker: [
        "#c55600", // hsl(26, 100%, 39%)
        "#9b4300", // hsl(26, 100%, 30%)
    ],
}
const colorsBlue = {
    // original TT-Blue:
    //     #0366d6 - "hsl(212,97%,43%)"
    //     #3584de TT-Blue (lighter)
    //     #024795 TT-Blue (darker)
    base: "#1144aa", // hsl(220, 82%, 37%)
    lighter: [
        "#355eaf", // hsl(220, 54%, 45%)
        "#5679bf", // hsl(220, 45%, 54%)
    ], 
    darker: [
        "#0c3483", // hsl(220, 83%, 28%)
        "#082867", // hsl(220, 86%, 22%)
    ], 
}
const colorsGreen = {
    // original TT-Green:
    // #04cc50 - hsl(143, 96%, 41%)
    base: "#14d100", // hsl(114,100%,41%)
    lighter: [
        "#3fd42f", // hsl(114, 66%, 51%)
        "#63dc56", // hsl(114, 66%, 60%)
    ],
    darker: [
        "#0fa100", // hsl(114, 100%, 32%)
        "#0c7f00", // hsl(114, 100%, 25%)
    ]
}
/**/

export const theme = {
    fonts: {
        //main: "Open Sans", // disabled - importing font in components/layout/global-styles.js
    },
    colors: {
        bgWindow: "hsl(207, 8%, 95%)",
        bgCanvas: "hsl(207, 8%, 98%)",
        bgHighlight: "hsl(212,50%,92%)",
        //text: "hsl(207, 8%, 10%)",
        text: "hsl(220, 75%, 8%)",
        textLight: "hsl(207, 8%, 40%)",
        accent: colorsBlue.base,
        active: colorsBlue.lighter[0],
        decorative: colorsOrange.base,
        cta: colorsGreen.base,
        orange: colorsOrange,
        blue: colorsBlue,
        green: colorsGreen,
    },
    breakpoints: {
        //desktop: "only screen and (min-width: 25rem)", // 400px
        desktop: "only screen and (min-width: 37.5rem)", // 600px
        //mobile: "only screen and (max-width: 50rem)",
        //tablet: "only screen and (max-width: 65rem)",
    },
    dimensions: {
        maxWidth: "1000px",
        //maxWidth: "800px",
        lineThickness: "0.25rem",
        cornerRadius: "0.5rem",
        largeIcon: "4rem",
    },
    spacings: {
        normal: "1rem", // usually 16px
        negNormal: "-1rem",
        /*xxSmall: ".25rem",
        xSmall: ".5rem",
        small: "1rem",
        medium: "2rem",
        large: "3rem",*/
    },
    gradients: {
        //light: "linear-gradient(135deg, #0366d630 0%, #ffffff10 100%)" // original
        light: `linear-gradient(135deg, ${colorsBlue.lighter[0]}30 0%, #ffffff10 100%)`,
    },
    shadows: {
        //box: "0px 5px 20px rgba(30, 30, 31, 0.05)",
        box: "2px 2px 5px 1px hsl(212,8%,60%)",
        popup: "10px 10px 10px 5px hsl(212,8%,10%)",
    },
}

//==============================================================================
// end of file
