//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/breadcrumbs
// Description: Widget to show breadcrumbs
// Created:     FUB, May 27, 2022
//==============================================================================

import React from "react"
//import styled from "styled-components"
import { FaBackward } from "react-icons/fa"
import { Link } from "./link"


export const Breadcrumbs = ({ to, text }) => {
    return (
        <div
            css={`
                font-size: small;
                margin-top: -1.0em;
            `}
        >
            <Link href={to}>
                {/*&lt;&lt;&lt;&nbsp;Back to {text}*/}
                <FaBackward/>&nbsp;&nbsp;Back to {text}
                </Link>
        </div>
    )
}

//==============================================================================
// end of file
