//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/portfolio/cumulative-returns
// Description: Widgets for portfolio display
// Created:     FUB, May 05, 2022
//==============================================================================

import React from "react"
import styled from "styled-components"

import { usePortfolioData } from "../../../utils/portfolio-data"
import { Lightbox } from "../lightbox"

//------------------------------------------------------------------------------
const Img = styled.img`
    width: 100%;
`

//------------------------------------------------------------------------------
export const RollingReturns = ({ portfolio }) => {
    const data = usePortfolioData(portfolio)
    const imgUrl = data?.rollingReturnChart

    /*return imgUrl ? (<div>
        <Img
            src={imgUrl}
            alt="TuringTrader.com"
        />
    </div>) : null*/

    return imgUrl ? (<div>
        <Lightbox title="Rolling Returns & Tracking to Benchmark" src={imgUrl}>
            <Img
                src={imgUrl}
                alt="TuringTrader.com"
            />
        </Lightbox>
    </div>) : null

}

//==============================================================================
// end of file
