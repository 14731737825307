exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-articles-30-minute-stock-trader-index-mdx": () => import("./../../../src/pages/articles/30-minute-stock-trader/index.mdx" /* webpackChunkName: "component---src-pages-articles-30-minute-stock-trader-index-mdx" */),
  "component---src-pages-articles-all-stars-portfolios-index-mdx": () => import("./../../../src/pages/articles/all-stars-portfolios/index.mdx" /* webpackChunkName: "component---src-pages-articles-all-stars-portfolios-index-mdx" */),
  "component---src-pages-articles-backtesting-index-mdx": () => import("./../../../src/pages/articles/backtesting/index.mdx" /* webpackChunkName: "component---src-pages-articles-backtesting-index-mdx" */),
  "component---src-pages-articles-bonds-rising-yields-index-mdx": () => import("./../../../src/pages/articles/bonds-rising-yields/index.mdx" /* webpackChunkName: "component---src-pages-articles-bonds-rising-yields-index-mdx" */),
  "component---src-pages-articles-crypto-etps-index-mdx": () => import("./../../../src/pages/articles/crypto-etps/index.mdx" /* webpackChunkName: "component---src-pages-articles-crypto-etps-index-mdx" */),
  "component---src-pages-articles-improving-momentum-strategies-index-mdx": () => import("./../../../src/pages/articles/improving-momentum-strategies/index.mdx" /* webpackChunkName: "component---src-pages-articles-improving-momentum-strategies-index-mdx" */),
  "component---src-pages-articles-index-mdx": () => import("./../../../src/pages/articles/index.mdx" /* webpackChunkName: "component---src-pages-articles-index-mdx" */),
  "component---src-pages-articles-leveraged-etf-portfolios-2-index-mdx": () => import("./../../../src/pages/articles/leveraged-etf-portfolios-2/index.mdx" /* webpackChunkName: "component---src-pages-articles-leveraged-etf-portfolios-2-index-mdx" */),
  "component---src-pages-articles-leveraged-etf-portfolios-index-mdx": () => import("./../../../src/pages/articles/leveraged-etf-portfolios/index.mdx" /* webpackChunkName: "component---src-pages-articles-leveraged-etf-portfolios-index-mdx" */),
  "component---src-pages-articles-live-trading-vs-backtests-index-mdx": () => import("./../../../src/pages/articles/live-trading-vs-backtests/index.mdx" /* webpackChunkName: "component---src-pages-articles-live-trading-vs-backtests-index-mdx" */),
  "component---src-pages-articles-low-noise-momentum-index-mdx": () => import("./../../../src/pages/articles/low-noise-momentum/index.mdx" /* webpackChunkName: "component---src-pages-articles-low-noise-momentum-index-mdx" */),
  "component---src-pages-articles-market-liquidity-index-mdx": () => import("./../../../src/pages/articles/market-liquidity/index.mdx" /* webpackChunkName: "component---src-pages-articles-market-liquidity-index-mdx" */),
  "component---src-pages-articles-market-vane-index-mdx": () => import("./../../../src/pages/articles/market-vane/index.mdx" /* webpackChunkName: "component---src-pages-articles-market-vane-index-mdx" */),
  "component---src-pages-articles-momentum-mean-reversion-index-mdx": () => import("./../../../src/pages/articles/momentum-mean-reversion/index.mdx" /* webpackChunkName: "component---src-pages-articles-momentum-mean-reversion-index-mdx" */),
  "component---src-pages-articles-portfolio-wizard-index-mdx": () => import("./../../../src/pages/articles/portfolio-wizard/index.mdx" /* webpackChunkName: "component---src-pages-articles-portfolio-wizard-index-mdx" */),
  "component---src-pages-articles-review-2021-index-mdx": () => import("./../../../src/pages/articles/review-2021/index.mdx" /* webpackChunkName: "component---src-pages-articles-review-2021-index-mdx" */),
  "component---src-pages-articles-review-2022-index-mdx": () => import("./../../../src/pages/articles/review-2022/index.mdx" /* webpackChunkName: "component---src-pages-articles-review-2022-index-mdx" */),
  "component---src-pages-articles-review-2023-index-mdx": () => import("./../../../src/pages/articles/review-2023/index.mdx" /* webpackChunkName: "component---src-pages-articles-review-2023-index-mdx" */),
  "component---src-pages-articles-tax-efficient-investing-index-mdx": () => import("./../../../src/pages/articles/tax-efficient-investing/index.mdx" /* webpackChunkName: "component---src-pages-articles-tax-efficient-investing-index-mdx" */),
  "component---src-pages-articles-the-alpha-formula-index-mdx": () => import("./../../../src/pages/articles/the-alpha-formula/index.mdx" /* webpackChunkName: "component---src-pages-articles-the-alpha-formula-index-mdx" */),
  "component---src-pages-articles-volatility-targeting-index-mdx": () => import("./../../../src/pages/articles/volatility-targeting/index.mdx" /* webpackChunkName: "component---src-pages-articles-volatility-targeting-index-mdx" */),
  "component---src-pages-company-contact-index-mdx": () => import("./../../../src/pages/company/contact/index.mdx" /* webpackChunkName: "component---src-pages-company-contact-index-mdx" */),
  "component---src-pages-company-disclaimer-index-mdx": () => import("./../../../src/pages/company/disclaimer/index.mdx" /* webpackChunkName: "component---src-pages-company-disclaimer-index-mdx" */),
  "component---src-pages-company-index-mdx": () => import("./../../../src/pages/company/index.mdx" /* webpackChunkName: "component---src-pages-company-index-mdx" */),
  "component---src-pages-company-newsletter-2023-01-index-mdx": () => import("./../../../src/pages/company/newsletter/2023/01/index.mdx" /* webpackChunkName: "component---src-pages-company-newsletter-2023-01-index-mdx" */),
  "component---src-pages-company-newsletter-2023-02-index-mdx": () => import("./../../../src/pages/company/newsletter/2023/02/index.mdx" /* webpackChunkName: "component---src-pages-company-newsletter-2023-02-index-mdx" */),
  "component---src-pages-company-newsletter-2023-03-index-mdx": () => import("./../../../src/pages/company/newsletter/2023/03/index.mdx" /* webpackChunkName: "component---src-pages-company-newsletter-2023-03-index-mdx" */),
  "component---src-pages-company-newsletter-2023-04-index-mdx": () => import("./../../../src/pages/company/newsletter/2023/04/index.mdx" /* webpackChunkName: "component---src-pages-company-newsletter-2023-04-index-mdx" */),
  "component---src-pages-company-newsletter-2023-05-index-mdx": () => import("./../../../src/pages/company/newsletter/2023/05/index.mdx" /* webpackChunkName: "component---src-pages-company-newsletter-2023-05-index-mdx" */),
  "component---src-pages-company-newsletter-2023-06-index-mdx": () => import("./../../../src/pages/company/newsletter/2023/06/index.mdx" /* webpackChunkName: "component---src-pages-company-newsletter-2023-06-index-mdx" */),
  "component---src-pages-company-newsletter-2023-08-index-mdx": () => import("./../../../src/pages/company/newsletter/2023/08/index.mdx" /* webpackChunkName: "component---src-pages-company-newsletter-2023-08-index-mdx" */),
  "component---src-pages-company-newsletter-2023-09-index-mdx": () => import("./../../../src/pages/company/newsletter/2023/09/index.mdx" /* webpackChunkName: "component---src-pages-company-newsletter-2023-09-index-mdx" */),
  "component---src-pages-company-newsletter-2023-10-index-mdx": () => import("./../../../src/pages/company/newsletter/2023/10/index.mdx" /* webpackChunkName: "component---src-pages-company-newsletter-2023-10-index-mdx" */),
  "component---src-pages-company-newsletter-2023-11-index-mdx": () => import("./../../../src/pages/company/newsletter/2023/11/index.mdx" /* webpackChunkName: "component---src-pages-company-newsletter-2023-11-index-mdx" */),
  "component---src-pages-company-newsletter-2023-12-index-mdx": () => import("./../../../src/pages/company/newsletter/2023/12/index.mdx" /* webpackChunkName: "component---src-pages-company-newsletter-2023-12-index-mdx" */),
  "component---src-pages-company-newsletter-2024-01-index-mdx": () => import("./../../../src/pages/company/newsletter/2024/01/index.mdx" /* webpackChunkName: "component---src-pages-company-newsletter-2024-01-index-mdx" */),
  "component---src-pages-company-newsletter-index-mdx": () => import("./../../../src/pages/company/newsletter/index.mdx" /* webpackChunkName: "component---src-pages-company-newsletter-index-mdx" */),
  "component---src-pages-company-privacy-policy-index-mdx": () => import("./../../../src/pages/company/privacy-policy/index.mdx" /* webpackChunkName: "component---src-pages-company-privacy-policy-index-mdx" */),
  "component---src-pages-company-referral-program-index-mdx": () => import("./../../../src/pages/company/referral-program/index.mdx" /* webpackChunkName: "component---src-pages-company-referral-program-index-mdx" */),
  "component---src-pages-company-sub-processors-index-mdx": () => import("./../../../src/pages/company/sub-processors/index.mdx" /* webpackChunkName: "component---src-pages-company-sub-processors-index-mdx" */),
  "component---src-pages-company-terms-of-use-index-mdx": () => import("./../../../src/pages/company/terms-of-use/index.mdx" /* webpackChunkName: "component---src-pages-company-terms-of-use-index-mdx" */),
  "component---src-pages-company-writers-index-mdx": () => import("./../../../src/pages/company/writers/index.mdx" /* webpackChunkName: "component---src-pages-company-writers-index-mdx" */),
  "component---src-pages-dashboard-indicators-index-mdx": () => import("./../../../src/pages/dashboard/indicators/index.mdx" /* webpackChunkName: "component---src-pages-dashboard-indicators-index-mdx" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-dashboard-market-vane-index-mdx": () => import("./../../../src/pages/dashboard/market-vane/index.mdx" /* webpackChunkName: "component---src-pages-dashboard-market-vane-index-mdx" */),
  "component---src-pages-dashboard-rebalance-index-js": () => import("./../../../src/pages/dashboard/rebalance/index.js" /* webpackChunkName: "component---src-pages-dashboard-rebalance-index-js" */),
  "component---src-pages-dashboard-total-index-js": () => import("./../../../src/pages/dashboard/total/index.js" /* webpackChunkName: "component---src-pages-dashboard-total-index-js" */),
  "component---src-pages-help-account-groups-index-mdx": () => import("./../../../src/pages/help/account-groups/index.mdx" /* webpackChunkName: "component---src-pages-help-account-groups-index-mdx" */),
  "component---src-pages-help-account-rebalancing-index-mdx": () => import("./../../../src/pages/help/account-rebalancing/index.mdx" /* webpackChunkName: "component---src-pages-help-account-rebalancing-index-mdx" */),
  "component---src-pages-help-api-index-mdx": () => import("./../../../src/pages/help/api/index.mdx" /* webpackChunkName: "component---src-pages-help-api-index-mdx" */),
  "component---src-pages-help-brokerage-account-index-mdx": () => import("./../../../src/pages/help/brokerage-account/index.mdx" /* webpackChunkName: "component---src-pages-help-brokerage-account-index-mdx" */),
  "component---src-pages-help-charts-metrics-index-mdx": () => import("./../../../src/pages/help/charts-metrics/index.mdx" /* webpackChunkName: "component---src-pages-help-charts-metrics-index-mdx" */),
  "component---src-pages-help-find-your-portfolio-index-mdx": () => import("./../../../src/pages/help/find-your-portfolio/index.mdx" /* webpackChunkName: "component---src-pages-help-find-your-portfolio-index-mdx" */),
  "component---src-pages-help-getting-started-index-mdx": () => import("./../../../src/pages/help/getting-started/index.mdx" /* webpackChunkName: "component---src-pages-help-getting-started-index-mdx" */),
  "component---src-pages-help-habits-for-success-index-mdx": () => import("./../../../src/pages/help/habits-for-success/index.mdx" /* webpackChunkName: "component---src-pages-help-habits-for-success-index-mdx" */),
  "component---src-pages-help-index-mdx": () => import("./../../../src/pages/help/index.mdx" /* webpackChunkName: "component---src-pages-help-index-mdx" */),
  "component---src-pages-help-investment-dashboard-index-mdx": () => import("./../../../src/pages/help/investment-dashboard/index.mdx" /* webpackChunkName: "component---src-pages-help-investment-dashboard-index-mdx" */),
  "component---src-pages-help-investors-outside-us-index-mdx": () => import("./../../../src/pages/help/investors-outside-us/index.mdx" /* webpackChunkName: "component---src-pages-help-investors-outside-us-index-mdx" */),
  "component---src-pages-help-managed-accounts-index-mdx": () => import("./../../../src/pages/help/managed-accounts/index.mdx" /* webpackChunkName: "component---src-pages-help-managed-accounts-index-mdx" */),
  "component---src-pages-help-member-info-mdx": () => import("./../../../src/pages/help/member-info.mdx" /* webpackChunkName: "component---src-pages-help-member-info-mdx" */),
  "component---src-pages-help-professional-advisors-index-mdx": () => import("./../../../src/pages/help/professional-advisors/index.mdx" /* webpackChunkName: "component---src-pages-help-professional-advisors-index-mdx" */),
  "component---src-pages-help-status-mdx": () => import("./../../../src/pages/help/status.mdx" /* webpackChunkName: "component---src-pages-help-status-mdx" */),
  "component---src-pages-help-tactical-asset-allocation-index-mdx": () => import("./../../../src/pages/help/tactical-asset-allocation/index.mdx" /* webpackChunkName: "component---src-pages-help-tactical-asset-allocation-index-mdx" */),
  "component---src-pages-help-what-to-expect-index-mdx": () => import("./../../../src/pages/help/what-to-expect/index.mdx" /* webpackChunkName: "component---src-pages-help-what-to-expect-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-no-signup-mdx": () => import("./../../../src/pages/no-signup.mdx" /* webpackChunkName: "component---src-pages-no-signup-mdx" */),
  "component---src-pages-portfolios-antonacci-dual-momentum-index-mdx": () => import("./../../../src/pages/portfolios/antonacci-dual-momentum/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-antonacci-dual-momentum-index-mdx" */),
  "component---src-pages-portfolios-bensdorp-weekly-rotation-index-mdx": () => import("./../../../src/pages/portfolios/bensdorp-weekly-rotation/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-bensdorp-weekly-rotation-index-mdx" */),
  "component---src-pages-portfolios-browne-permanent-portfolio-index-mdx": () => import("./../../../src/pages/portfolios/browne-permanent-portfolio/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-browne-permanent-portfolio-index-mdx" */),
  "component---src-pages-portfolios-classic-60-40-index-mdx": () => import("./../../../src/pages/portfolios/classic-60-40/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-classic-60-40-index-mdx" */),
  "component---src-pages-portfolios-clenow-stocks-on-the-move-index-mdx": () => import("./../../../src/pages/portfolios/clenow-stocks-on-the-move/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-clenow-stocks-on-the-move-index-mdx" */),
  "component---src-pages-portfolios-connors-alpha-portfolio-index-mdx": () => import("./../../../src/pages/portfolios/connors-alpha-portfolio/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-connors-alpha-portfolio-index-mdx" */),
  "component---src-pages-portfolios-connors-dynamic-treasuries-index-mdx": () => import("./../../../src/pages/portfolios/connors-dynamic-treasuries/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-connors-dynamic-treasuries-index-mdx" */),
  "component---src-pages-portfolios-connors-etf-avalanches-index-mdx": () => import("./../../../src/pages/portfolios/connors-etf-avalanches/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-connors-etf-avalanches-index-mdx" */),
  "component---src-pages-portfolios-connors-rising-assets-index-mdx": () => import("./../../../src/pages/portfolios/connors-rising-assets/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-connors-rising-assets-index-mdx" */),
  "component---src-pages-portfolios-connors-tps-index-mdx": () => import("./../../../src/pages/portfolios/connors-tps/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-connors-tps-index-mdx" */),
  "component---src-pages-portfolios-connors-vix-rsi-index-mdx": () => import("./../../../src/pages/portfolios/connors-vix-rsi/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-connors-vix-rsi-index-mdx" */),
  "component---src-pages-portfolios-connors-weekly-mean-reversion-index-mdx": () => import("./../../../src/pages/portfolios/connors-weekly-mean-reversion/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-connors-weekly-mean-reversion-index-mdx" */),
  "component---src-pages-portfolios-ep-accelerating-dual-momentum-index-mdx": () => import("./../../../src/pages/portfolios/ep-accelerating-dual-momentum/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-ep-accelerating-dual-momentum-index-mdx" */),
  "component---src-pages-portfolios-faber-ivy-portfolio-index-mdx": () => import("./../../../src/pages/portfolios/faber-ivy-portfolio/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-faber-ivy-portfolio-index-mdx" */),
  "component---src-pages-portfolios-freeburg-pentad-index-mdx": () => import("./../../../src/pages/portfolios/freeburg-pentad/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-freeburg-pentad-index-mdx" */),
  "component---src-pages-portfolios-heine-bond-model-index-mdx": () => import("./../../../src/pages/portfolios/heine-bond-model/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-heine-bond-model-index-mdx" */),
  "component---src-pages-portfolios-history-js": () => import("./../../../src/pages/portfolios/history.js" /* webpackChunkName: "component---src-pages-portfolios-history-js" */),
  "component---src-pages-portfolios-ilap-commitment-index-mdx": () => import("./../../../src/pages/portfolios/ilap-commitment/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-ilap-commitment-index-mdx" */),
  "component---src-pages-portfolios-ilap-safeguard-index-mdx": () => import("./../../../src/pages/portfolios/ilap-safeguard/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-ilap-safeguard-index-mdx" */),
  "component---src-pages-portfolios-js": () => import("./../../../src/pages/portfolios.js" /* webpackChunkName: "component---src-pages-portfolios-js" */),
  "component---src-pages-portfolios-keller-bold-asset-allocation-index-mdx": () => import("./../../../src/pages/portfolios/keller-bold-asset-allocation/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-keller-bold-asset-allocation-index-mdx" */),
  "component---src-pages-portfolios-keller-classical-asset-allocation-index-mdx": () => import("./../../../src/pages/portfolios/keller-classical-asset-allocation/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-keller-classical-asset-allocation-index-mdx" */),
  "component---src-pages-portfolios-keller-defensive-asset-allocation-index-mdx": () => import("./../../../src/pages/portfolios/keller-defensive-asset-allocation/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-keller-defensive-asset-allocation-index-mdx" */),
  "component---src-pages-portfolios-keller-hybrid-asset-allocation-index-mdx": () => import("./../../../src/pages/portfolios/keller-hybrid-asset-allocation/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-keller-hybrid-asset-allocation-index-mdx" */),
  "component---src-pages-portfolios-keller-lethargic-asset-allocation-index-mdx": () => import("./../../../src/pages/portfolios/keller-lethargic-asset-allocation/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-keller-lethargic-asset-allocation-index-mdx" */),
  "component---src-pages-portfolios-li-universal-investment-strategy-index-mdx": () => import("./../../../src/pages/portfolios/li-universal-investment-strategy/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-li-universal-investment-strategy-index-mdx" */),
  "component---src-pages-portfolios-livingston-mama-bear-index-mdx": () => import("./../../../src/pages/portfolios/livingston-mama-bear/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-livingston-mama-bear-index-mdx" */),
  "component---src-pages-portfolios-livingston-papa-bear-index-mdx": () => import("./../../../src/pages/portfolios/livingston-papa-bear/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-livingston-papa-bear-index-mdx" */),
  "component---src-pages-portfolios-priv-bsol-aferreira-2-index-mdx": () => import("./../../../src/pages/portfolios/priv-bsol-aferreira-2/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-bsol-aferreira-2-index-mdx" */),
  "component---src-pages-portfolios-priv-bsol-ausenko-1-index-mdx": () => import("./../../../src/pages/portfolios/priv-bsol-ausenko-1/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-bsol-ausenko-1-index-mdx" */),
  "component---src-pages-portfolios-priv-bsol-bsay-4-index-mdx": () => import("./../../../src/pages/portfolios/priv-bsol-bsay-4/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-bsol-bsay-4-index-mdx" */),
  "component---src-pages-portfolios-priv-bsol-cchase-1-index-mdx": () => import("./../../../src/pages/portfolios/priv-bsol-cchase-1/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-bsol-cchase-1-index-mdx" */),
  "component---src-pages-portfolios-priv-bsol-cchase-2-index-mdx": () => import("./../../../src/pages/portfolios/priv-bsol-cchase-2/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-bsol-cchase-2-index-mdx" */),
  "component---src-pages-portfolios-priv-bsol-cchase-3-index-mdx": () => import("./../../../src/pages/portfolios/priv-bsol-cchase-3/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-bsol-cchase-3-index-mdx" */),
  "component---src-pages-portfolios-priv-bsol-hmahadevan-1-index-mdx": () => import("./../../../src/pages/portfolios/priv-bsol-hmahadevan-1/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-bsol-hmahadevan-1-index-mdx" */),
  "component---src-pages-portfolios-priv-bsol-jhenneberg-1-index-mdx": () => import("./../../../src/pages/portfolios/priv-bsol-jhenneberg-1/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-bsol-jhenneberg-1-index-mdx" */),
  "component---src-pages-portfolios-priv-bsol-jthomas-1-index-mdx": () => import("./../../../src/pages/portfolios/priv-bsol-jthomas-1/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-bsol-jthomas-1-index-mdx" */),
  "component---src-pages-portfolios-priv-bsol-lsay-1-index-mdx": () => import("./../../../src/pages/portfolios/priv-bsol-lsay-1/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-bsol-lsay-1-index-mdx" */),
  "component---src-pages-portfolios-priv-bsol-msay-1-index-mdx": () => import("./../../../src/pages/portfolios/priv-bsol-msay-1/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-bsol-msay-1-index-mdx" */),
  "component---src-pages-portfolios-priv-bsol-poerter-1-index-mdx": () => import("./../../../src/pages/portfolios/priv-bsol-poerter-1/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-bsol-poerter-1-index-mdx" */),
  "component---src-pages-portfolios-priv-cook-bond-rot-etf-index-mdx": () => import("./../../../src/pages/portfolios/priv-cook-bond-rot-etf/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-cook-bond-rot-etf-index-mdx" */),
  "component---src-pages-portfolios-priv-cook-mean-rev-etf-index-mdx": () => import("./../../../src/pages/portfolios/priv-cook-mean-rev-etf/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-cook-mean-rev-etf-index-mdx" */),
  "component---src-pages-portfolios-priv-cook-mean-rev-oex-index-mdx": () => import("./../../../src/pages/portfolios/priv-cook-mean-rev-oex/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-cook-mean-rev-oex-index-mdx" */),
  "component---src-pages-portfolios-priv-cook-multi-mom-etf-bonds-index-mdx": () => import("./../../../src/pages/portfolios/priv-cook-multi-mom-etf-bonds/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-cook-multi-mom-etf-bonds-index-mdx" */),
  "component---src-pages-portfolios-priv-cook-multi-mom-etf-cash-index-mdx": () => import("./../../../src/pages/portfolios/priv-cook-multi-mom-etf-cash/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-cook-multi-mom-etf-cash-index-mdx" */),
  "component---src-pages-portfolios-priv-cook-multi-mom-etf-commodities-index-mdx": () => import("./../../../src/pages/portfolios/priv-cook-multi-mom-etf-commodities/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-cook-multi-mom-etf-commodities-index-mdx" */),
  "component---src-pages-portfolios-priv-cook-multi-mom-etf-factors-index-mdx": () => import("./../../../src/pages/portfolios/priv-cook-multi-mom-etf-factors/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-cook-multi-mom-etf-factors-index-mdx" */),
  "component---src-pages-portfolios-priv-cook-multi-mom-etf-index-mdx": () => import("./../../../src/pages/portfolios/priv-cook-multi-mom-etf/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-cook-multi-mom-etf-index-mdx" */),
  "component---src-pages-portfolios-priv-cook-multi-mom-etf-sectors-index-mdx": () => import("./../../../src/pages/portfolios/priv-cook-multi-mom-etf-sectors/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-cook-multi-mom-etf-sectors-index-mdx" */),
  "component---src-pages-portfolios-priv-cook-rcv-mom-dji-index-mdx": () => import("./../../../src/pages/portfolios/priv-cook-rcv-mom-dji/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-cook-rcv-mom-dji-index-mdx" */),
  "component---src-pages-portfolios-priv-cook-rcv-mom-ndx-index-mdx": () => import("./../../../src/pages/portfolios/priv-cook-rcv-mom-ndx/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-cook-rcv-mom-ndx-index-mdx" */),
  "component---src-pages-portfolios-priv-cook-rcv-mom-oex-index-mdx": () => import("./../../../src/pages/portfolios/priv-cook-rcv-mom-oex/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-priv-cook-rcv-mom-oex-index-mdx" */),
  "component---src-pages-portfolios-robbins-all-seasons-index-mdx": () => import("./../../../src/pages/portfolios/robbins-all-seasons/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-robbins-all-seasons-index-mdx" */),
  "component---src-pages-portfolios-soto-sector-rotation-index-mdx": () => import("./../../../src/pages/portfolios/soto-sector-rotation/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-soto-sector-rotation-index-mdx" */),
  "component---src-pages-portfolios-tt-all-stars-lev-index-mdx": () => import("./../../../src/pages/portfolios/tt-all-stars-lev/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-all-stars-lev-index-mdx" */),
  "component---src-pages-portfolios-tt-all-stars-monthly-index-mdx": () => import("./../../../src/pages/portfolios/tt-all-stars-monthly/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-all-stars-monthly-index-mdx" */),
  "component---src-pages-portfolios-tt-all-stars-stocks-index-mdx": () => import("./../../../src/pages/portfolios/tt-all-stars-stocks/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-all-stars-stocks-index-mdx" */),
  "component---src-pages-portfolios-tt-all-stars-tax-index-mdx": () => import("./../../../src/pages/portfolios/tt-all-stars-tax/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-all-stars-tax-index-mdx" */),
  "component---src-pages-portfolios-tt-all-stars-tr-index-mdx": () => import("./../../../src/pages/portfolios/tt-all-stars-tr/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-all-stars-tr-index-mdx" */),
  "component---src-pages-portfolios-tt-all-stars-weekly-index-mdx": () => import("./../../../src/pages/portfolios/tt-all-stars-weekly/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-all-stars-weekly-index-mdx" */),
  "component---src-pages-portfolios-tt-all-stars-xl-index-mdx": () => import("./../../../src/pages/portfolios/tt-all-stars-xl/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-all-stars-xl-index-mdx" */),
  "component---src-pages-portfolios-tt-back-to-basics-index-mdx": () => import("./../../../src/pages/portfolios/tt-back-to-basics/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-back-to-basics-index-mdx" */),
  "component---src-pages-portfolios-tt-bonds-not-index-mdx": () => import("./../../../src/pages/portfolios/tt-bonds-not/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-bonds-not-index-mdx" */),
  "component---src-pages-portfolios-tt-buoy-index-mdx": () => import("./../../../src/pages/portfolios/tt-buoy/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-buoy-index-mdx" */),
  "component---src-pages-portfolios-tt-dos-equis-index-mdx": () => import("./../../../src/pages/portfolios/tt-dos-equis/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-dos-equis-index-mdx" */),
  "component---src-pages-portfolios-tt-easy-peasy-index-mdx": () => import("./../../../src/pages/portfolios/tt-easy-peasy/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-easy-peasy-index-mdx" */),
  "component---src-pages-portfolios-tt-equal-voltage-index-mdx": () => import("./../../../src/pages/portfolios/tt-equal-voltage/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-equal-voltage-index-mdx" */),
  "component---src-pages-portfolios-tt-four-fifteen-index-mdx": () => import("./../../../src/pages/portfolios/tt-four-fifteen/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-four-fifteen-index-mdx" */),
  "component---src-pages-portfolios-tt-mach-1-index-mdx": () => import("./../../../src/pages/portfolios/tt-mach-1/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-mach-1-index-mdx" */),
  "component---src-pages-portfolios-tt-mach-2-index-mdx": () => import("./../../../src/pages/portfolios/tt-mach-2/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-mach-2-index-mdx" */),
  "component---src-pages-portfolios-tt-mach-3-index-mdx": () => import("./../../../src/pages/portfolios/tt-mach-3/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-mach-3-index-mdx" */),
  "component---src-pages-portfolios-tt-market-vane-index-mdx": () => import("./../../../src/pages/portfolios/tt-market-vane/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-market-vane-index-mdx" */),
  "component---src-pages-portfolios-tt-mean-kitty-index-mdx": () => import("./../../../src/pages/portfolios/tt-mean-kitty/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-mean-kitty-index-mdx" */),
  "component---src-pages-portfolios-tt-pick-me-up-index-mdx": () => import("./../../../src/pages/portfolios/tt-pick-me-up/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-pick-me-up-index-mdx" */),
  "component---src-pages-portfolios-tt-quick-change-index-mdx": () => import("./../../../src/pages/portfolios/tt-quick-change/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-quick-change-index-mdx" */),
  "component---src-pages-portfolios-tt-rain-or-shine-index-mdx": () => import("./../../../src/pages/portfolios/tt-rain-or-shine/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-rain-or-shine-index-mdx" */),
  "component---src-pages-portfolios-tt-round-robin-index-mdx": () => import("./../../../src/pages/portfolios/tt-round-robin/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-round-robin-index-mdx" */),
  "component---src-pages-portfolios-tt-stocks-on-a-stroll-index-mdx": () => import("./../../../src/pages/portfolios/tt-stocks-on-a-stroll/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-stocks-on-a-stroll-index-mdx" */),
  "component---src-pages-portfolios-tt-stocks-on-the-loose-index-mdx": () => import("./../../../src/pages/portfolios/tt-stocks-on-the-loose/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-stocks-on-the-loose-index-mdx" */),
  "component---src-pages-portfolios-tt-straight-four-index-mdx": () => import("./../../../src/pages/portfolios/tt-straight-four/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-straight-four-index-mdx" */),
  "component---src-pages-portfolios-tt-vix-spritz-aggro-index-mdx": () => import("./../../../src/pages/portfolios/tt-vix-spritz-aggro/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-vix-spritz-aggro-index-mdx" */),
  "component---src-pages-portfolios-tt-vix-spritz-index-mdx": () => import("./../../../src/pages/portfolios/tt-vix-spritz/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-vix-spritz-index-mdx" */),
  "component---src-pages-portfolios-tt-vix-treasures-index-mdx": () => import("./../../../src/pages/portfolios/tt-vix-treasures/index.mdx" /* webpackChunkName: "component---src-pages-portfolios-tt-vix-treasures-index-mdx" */),
  "component---src-pages-sign-up-index-mdx": () => import("./../../../src/pages/sign-up/index.mdx" /* webpackChunkName: "component---src-pages-sign-up-index-mdx" */),
  "component---src-pages-thank-you-index-mdx": () => import("./../../../src/pages/thank-you/index.mdx" /* webpackChunkName: "component---src-pages-thank-you-index-mdx" */)
}

