//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/toggle
// Description: Toggle switch
//              see https://w3collective.com/react-toggle-switch-component/
// Created:     FUB, June 10, 2022
//==============================================================================

import React from "react"

//------------------------------------------------------------------------------
export const Toggle = ({ labels, isChecked, setChecked }) => {
    const onToggle = () => setChecked && setChecked(!isChecked);

    return (
        <label
            css={`
                vertical-align: text-bottom;
            `}
        >
            {labels?.[0]}
            <div
                css={`
                    display: inline-block;
                    position: relative;
                    width: 2.0em;
                    height: 1.0em;
                    margin-left: 1em;
                    margin-right: 1em;            
                `}
            >
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={onToggle}
                    css={`display:none;`}
                />
                <span
                    checked={isChecked}
                    css={`
                        position: absolute;
                        cursor: pointer;
                        border-radius: 0.75em;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        transition: background-color 0.2s ease;
                        background-color: ${props => props.checked ? props.theme.colors.cta : props.theme.colors.active};
                    
                        &:before {
                            position: absolute;
                            content: "";
                            left: 0.15em;
                            top: 0.15em;
                            width: 0.7em;
                            height: 0.7em;
                            background-color: #aaa;
                            border-radius: 50%;
                            transition: transform 0.3s ease;        
                    
                            transform: translateX(${props => props.checked ? "1.0em" : "0"});
                        }                
                    `}
                />
            </div>
            {labels?.[1]}
        </label>
    );
};

//==============================================================================
// end of file
