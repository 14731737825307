//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/aside
// Description: Layout primitives.
// Created:     FUB, July 1, 2022
//==============================================================================

//import React from "react"
import styled from "styled-components"

export const Aside = styled.div`
    /*border-radius: ${props => props.theme.dimensions.cornerRadius};*/
    /*background: ${props => props.theme.gradients.light};*/
    /*box-shadow: ${props => props.theme.shadows.box};*/
    /*border: ${props => props.theme.dimensions.lineThickness} solid ${props => props.theme.colors.decorative};*/
    /*border-style: none none none solid;*/
    border-left: 1px solid ${props => props.theme.colors.decorative};

    font-size: small;
    float: right;
    /*max-width: min(200px, 25%);*/
    width: min(200px, 25%);
    margin-left: ${props => props.theme.spacings.normal};
    padding-left: ${props => props.theme.spacings.normal};
    & p {
        color: ${props => props.theme.colors.accent};
        margin-bottom: 0.5rem;
    }
    & ul {
        margin-top: 0.5rem;
        padding-left: 1.5em;
    }

    /*&:after {
        content: "";
        display: table;
        clear: both;
    }*/
`

//==============================================================================
// end of file
