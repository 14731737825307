import { GenericPage } from "../../../../src/components/mdx/page";
import styled from "styled-components";
import { FaChessKnight, FaRandom, FaCheckDouble, FaWrench, FaFlask, FaCommentsDollar, FaChartLine, FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { ButtonPrimary, ButtonSecondary } from "../../../../src/components/widgets/button";
import { SignUpButton } from "../../../../src/components/widgets/sign-up-button";
import { TopPortfolios } from "../../../../src/components/widgets/top-portfolios";
import TuringTraderNews from "../../../../src/components/widgets/tt-news";
import { YouTube } from "../../../../src/components/widgets/youtube";
import { Quote } from "../../../../src/components/widgets/quote";
import * as React from 'react';
export default {
  GenericPage,
  styled,
  FaChessKnight,
  FaRandom,
  FaCheckDouble,
  FaWrench,
  FaFlask,
  FaCommentsDollar,
  FaChartLine,
  FaQuoteLeft,
  FaQuoteRight,
  ButtonPrimary,
  ButtonSecondary,
  SignUpButton,
  TopPortfolios,
  TuringTraderNews,
  YouTube,
  Quote,
  React
};