import { GenericPage } from "../../../../src/components/mdx/page";
import { Breadcrumbs } from "../../../../src/components/widgets/breadcrumbs";
import { Aside } from "../../../../src/components/widgets/aside";
import { Border } from "../../../../src/components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../src/components/widgets/button";
import { PortfolioChart } from "../../../../src/components/widgets/portfolio-ref";
import * as React from 'react';
export default {
  GenericPage,
  Breadcrumbs,
  Aside,
  Border,
  ButtonPrimary,
  ButtonSecondary,
  PortfolioChart,
  React
};