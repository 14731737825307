//==============================================================================
// Project:     www.TuringTrader.com
// Name:        utils/firebase
// Description: Firebase implementation.
// History:     2022, May 10, FUB, created
//==============================================================================

// see https://www.npmjs.com/package/firebase

// NOTE: make sure this gets imported from top layout (components/layout/page)

import { useState, useEffect } from "react"
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent /*isSupported*/ } from "firebase/analytics";
import { getStorage, ref, getDownloadURL } from "firebase/storage"
//import { getAuth, signInAnonymously } from "firebase/auth"
//import { getFirestore, collection, doc, getDoc, setDoc, deleteField } from "firebase/firestore"

import { status, text, json } from "./fetch-helpers"
//import { useMembership } from "./memberspace";
//import { cleanMeta } from "./member-data"

//------------------------------------------------------------------------------
// debug stuff

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`FIREBASE: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`FIREBASE: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
// * * *   F i r e b a s e   A p p   * * * * * * * * * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

const isBrowser = typeof window !== "undefined"

const firebaseConfig = {
    apiKey: "AIzaSyCCP4GVJ7soV4tejbhwsGUL451Nc4h0luY",
    authDomain: "turingtrader-com.firebaseapp.com",
    databaseURL: "https://turingtrader-com.firebaseio.com",
    projectId: "turingtrader-com",
    storageBucket: "turingtrader-com.appspot.com",
    messagingSenderId: "334637479764",
    appId: "1:334637479764:web:ee546e49b936a768847ac1",
    measurementId: "G-PTQ7VBBP8S",
}

const app = initializeApp(firebaseConfig)

//------------------------------------------------------------------------------
// * * *   A n a l y t i c s   * * * * * * * * * * * * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

// see https://firebase.google.com/docs/analytics/get-started?platform=web
// and https://firebase.google.com/docs/analytics/events?platform=web
// and https://developers.google.com/tag-platform/gtagjs/reference/events

/*let analytics = null // eslint-disable-line no-unused-vars
isSupported().then((fSupported) => {
    if (fSupported) {
        DEBUG_MSG("analytics supported")
        analytics = getAnalytics(app)
    } else {
        DEBUG_MSG("analytics not supported")
    }
})*/

const analytics = isBrowser && getAnalytics(app)

export const logPageView = (pagePath) => {
    DEBUG_MSG(`page view: ${pagePath}`)

    //logEvent(analytics, "page_view")

    logEvent(analytics, "page_view", {
        // see https://developers.google.com/analytics/devguides/collection/gtagjs/pages
        page_path: pagePath,
        page_location: `https://www.turingtrader.com${pagePath}`,
    })

    /*logEvent(analytics, "screen_view", { 
        // see https://firebase.google.com/docs/analytics/screenviews#web-version-9
        firebase_screen: pagePath, 
        screen_name: pagePath
    })*/
}

export const logPortfolioSearch = (parameters) => {
    logEvent(analytics, "search", { search_term: parameters })
}

//------------------------------------------------------------------------------
// * * *   S t o r a g e   * * * * * * * * * * * * * * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

// see https://firebase.google.com/docs/storage/web/start
const storage = getStorage(app)

const useStorageRef = (portfolio, file) => {
    const [fileRef, setRef] = useState(undefined)

    useEffect(() => {
        // NOTE: if portfolio is undefined, we assume that its value has
        // not been determined yet, and we exit useEffect.
        // if portfolio is null, we assume that its value was intentionally
        // set and that we are looking for a file in the root directory.
        if (typeof portfolio === 'undefined') return
        if (!file) return

        //setRef(ref(storage, "heine-bond-model/last-update.txt"))
        const r = ref(storage, portfolio ? `${portfolio}/${file}` : file)
        DEBUG_MSG(`pf=${portfolio}, f=${file} r=${r ? "something" : "nothing"}`)
        setRef({
            portfolio,
            file,
            ref: r,
        })
    }, [portfolio, file])

    // make sure we only return a fileref that matches the request
    return fileRef?.portfolio === portfolio &&
        fileRef?.file === file &&
        fileRef.ref
}

const defaultRefresh = 60 * 60 * 1000 // one hour in milliseconds

export const useTextFile = (portfolio, file, refresh = defaultRefresh) => {
    const fileRef = useStorageRef(portfolio, file)
    const [theText, setText] = useState(undefined)
    const [theError, setError] = useState(undefined)

    useEffect(() => {
        if (!fileRef) return

        let isActive = true

        const fetchData = async () => {
            DEBUG_MSG(`useTextFile(${portfolio}, ${file}, ${refresh})`)
            getDownloadURL(fileRef)
                .then(fetch)
                .then(status)
                .then(text)
                .then((text) => {
                    if (isActive) {
                        setText({
                            portfolio,
                            file,
                            text,
                        })
                        setTimeout(fetchData, refresh)
                    }
                })
                .catch((err) => {
                    ERROR_MSG(
                        `fetch error: slug=${portfolio}, file=${file}, err=${err}`
                    )
                    setError(err)
                })
        }

        fetchData()

        return () => { isActive = false }
    }, [fileRef, portfolio, file, refresh])

    if (theError) return null

    // make sure we only return data if the request matches the data
    return theText?.portfolio === portfolio &&
        theText?.file === file &&
        theText.text
}

export const useJsonFile = (portfolio, file, refresh = defaultRefresh) => {
    const fileRef = useStorageRef(portfolio, file)
    const [theJson, setJson] = useState(undefined)
    const [theError, setError] = useState(undefined)

    useEffect(() => {
        if (!fileRef) return

        let isActive = true

        const fetchData = async () => {
            DEBUG_MSG(`useJsonFile(${portfolio}, ${file}, ${refresh})`)
            getDownloadURL(fileRef)
                .then(fetch)
                .then(status)
                .then(json)
                .then((text) => {
                    if (isActive) {
                        setJson({
                            portfolio,
                            file,
                            json: text,
                        })
                        setTimeout(fetchData, refresh)
                    }
                })
                .catch((err) => {
                    ERROR_MSG(
                        `fetch error: slug=${portfolio}, file=${file}, err=${err}`
                    )
                    setError(err)
                })
        }

        fetchData()

        return () => { isActive = false }
    }, [fileRef, portfolio, file, refresh])

    if (theError) return null

    return theJson?.portfolio === portfolio &&
        theJson?.file === file &&
        theJson.json
}

export const useImageUrl = (portfolio, file) => {
    const fileRef = useStorageRef(portfolio, file)
    const [theImage, setImage] = useState(undefined)
    const [theError, setError] = useState(undefined)

    useEffect(() => {
        if (!fileRef) return

        const fetchUrl = async () => {
            if (!fileRef) return

            getDownloadURL(fileRef)
                .then((url) => {
                    setImage({
                        portfolio,
                        file,
                        url,
                    })
                })
                .catch((error) => {
                    ERROR_MSG(
                        `useImageUrl error: slug=${portfolio}, file=${file}, err=${error.message}`
                    )
                    setError(error)
                })
        }

        fetchUrl()
    }, [fileRef, portfolio, file])

    if (theError) return null

    return theImage?.portfolio === portfolio &&
        theImage?.file === file &&
        theImage.url
}

//------------------------------------------------------------------------------
// * * *   A u t h   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

// see https://firebase.google.com/docs/auth/web/anonymous-auth

/* retired 2023x04 - Outseta no longer needs Firebase auth
const auth = getAuth(app)

// FIXME: this code is executed during server-side rendering
signInAnonymously(auth)
    .then(() => {
        DEBUG_MSG("Firebase sign-in successful")
    })
    .catch((error) => {
        ERROR_MSG(`Firebase sign-in failed. Code=${error.code}, Message=${error.Message}`)
    });
*/

//------------------------------------------------------------------------------
// * * *   F i r e s t o r e   * * * * * * * * * * * * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

// see https://firebase.google.com/docs/firestore/quickstart
// see https://firebase.google.com/docs/firestore/query-data/get-data

/* retired 2023x04 - Outseta no longer needs Firestore db
const db = getFirestore(app)

export const useFirebaseMeta = (membership) => {
    const memberId = membership?.user?.id
    const memberLinks = membership?.links
    const [docRef, setDocRef] = useState(undefined)
    const [meta, setMeta] = useState(undefined)

    //----- maintain docRef
    useEffect(() => {
        if (!memberId) {
            setDocRef(null)
            return
        }

        const colName = memberLinks.login.includes("outseta")
            ? "outseta-users"
            : "users"
        const docName = "#".concat(memberId)
        const ref = doc(collection(db, colName), docName)
        setDocRef(ref)
    }, [memberId, memberLinks])

    //----- retrieve metadata
    useEffect(() => {
        let timer = null

        if (!docRef) {
            // with meta being null, we won't
            // store updates to Firebase
            setMeta(null)
            return
        }

        const getMeta = () => {
            // we do _not_ subscribe to the data here
            // because we consider Firebase only a mirror
            // of the data stored on MemberSpace
            getDoc(docRef)
                .then((doc) => {
                    if (doc.exists) {
                        const data = doc.data()
                        if (data) {
                            setMeta(data)
                            DEBUG_MSG(`read from Firestore: ${JSON.stringify(data)}`)
                        } else {
                            // FIXME: this is weird. we get here,
                            // because the document exists, but is undefined.
                            // for now, we create a new document.
                            setDoc(docRef, {})
                                .then(() => setMeta({}))
                            DEBUG_MSG(`Firestore document undefined => create {}`)
                        }
                    } else {
                        setDoc(docRef, {})
                            .then(() => setMeta({}))
                        DEBUG_MSG(`Firestore document does not exist => create {}`)
                    }

                    // FIXME: this is transitionary
                    // we can remove this timer once we switch
                    // to MemberSpace v2
                    timer = setTimeout(getMeta, 20000) // every 20 seconds
                })
                .catch(error => {
                    ERROR_MSG(error)
                    setMeta(null)
                })
        }

        getMeta()

        return () => {
            clearTimeout(timer)
        }
    }, [docRef])

    //----- set metadata from the UI
    const externalSetMeta = (newMeta) => {
        // don't update before we received metadata here
        if (!meta) return

        const newMeta2 = cleanMeta(newMeta)
        setMeta(newMeta2)

        const newMeta3 = cleanMeta({
            ...meta,
            ...newMeta2,
        }, (obj, key) => obj[key] = deleteField())
        setDoc(docRef, newMeta3, { merge: true })
        DEBUG_MSG(`write to Firestore: ${JSON.stringify(newMeta3)}`)
    }

    return [meta, externalSetMeta]
}
*/

//------------------------------------------------------------------------------
// * * *   F u n c t i o n s   * * * * * * * * * * * * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

// see firebase.json for rewrites
//  "rewrites": [
//      {"source": "/api/v1/alloc","function": "alloc"},
//      {"source": "/api/v1/key","function": "key"},
//      {"source": "/api/v1/dl_chart","function": "dl_chart"},
//      {"source": "/api/v1/dl_alloc","function": "dl_alloc"},
//      {"source": "/api/v1/backtest","function": "backtest"}
//  ]

// see https://jasonwatmore.com/post/2020/02/01/react-fetch-http-post-request-examples
export const useBacktestApi = (spec) => {
    const [theResult, setResult] = useState(undefined)
    const [theError, setError] = useState(undefined)

    useEffect(() => {
        if (!spec) return

        let isActive = true

        fetch(
            "https://us-central1-turingtrader-com.cloudfunctions.net/backtest5", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(spec)
        })
            .then(status)
            .then(json)
            .then(text => {
                if (isActive) setResult(text)
            })
            .catch(err => {
                ERROR_MSG(
                    `backtest error: spec=${JSON.stringify(spec)}, err=${err}`
                )
                setError(err)
            })

        return () => { isActive = false }
    }, [spec])

    return theError ? null : theResult
}

export const useKeyApi = (membership) => {
    const [apiKey, setApiKey] = useState(undefined)

    useEffect(() => {
        if (!membership?.user?.id)
            return

        const url = `https://api.turingtrader.com/api/v1/key?id=${membership?.user?.id}`

        fetch(url)
            .then(status)
            .then(json)
            .then(res => setApiKey(res.apiKey))
            .catch((error) => {
                ERROR_MSG(`failed to fetch api key: ${error.message}`)
                setApiKey("n/a")
            })
    }, [membership])

    return apiKey
}

export const useDownloadChartApi = (slug) => {
    const [theUrl, setUrl] = useState()

    // FIXME: it is not entirely clear why we need to use an effect here
    //        but without it, the url is not set correctly
    useEffect(() => {
        const slugWithoutSlashes = slug.replace(/\//g, "")
        //const url = `https://us-central1-turingtrader-com.cloudfunctions.net/dl_chart?pf=${slugWithoutSlashes}`
        const url = `https://api.turingtrader.com/api/v1/dl_chart/?pf=${slugWithoutSlashes}`
        setUrl(url)
    }, [slug])

    return theUrl
}

export const useDownloadAllocApi = (slug) => {
    if (!slug) return slug

    const slugWithoutSlashes = slug.replace(/\//g, "")
    //const url = `https://us-central1-turingtrader-com.cloudfunctions.net/dl_alloc?pf=${slugWithoutSlashes}`
    const url = `https://api.turingtrader.com/api/v1/dl_alloc/?pf=${slugWithoutSlashes}`

    return url
}

//==============================================================================
// end of file
