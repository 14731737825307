//==============================================================================
// Project:     www.TuringTrader.com
// Name:        utils/search-params
// Description: Access to search parameters
// History:     2022, June 10, FUB, created
//==============================================================================

import { useState, useEffect } from "react"
import { navigate } from "gatsby"

export const useSearchParams = () => {
    const [searchParams, setSearchParams] = useState()

    const search = typeof window !== 'undefined' ? window.location.search : null

    useEffect(() => {
        if (!search) return

        setSearchParams(new URLSearchParams(search))
    }, [search])

    return searchParams
}

//------------------------------------------------------------------------------
// compatible to useSearchParams from react-router-dom
// NOTE: we keep this outside useSearchParams2,
//       so that the function won't change on each render
const searchParamsSetter = (params) => {
    // FIXME: right now, all this function allows is to remove
    // the search parameters from the URL.
    // Moving forward, we should allow to also set the 
    // params, and append them to the URL

    // FIXME: do we need to wrap this with a setTimeout?
    setTimeout(() => {
        navigate(`${window.location.origin}${window.location.pathname}`)
    }, 100)
}

export const useSearchParams2 = () => {
    const [searchParamsValue, setSearchParamsValue] = useState()
    //const [searchParamsSetter, setSearchParamsSetter] = useState(() => { })

    const search = typeof window !== 'undefined' ? window.location.search : null

    useEffect(() => {
        if (!search) return

        setSearchParamsValue(new URLSearchParams(search))
        /*setSearchParamsSetter((params) => {
            // FIXME: right now, all this function allows is to remove
            // the search parameters from the URL.
            // Moving forward, we should allow to also set the 
            // params, and append them to the URL
 
            // FIXME: do we need to wrap this with a setTimeout?
            setTimeout(() => {
                navigate(`${window.location.origin}${window.location.pathname}`)
            }, 100)
        })*/
    }, [search])

    return [searchParamsValue, searchParamsSetter]
}

//==============================================================================
// end of file