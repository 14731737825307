//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/lightbox
// Description: Lightbox widget.
// History:     2022 May 30, FUB, created
//==============================================================================

 // Visible, non-interactive elements with click handlers must have at least one keyboard listener
 /* eslint-disable jsx-a11y/click-events-have-key-events */
 // Static HTML elements with event handlers require a role
 /* eslint-disable jsx-a11y/no-static-element-interactions */

 import React, { useState } from "react"

import { Popup } from "./popup"

//------------------------------------------------------------------------------
export const Lightbox = ({ title, src, children }) => {
    const [isVisible, setVisible] = useState(false)

    return (<>
        <span onClick={() => setVisible(true)} css={`dispay:block;cursor:pointer;`}>
            {children}            
        </span>

        {isVisible === true && (
            <Popup 
                title={title} 
                onCancel={() => setVisible(false)}
                css={`padding:0;`}
            >
                <img src={src} alt={title} css={`width:89vw;`}/>
            </Popup>
        )}
    </>)
}

//==============================================================================
// end of file
