import { GenericPage } from "../../../../src/components/mdx/page";
import { Breadcrumbs } from "../../../../src/components/widgets/breadcrumbs";
import { ContentPlusDecor } from "../../../../src/components/widgets/content-plus-decor";
import { FaPlug } from "react-icons/fa";
import * as React from 'react';
export default {
  GenericPage,
  Breadcrumbs,
  ContentPlusDecor,
  FaPlug,
  React
};