import { GenericPage } from "../../../../src/components/mdx/page";
import { Accordion } from "../../../../src/components/widgets/accordion";
import { HelpPopup } from "../../../../src/components/widgets/help-popup";
import { ContentPlusDecor } from "../../../../src/components/widgets/content-plus-decor";
import { FaQuestionCircle } from "react-icons/fa";
import * as React from 'react';
export default {
  GenericPage,
  Accordion,
  HelpPopup,
  ContentPlusDecor,
  FaQuestionCircle,
  React
};