import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`All Premium features, plus:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Account grouping`}</strong><br />{`
Create custom meta-portfolios by grouping accounts`}</li>
      <li parentName="ul"><strong parentName="li">{`Account consolidation`}</strong><br />{`
Analyze historical performance and review the consolidated asset allocation of grouped accounts`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#api"
          }}>{`Support automation with API access`}</a></strong><br />{`
Automate your portfolio maintenance and rebalancing`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      