import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import styled from "styled-components";
export const RedBackground = styled.div`
   border: 5px solid red;
`;
export const _frontmatter = {};
const layoutProps = {
  RedBackground,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{` January 2024: `}</strong>{`
Read our `}<a parentName="p" {...{
        "href": "/articles/review-2023"
      }}>{`2023 Review`}</a>{` to learn about our performance and tracking of live trading vs backtests.`}</p>
    <p><strong parentName="p">{` November 2023: `}</strong>{`
We introduced `}<a parentName="p" {...{
        "href": "/portfolios/freeburg-pentad/"
      }}>{`PENTAD`}</a>{`, a strategy toggling between stocks and T-bills, based on macroeconomic cues.`}</p>
    <p><strong parentName="p">{` June 2023: `}</strong>{`
We introduced `}<a parentName="p" {...{
        "href": "/portfolios/ilap-commitment/"
      }}>{`Commitment`}</a>{`, a low-volatility strategy optimized to help you reach your financial goals.`}</p>
    <p><strong parentName="p">{` April 2023: `}</strong>{`
We introduced `}<a parentName="p" {...{
        "href": "/portfolios/tt-easy-peasy/"
      }}>{`Easy Peasy`}</a>{`, a charmingly simple tactical alternative to the ubiquitous 60/40.`}</p>
    <p><strong parentName="p">{` March 2023: `}</strong>{`
We introduced `}<a parentName="p" {...{
        "href": "/portfolios/keller-hybrid-asset-allocation"
      }}>{`Keller's Hybrid Asset Allocation`}</a>{`, a unique momentum strategy that combines dual-momentum with a canary asset for swift crash protection.`}</p>
    <p><strong parentName="p">{` February 2023: `}</strong>{`
We retrofitted our `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-monthly/"
      }}>{`All-Stars Monthly`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-round-robin/"
      }}>{`Round Robin`}</a>{` portfolios to stick to their monthly rebalancing schedule, even while
in bearish markets.`}</p>
    <p><strong parentName="p">{` December 2022: `}</strong>{`
We improved our portfolio wizard to make even better suggestions, epecially for investments in taxable accounts. `}<a parentName="p" {...{
        "href": "/portfolios/?wizard=1"
      }}>{`Check it out!`}</a></p>
    <p><strong parentName="p">{` December 2022: `}</strong>{`
We introduced `}<a parentName="p" {...{
        "href": "/portfolios/keller-bold-asset-allocation/"
      }}>{`Keller's Bold Asset Allocation`}</a>{`, a unique strategy that spends more than half of its time in defensive assets.`}</p>
    <p><strong parentName="p">{` November 2022: `}</strong>{`
We improved our `}<a parentName="p" {...{
        "href": "/portfolios/tt-mean-kitty/"
      }}>{`Mean Kitty`}</a>{` strategy to use `}<a parentName="p" {...{
        "href": "/portfolios/tt-buoy/"
      }}>{`Buoy`}</a>{` as its risk-off investment. Further, we updated our `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-tr/"
      }}>{`All-Stars Total Return`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-xl/"
      }}>{`All-Stars XL`}</a>{` portfolios to include the updated strategy.`}</p>
    <p><strong parentName="p">{` November 2022: `}</strong>{`
We improved our `}<a parentName="p" {...{
        "href": "/portfolios/tt-round-robin/"
      }}>{`Round Robin`}</a>{` strategy to use `}<a parentName="p" {...{
        "href": "/dashboard/market-vane/"
      }}>{`Market Vane`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-buoy"
      }}>{`Buoy`}</a>{`. Also, we updated our `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-tr/"
      }}>{`All-Stars Total Return`}</a>{`, `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-monthly/"
      }}>{`All-Stars Monthly`}</a>{`, `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-stocks/"
      }}>{`All-Stars Stocks`}</a>{`, `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-lev/"
      }}>{`All-Stars Leveraged`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-xl/"
      }}>{`All-Stars XL`}</a>{` portfolios to use the updated strategy.`}</p>
    <p><strong parentName="p">{` November 2022: `}</strong>{`
We improved our `}<a parentName="p" {...{
        "href": "/portfolios/tt-mach-1/"
      }}>{`Mach-1`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-mach-2/"
      }}>{`Mach-2`}</a>{` strategies with an exit to T-bills when mean-variance optimization fails. Further, we updated our `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-weekly/"
      }}>{`All-Stars Weekly`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-lev/"
      }}>{`All-Stars Leveraged`}</a>{` portfolios to use the updated strategies.`}</p>
    <p><strong parentName="p">{` October 2022: `}</strong>{`
We upgraded the `}<a parentName="p" {...{
        "href": "/help/account-rebalancing/"
      }}>{`rebalancing screen`}</a>{` to memorizes your account holdings and calculate the order sizes required to rebalance your account.`}</p>
    <p><strong parentName="p">{` September 2022: `}</strong>{`
With our new `}<a parentName="p" {...{
        "href": "/help/account-groups/"
      }}>{`account groups`}</a>{` feature, you can consolidate holdings and backtest performance across multiple account tranches.`}</p>
    <p><strong parentName="p">{` August 2022: `}</strong>{`
Introducing `}<a parentName="p" {...{
        "href": "/portfolios/tt-quick-change/"
      }}>{`Quick-Change`}</a>{`, our latest mean-reversion strategy, and `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-xl"
      }}>{`All-Stars XL`}</a>{`, our new meta-portfolio for larger accounts.`}</p>
    <p><strong parentName="p">{` July 2022: `}</strong>{`
We renovated the site for improved workflows, cleaner looks, and faster loading speed. Read more in our `}<a parentName="p" {...{
        "href": "/company/newsletter/"
      }}>{`July 2022 newsletter`}</a>{`.`}</p>
    <p><strong parentName="p">{` May 2022: `}</strong>{`
We upgraded our family of All-Stars portfolios to use the latest versions of `}<a parentName="p" {...{
        "href": "/portfolios/tt-vix-spritz/"
      }}>{`VIX Spritz`}</a>{`, `}<a parentName="p" {...{
        "href": "/portfolios/tt-mach-1"
      }}>{`Mach-1`}</a>{`, and `}<a parentName="p" {...{
        "href": "/portfolios/tt-mach-2"
      }}>{`Mach-2`}</a>{`.`}</p>
    <p><strong parentName="p">{` May 2022: `}</strong>{`
Our refreshed version of the `}<a parentName="p" {...{
        "href": "/portfolios/tt-vix-spritz/"
      }}>{`VIX Spritz`}</a>{` strategy is now using using `}<a parentName="p" {...{
        "href": "/portfolios/tt-buoy/"
      }}>{`Buoy`}</a>{` to adapt its bond holdings to the current yield environment.`}</p>
    <p><strong parentName="p">{` May 2022: `}</strong>{`
Our improved versions of the `}<a parentName="p" {...{
        "href": "/portfolios/tt-mach-1/"
      }}>{`Mach-1`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-mach-2"
      }}>{`Mach-2`}</a>{` portfolios show lower volatility in times of negative returns.`}</p>
    <p><strong parentName="p">{` April 2022: `}</strong>{`
We updated our `}<a parentName="p" {...{
        "href": "/portfolios/tt-stocks-on-the-loose/"
      }}>{`Stocks on the Loose`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-stocks-on-a-stroll/"
      }}>{`Stocks on a Stroll`}</a>{` stock-trading strategies to use `}<a parentName="p" {...{
        "href": "/portfolios/tt-buoy/"
      }}>{`Buoy`}</a>{` as risk-off investment.`}</p>
    <>{/*
       ** February 2022: **
       Introducing [Buoy](/portfolios/tt-buoy/), a novel bond-trading strategy designed with rising rates in mind.
       */}</>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      