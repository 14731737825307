import { GenericPage } from "../../../../src/components/mdx/page";
import { Accordion } from "../../../../src/components/widgets/accordion";
import { HelpPopup } from "../../../../src/components/widgets/help-popup";
import { Breadcrumbs } from "../../../../src/components/widgets/breadcrumbs";
import { FaFlask } from "react-icons/fa";
import * as React from 'react';
export default {
  GenericPage,
  Accordion,
  HelpPopup,
  Breadcrumbs,
  FaFlask,
  React
};