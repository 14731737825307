import { GenericPage } from "../../../../src/components/mdx/page";
import { MarketVane, MonthlyIndicatorsChart, DailyIndicatorsChart, PortfolioPerformanceChart } from "../../../../src/components/widgets/market-vane";
import { Breadcrumbs } from "../../../../src/components/widgets/breadcrumbs";
import { Aside } from "../../../../src/components/widgets/aside";
import { Border } from "../../../../src/components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../src/components/widgets/button";
import * as React from 'react';
export default {
  GenericPage,
  MarketVane,
  MonthlyIndicatorsChart,
  DailyIndicatorsChart,
  PortfolioPerformanceChart,
  Breadcrumbs,
  Aside,
  Border,
  ButtonPrimary,
  ButtonSecondary,
  React
};