import { GenericPage } from "../../../../src/components/mdx/page";
import styled from "styled-components";
import { Breadcrumbs } from "../../../../src/components/widgets/breadcrumbs";
import { ButtonPrimary, ButtonSecondary } from "../../../../src/components/widgets/button";
import * as React from 'react';
export default {
  GenericPage,
  styled,
  Breadcrumbs,
  ButtonPrimary,
  ButtonSecondary,
  React
};