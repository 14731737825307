//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/cumulative-returns
// Description: Widget to show cumulative returns and drawdowns
// Created:     FUB, June 09, 2022
//==============================================================================

import React, { useState, useEffect } from "react"
//import styled from "styled-components" // eslint-disable-line no-unused-vars
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LogarithmicScale, // FUB
    PointElement,
    LineElement,
    Filler, // FUB
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { theme } from "../layout/theme"
import { formatCurrency, formatPercent } from "../../utils/format-helpers"
import { Button } from "./button"

//------------------------------------------------------------------------------
// debug stuff

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`CUM-RET: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`CUM-RET: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
ChartJS.register(
    CategoryScale,
    LinearScale,
    LogarithmicScale, // FUB
    PointElement,
    LineElement,
    Filler, // FUB
    Title,
    Tooltip,
    Legend
);

export const CumulativeReturns = ({ data }) => {
    const [yearsToShow, setYearsToShow] = useState(100)

    //----- compose chart data
    // FIXME: shouldn't useMemo do the same?
    // we have this code because useMemo threw an exception
    const [defaultChartData, /*setDefaultChartData*/] = useState({
        labels: [Date.parse("01/01/2007"), Date.parse("01/01/2008"), Date.parse("01/01/2010"), Date.now()],
        datasets: [
            {
                label: "base",
                data: [1000, 1000, 1000, 1000], // baseline
                fill: false,
                pointRadius: 0,
                yAxisID: 'eq',

                //borderColor: 'red',
            },
            {
                label: "Portfolio",
                data: [1000, 1200, 800, 6000],
                borderColor: theme.colors.blue.base,
                backgroundColor: theme.colors.blue.base,
                //backgroundColor: theme.colors.blue.lighter[1],
                yAxisID: 'eq',

                fill: 0,
                pointRadius: 0,
                borderWidth: 1,
                pointHoverBackgroundColor: 'white',
                pointHoverBorderColor: theme.colors.blue.base,
                order: 999, // see https://www.chartjs.org/docs/latest/charts/mixed.html#drawing-order
            },
            {
                label: "Benchmark",
                data: [1000, 1100, 550, 3000],
                borderColor: theme.colors.green.base,
                backgroundColor: theme.colors.green.base,
                yAxisID: 'eq',

                pointRadius: 0,
                borderWidth: 1,
                pointHoverBackgroundColor: 'white',
                pointHoverBorderColor: theme.colors.green.base,
            },
            {
                label: "base (DD)",
                data: [0, 0, 0, 0],
                fill: false,
                pointRadius: 0,
                yAxisID: 'dd',

                //borderColor: 'red',
            },
            {
                label: "Portfolio (DD)",
                data: [-3, -3, -3, -3],     // NAV drawdown
                borderColor: theme.colors.blue.base,
                backgroundColor: theme.colors.blue.base,
                //backgroundColor: theme.colors.blue.lighter[1],
                yAxisID: 'dd',

                fill: 3,
                pointRadius: 0,
                borderWidth: 1,
                pointHoverBackgroundColor: 'white',
                pointHoverBorderColor: theme.colors.blue.base,
                order: 999, // see https://www.chartjs.org/docs/latest/charts/mixed.html#drawing-order
            },
            {
                label: "Benchmark (DD)",
                data: [-10, -10, -10, -10],
                borderColor: theme.colors.green.base,
                backgroundColor: theme.colors.green.base,
                yAxisID: 'dd',

                pointRadius: 0,
                borderWidth: 1,
                pointHoverBackgroundColor: 'white',
                pointHoverBorderColor: theme.colors.green.base,
            },
        ],
    })

    const [theChartData, setChartData] = useState(defaultChartData)

    useEffect(() => {
        if (!data) return

        /*
        // this is the quick & dirty solution
        const barsToShow = yearsToShow * 252
        const barsToSkip = Math.max(0, data[data.columns[0]].length - barsToShow)

        const xLabels = data[data.columns[0]]
            .filter((val, idx) => idx >= barsToSkip)
        */

        const firstDate = new Date(Math.max(Date.now() - yearsToShow * 365.25 * 24 * 60 * 60 * 1000))
        const xLabels = data[data.columns[0]]
            .filter(date => new Date(Date.parse(date)) >= firstDate)
        const barsToSkip = data[data.columns[0]].length - xLabels.length

        const pfFirst = data[data.columns[1]][barsToSkip]
        const bmFirst = data[data.columns[2]][barsToSkip]

        const pfNav = data[data.columns[1]]
            .filter((val, idx) => idx >= barsToSkip)
            .map(v => 1000 * v / pfFirst)
        const bmNav = data[data.columns[2]]
            .filter((val, idx) => idx >= barsToSkip)
            .map(v => 1000 * v / bmFirst)

        let _pfPeak = 0
        const pfPeak = pfNav
            .map(nav => { _pfPeak = Math.max(nav, _pfPeak); return _pfPeak })

        let _bmPeak = 0
        const bmPeak = bmNav
            .map(nav => { _bmPeak = Math.max(nav, _bmPeak); return _bmPeak })

        const pfDd = pfNav
            .map((nav, index) => 100 * (nav / pfPeak[index] - 1))
        const bmDd = bmNav
            .map((nav, index) => 100 * (nav / bmPeak[index] - 1))

        const chartData = {
            ...defaultChartData,
            labels: xLabels,
            datasets: [
                { // base
                    ...defaultChartData.datasets[0],
                    data: pfNav.map(v => 1000),
                },
                { // portfolio
                    ...defaultChartData.datasets[1],
                    label: data.columns[1],
                    data: pfNav,
                },
                { // benchmark
                    ...defaultChartData.datasets[2],
                    label: data.columns[2],
                    data: bmNav,
                },
                { // base (dd)
                    ...defaultChartData.datasets[3],
                    data: pfDd.map(v => 0),
                },
                { // portfolio (dd)
                    ...defaultChartData.datasets[4],
                    label: data.columns[1] + " (DD)",
                    data: pfDd,
                },
                { // benchmark (dd)
                    ...defaultChartData.datasets[5],
                    label: data.columns[2] + " (DD)",
                    data: bmDd,
                }
            ],
        }

        setChartData(chartData)
    }, [data, yearsToShow, defaultChartData])

    //----- compose chart options
    const [theChartOptions, setChartOptions] = useState()

    useEffect(() => {
        if (!theChartData) return

        const pfMin = theChartData.datasets[1].data
            .reduce((min, nav) => Math.min(min, nav), 1000)
        const bmMin = theChartData.datasets[2].data
            .reduce((min, nav) => Math.min(min, nav), 1000)
        const navMin = Math.min(pfMin, bmMin)

        const pfMax = theChartData.datasets[1].data
            .reduce((max, nav) => Math.max(max, nav), 1000)
        const bmMax = theChartData.datasets[2].data
            .reduce((max, nav) => Math.max(max, nav), 1000)
        const navMax = Math.max(pfMax, bmMax)
        DEBUG_MSG(`navMin = ${navMin} navMax = ${navMax}`)

        const pfDdMax = theChartData.datasets[4].data
            .reduce((min, dd) => Math.min(min, dd), -0.5)
        const bmDdMax = theChartData.datasets[5].data
            .reduce((min, dd) => Math.min(min, dd), -0.5)
        const ddMax = Math.min(pfDdMax, bmDdMax)
        DEBUG_MSG(`ddMax = ${ddMax}`)

        const chartOptions = {
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 1.78,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                x: {
                    type: 'category',
                    //sampleSize: 100,
                    //autoskip: true,
                    ticks: {
                        callback: function (value, index, ticks) {
                            // see https://www.chartjs.org/docs/latest/axes/labelling.html
                            const label = this.getLabelForValue(value)
                            if (typeof label === 'number')
                                return label

                            const m = parseInt(label.split('/')[0])
                            const mPrev = parseInt(this.getLabelForValue(Math.max(0, value - 1))
                                .split('/')[0])

                            const years = ticks.length / 252
                            let months = 1
                            if (years > 1.5) months = 3
                            if (years > 4) months = 6
                            if (years > 8) months = 12

                            if (m === mPrev || (m - 1) % months !== 0) return null

                            const options = {
                                year: months < 12 ? "2-digit" : "numeric",
                                month: months < 12 ? "short" : undefined,
                            }

                            return new Date(label).toLocaleDateString("en-US", options)
                        }
                    },
                    grid: {
                        borderColor: theme.colors.decorative,
                    },
                },
                eq: {
                    type: 'logarithmic',
                    display: true,
                    position: 'right',
                    stack: 'nav-dd', // see https://www.chartjs.org/docs/3.7.0/samples/scales/stacked.html
                    stackWeight: 2,
                    min: navMin,
                    max: navMax,
                    ticks: {
                        // see https://www.chartjs.org/docs/latest/axes/labelling.html
                        //callback: (value, index, ticks) => ticks[index].major ? formatCurrency(value, 0) : '',
                        //callback: (value, index, ticks) => ticks[index].major ? value / 1000 + "k US$" : '',
                        //callback: (value, index, ticks) => [500, 1000, 2000, 3000, 5000, 10000, 20000, 5000].includes(value) ? value / 1000 + "k US$" : '',
                        callback: (value, index, ticks) => (value % 1000 === 0) ? '$' + value / 1000 + "k" : '',
                    },
                    grid: {
                        borderColor: theme.colors.decorative,
                    },
                },
                dd: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    stack: 'nav-dd', // see https://www.chartjs.org/docs/3.7.0/samples/scales/stacked.html
                    stackWeight: 1,
                    //offset: true,
                    max: -ddMax / 10,
                    min: ddMax,
                    ticks: {
                        // see https://www.chartjs.org/docs/latest/axes/labelling.html
                        callback: (value, index, ticks) => value <= 0 ? formatPercent(value, 0) : null,
                        filter: false,
                    },
                    grid: {
                        borderColor: theme.colors.decorative,
                    },
                },
            },
            plugins: {
                title: {
                    display: true,
                    text: 'Cumulative Returns and Drawdowns',
                },
                legend: { // see https://www.chartjs.org/docs/latest/configuration/legend.html
                    labels: {
                        filter: (legendItem, data) => legendItem.datasetIndex >= 1 && legendItem.datasetIndex <= 2,
                    },
                    onClick: function (e, legendItem, legend) {
                        // see https://www.chartjs.org/docs/latest/configuration/legend.html

                        const defaultLegendClickHandler = ChartJS.defaults.plugins.legend.onClick
                        defaultLegendClickHandler(e, legendItem, legend)

                        const ci = legend.chart
                        //const index = legendItem.datasetIndex

                        const metaPfNav = ci.getDatasetMeta(1)
                        const metaBmNav = ci.getDatasetMeta(2)
                        const metaPfDd = ci.getDatasetMeta(4)
                        const metaBmDd = ci.getDatasetMeta(5)

                        metaPfDd.hidden = metaPfNav.hidden
                        metaBmDd.hidden = metaBmNav.hidden

                        ci.update()
                    },
                },
                tooltip: {
                    filter: (item, data) => item.datasetIndex !== 0 && item.datasetIndex !== 3,
                    itemSort: (a, b, data) => a.datasetIndex - b.datasetIndex,
                    callbacks: {
                        // https://www.chartjs.org/docs/latest/configuration/tooltip.html#label-callback
                        label: context => {
                            //const xLabel = context.label
                            //const yValue = context.parsed.y
                            const yValue = context.raw
                            const dataLabel = context.dataset.label
                            const dataIndex = context.datasetIndex

                            return dataIndex < 3 ?
                                `${dataLabel}: ${formatCurrency(yValue)}` :
                                `${dataLabel}: ${formatPercent(yValue)}`
                        }
                    }
                },
            },
            animation: false, // see https://www.chartjs.org/docs/latest/configuration/animations.html
            animations: {
                colors: false,
                x: false,
            },
            transitions: {
                active: {
                    animation: {
                        duration: 0,
                    }
                }
            },
        }

        setChartOptions(chartOptions)
    }, [yearsToShow, theChartData])

    //----- TODO: crosshairs
    // see https://www.youtube.com/watch?v=za2cQFObvWQ
    // NOTE: it seems that crosshairs won't work together with tooltips

    //----- render chart
    // FIXME: this looks convoluted. can we simplify the nested divs somehow?
    return (<>
        <div css={`position:relative;`}>
            <div css={`display:inline-block;width:0px;padding-bottom:56%;`} />
            <div css={`position:absolute;top:0;left:0;width:100%;height:100%;`}>
                <div css={`position:relative;`}>
                    {theChartOptions && theChartData && (
                        <Line options={theChartOptions} data={theChartData} />
                    )}
                </div>
            </div>
        </div>

        <div>
            <Button small={true} text="1 year" onClick={() => setYearsToShow(1)} />&nbsp;&nbsp;
            <Button small={true} text="2 years" onClick={() => setYearsToShow(2)} />&nbsp;&nbsp;
            <Button small={true} text="5 years" onClick={() => setYearsToShow(5)} />&nbsp;&nbsp;
            <Button small={true} text="10 years" onClick={() => setYearsToShow(10)} />&nbsp;&nbsp;
            <Button small={true} text="Maximum" onClick={() => setYearsToShow(100)} />
        </div>
    </>)
}

//==============================================================================
// end of file
