//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/portfolio/annual-returns
// Description: Widgets for portfolio display
// Created:     FUB, May 05, 2022
//==============================================================================

import React from "react"

import { usePortfolioData } from "../../../utils/portfolio-data"
import { TableSimple } from "../table"
import { useAnnualReturns, AnnualReturns as AnnualReturnsWidget } from "../annual-returns"

//------------------------------------------------------------------------------
const AnnualReturnsImage = ({ portfolio }) => { // eslint-disable-line no-unused-vars
    const data = usePortfolioData(portfolio)
    const imgUrl = data?.annualPerformanceChart

    return imgUrl ? (<div>
        <img
            src={imgUrl}
            alt="TuringTrader.com"
            css={`width: 100%;`}
        />
    </div>) : null
}

//------------------------------------------------------------------------------
const AnnualReturnsTable = ({ portfolio }) => { // eslint-disable-line no-unused-vars
    const cumulativeReturns = usePortfolioData(portfolio)?.cumulativePerformanceTable
    const annualReturns = useAnnualReturns(cumulativeReturns)

    return <TableSimple data={annualReturns}/>
}

//------------------------------------------------------------------------------
const AnnualReturnsInteractive= ({ portfolio }) => {
    const cumulativeReturns = usePortfolioData(portfolio)?.cumulativePerformanceTable
    const annualReturns = useAnnualReturns(cumulativeReturns)

    return <AnnualReturnsWidget data={annualReturns}/>
}

//------------------------------------------------------------------------------
//export const AnnualReturns = AnnualReturnsImage // backend-rendered image
//export const AnnualReturns = AnnualReturnsTable // 3-column table
export const AnnualReturns = AnnualReturnsInteractive // interactive chart

//==============================================================================
// end of file
