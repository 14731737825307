import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../../src/components/mdx/portfolio";
import { Border } from "../../../../src/components/widgets/container";
import * as React from 'react';
export default {
  PortfolioPage,
  Summary,
  Review,
  Aside,
  Notes,
  Border,
  React
};