//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/pricing-table
// Description: Pricing table widget
// Created:     FUB, July 15, 2022
//==============================================================================

import React from "react"
import { navigate } from "gatsby"
import { FaHeart, FaMedal, FaInfinity } from "react-icons/fa"
import styled from "styled-components"

import { ButtonPrimary, ButtonSecondary } from "../button.js"
import { Container } from "../container"
import { Toggle } from "../toggle"
import { useSearchParams } from "../../../utils/search-params"
import { useMembership } from "../../../utils/member-data"
import BasicFeatures from "./basic-features"
import PremiumFeatures from "./premium-features"
import InfiniteFeatures from "./infinite-features"

//------------------------------------------------------------------------------
const useAnnualBilling = () => {
    const search = useSearchParams()
    return ((search?.get('bill') ?? "a") !== "q")
}

const ToggleQuarterlyAnnual = () => {
    const isAnnual = useAnnualBilling()
    const setAnnual = (isAnnual) => navigate(`/sign-up/?bill=${isAnnual ? "a" : "q"}`)

    return (<p><center>
        <Toggle
            labels={["Quarterly Billing", "Annual Billing"]}
            isChecked={isAnnual}
            setChecked={setAnnual}
        /><br />
        <em>Save big with longer commitments</em>
    </center></p>)
}

//------------------------------------------------------------------------------
const LayoutTable = styled.div`
    display: grid;
    grid-template-areas:
        "basic-1"
        "basic-2"
        "basic-3"
        "premium-1"
        "premium-2"
        "premium-3"
        "infinite-1"
        "infinite-2"
        "infinite-3";
    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-areas:
            "basic-1 premium-1 infinite-1"
            "basic-2 premium-2 infinite-2"
            "basic-3 premium-3 infinite-3";
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1em;
    }
    margin-top: 0.5em;
`

const LayoutPlan1 = styled(Container)`
    grid-area: ${props => props.plan}-1;
    background-color: ${props => props.theme.colors.bgHighlight};
    border: ${props => props.plan === "premium" ? props.theme.dimensions.lineThickness : "1px"} 
        solid 
        ${props => props.plan === "premium" ? props.theme.colors.decorative : props.theme.colors.textLight};
    border-radius: ${props => props.theme.dimensions.cornerRadius} ${props => props.theme.dimensions.cornerRadius} 0 0;
    border-style: solid solid none solid;
    text-align: center;
    & h2 {
        margin-top: 0;
    }
    & p {
        margin-bottom: 0;
    }
    font-size: smaller;
    position: relative;
    &:after {
        position: absolute;
        top: 0;
        right: 0.5em;
        content: "${props => props.plan === "premium" ? "Most Popular" : null}";
        color: ${props => props.theme.colors.decorative};
        font-style: italic;
    }
`

const LayoutPlan2 = styled(Container)`
    grid-area: ${props => props.plan}-2;
    background-color: ${props => props.theme.colors.bgHighlight};
    border: ${props => props.plan === "premium" ? props.theme.dimensions.lineThickness : "1px"} 
        solid 
        ${props => props.plan === "premium" ? props.theme.colors.decorative : props.theme.colors.textLight};
    border-style: none solid none solid;
    text-align: center;
    font-size: smaller;
    padding-top: 0;
    border-bottom: 1px solid ${props => props.theme.colors.decorative};
`

const LayoutPlan3 = styled(Container)`
    grid-area: ${props => props.plan}-3;
    border: ${props => props.plan === "premium" ? props.theme.dimensions.lineThickness : "1px"} 
        solid 
        ${props => props.plan === "premium" ? props.theme.colors.decorative : props.theme.colors.textLight};
    border-radius: 0 0 ${props => props.theme.dimensions.cornerRadius} ${props => props.theme.dimensions.cornerRadius};
    border-style: none solid solid solid;
    margin-bottom: 1em;
    font-size: smaller;
    & ul {
        padding-inline-start: 1.5em;
    }
    & strong {
        font-weight: 600;
        color: ${props => props.theme.colors.active};
    }
`

const LayoutIcon = styled.div`
    font-size: 2em;
    color: ${props => props.theme.colors.decorative};
`

//------------------------------------------------------------------------------
export const BasicPricing = () => {
    const membership = useMembership()

    let button = {}
    switch (membership?.level?.paid) {
        case 0:
        default:
            button = <ButtonSecondary text="sign up" to={membership?.links?.signupTrial} />
            break;
        case 1:
            button = <ButtonSecondary text="your plan" to={membership?.links?.signupBasic} />
            break;
        case 2:
        case 3:
            button = <ButtonSecondary text="included" to={membership?.links?.signupBasic} />
            break;
    }

    return (<LayoutPlan2 plan="basic">
        {button}<br /><br />
        <font size="+1"><strong>Always Free</strong></font><br />
        (No credit card required)
    </LayoutPlan2>)
}

export const PremiumPricing = () => {
    const isAnnual = useAnnualBilling()
    const membership = useMembership()
    const link = isAnnual ? membership?.links?.signupPremiumAnnual : membership?.links?.signupPremiumMonthly

    let button = {}
    switch (membership?.level?.paid) {
        case 0:
        default:
            button = <ButtonPrimary text="sign up" to={link} />
            break;
        case 1:
            button = <ButtonPrimary text="upgrade" to={link} />
            break;
        case 2:
            button = <ButtonSecondary text="your plan" to={link} />
            break;
        case 3:
            button = <ButtonSecondary text="included" to={link} />
            break;
    }

    return (<LayoutPlan2 plan="premium">
        {button}<br /><br />
        <font size="+1"><strong>{isAnnual === true ? "$41.50/month" : "$45.00/month"}</strong></font><br />
        {isAnnual === true? "(billed annually)" : "(billed quarterly)"}
    </LayoutPlan2>)
}

export const InfinitePricing = () => {
    const isAnnual = useAnnualBilling()
    const membership = useMembership()
    const link = isAnnual ? membership?.links?.signupInfiniteAnnual : membership?.links?.signupInfiniteMonthly

    let button = {}
    switch (membership?.level?.paid) {
        case 0:
        default:
            button = <ButtonPrimary text="sign up" to={link} />
            break;
        case 1:
        case 2:
            button = <ButtonPrimary text="upgrade" to={link} />
            break;
        case 3:
            button = <ButtonSecondary text="your plan" to={link} />
            break;
    }

    return (<LayoutPlan2 plan="infinite">
        {button}<br /><br />
        <font size="+1"><strong>{isAnnual === true ? "$66.50/month" : "$72.00/month"}</strong></font><br />
        {isAnnual === true? "(billed annually)" : "(billed quarterly)"}
    </LayoutPlan2>)
}

//------------------------------------------------------------------------------
export const PricingTable = () => {

    return (<div>
        <ToggleQuarterlyAnnual />

        <LayoutTable>

            <LayoutPlan1 plan="basic">
                <LayoutIcon><FaHeart /></LayoutIcon>
                <h2>Basic</h2>
                <p>
                    For curious investors - your baseline for tactical asset allocation
                </p>
            </LayoutPlan1>
            <BasicPricing />
            <LayoutPlan3 plan="basic">
                <BasicFeatures />
            </LayoutPlan3>

            <LayoutPlan1 plan="premium">
                <LayoutIcon><FaMedal /></LayoutIcon>
                <h2>Premium</h2>
                <p>
                    For serious investors - all of our best-in-class portfolios
                </p>
            </LayoutPlan1>
            <PremiumPricing />
            <LayoutPlan3 plan="premium">
                <PremiumFeatures />
            </LayoutPlan3>

            <LayoutPlan1 plan="infinite">
                <LayoutIcon><FaInfinity /></LayoutIcon>
                <h2>Infinite</h2>
                <p>
                    For demanding investors - productivity features for larger accounts
                </p>
            </LayoutPlan1>
            <InfinitePricing />
            <LayoutPlan3 plan="infinite">
                <InfiniteFeatures />
            </LayoutPlan3>

        </LayoutTable>
    </div>)
}

//==============================================================================
// end of file
