//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/portfolio/cumulative-returns
// Description: Widgets for portfolio display
// Created:     FUB, May 05, 2022
//==============================================================================

import React from "react"
import styled from "styled-components"
import { FaFileDownload } from "react-icons/fa"

import { usePortfolioData, usePortfolioDownloadChartUrl } from "../../../utils/portfolio-data"
import { TableSimple } from "../table"
import { CumulativeReturns as CumulativeReturnsWidget } from "../cumulative-returns"
import { Link } from "../link"

//------------------------------------------------------------------------------
const Img = styled.img`
    width: 100%;
`

//------------------------------------------------------------------------------
const CumulativeReturnsImage = ({ portfolio }) => { // eslint-disable-line no-unused-vars
    const data = usePortfolioData(portfolio)
    const imgUrl = data?.cumulativePerformanceChart

    return imgUrl ? (<div>
        <Img
            src={imgUrl}
            alt="TuringTrader.com"
        />
    </div>) : null
}

//------------------------------------------------------------------------------
const CumulativeReturnsTable = ({ portfolio }) => { // eslint-disable-line no-unused-vars
    const data = usePortfolioData(portfolio)
    return <TableSimple data={data?.cumulativePerformanceTable} />
}

//------------------------------------------------------------------------------
const CumulativeReturnsInteractive = ({ portfolio }) => {
    const data = usePortfolioData(portfolio)
    const chart = data?.cumulativePerformanceTable
    const apiUrl = usePortfolioDownloadChartUrl(portfolio)

    return (<>
        <CumulativeReturnsWidget data={chart} />
        <br/>
        
        &nbsp;
        <small>
            <Link href={apiUrl}>
                <FaFileDownload />
                &nbsp;Download as CSV<br /><br />
            </Link>
        </small>
    </>)
}

//------------------------------------------------------------------------------
//export const CumulativeReturns = CumulativeReturnsImage // backend-rendered image
//export const CumulativeReturns = CumulativeReturnsTable // 3-column table
export const CumulativeReturns = CumulativeReturnsInteractive // interactive chart

//==============================================================================
// end of file
