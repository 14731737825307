//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/sign-up-button
// Description: Sign-up button
// Created:     FUB, May 14, 2022
//==============================================================================

import React from "react"
import styled from "styled-components"

import { useMembership } from "../../utils/member-data"
import { ButtonPrimary } from "./button"

//------------------------------------------------------------------------------
const Outer = styled.div`
    display: inline-block;
`

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

//------------------------------------------------------------------------------
export const SignUpButton = ({ free }) => {
    const membership = useMembership()

    if (free === true) {
        return (
            <ButtonPrimary text="get free access" to={membership?.links?.signupTrial} />
        )
    }

    if (membership?.level.access === 0) {
        return (
            <Outer>
                <Inner>
                    <ButtonPrimary text="get free access for 14 days" to={membership?.links?.signupTrial} />
                    <small><em>No credit card required to sign up</em></small>
                </Inner>
            </Outer>
        )
    }

    if (membership?.level.paid < 2) {
        return (
            <ButtonPrimary text="upgrade your membership" to="/sign-up" />
        )
    }

    return null
}

//==============================================================================
// end of file
