//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/link
// Description: Internal and external links.
// Created:     FUB, Apr 19, 2022
//==============================================================================

import React from "react"
import { Link as GatsbyLink } from "gatsby"
//import styled from "styled-components"

import { LinkStore } from "./link-store"
import { Lightbox } from "../lightbox"

//------------------------------------------------------------------------------
const IsImage = (url) => (
    url?.endsWith(".png") ||
    url?.endsWith(".jpg")
)

const isExternal = (url) => (
    url?.includes("http://") ||
    url?.includes("https://") ||
    url?.includes("mailto:") ||
    url?.includes("tel:")
)

const isLookup = (url) => url?.startsWith("@")

const isAffiliate = (url) => url?.includes("//amzn.to/")

//------------------------------------------------------------------------------
// NOTE: this was LinkUnstyled before
export const Link = (props) => { //{ href, children, className, state }) => {
    const { href, children, className, state, target, rel } = props;
    const url = isLookup(href) ? LinkStore[href] : href;
    const ad = isAffiliate(url)
    const text = url || !href ? children : `broken: ${href}`

    if (IsImage(url)) return (
        <Lightbox src={href}>
            {children}
        </Lightbox>
    )

    if (isExternal(url) || !url) return (<>
        <a
            href={url}
            target={target ?? "_blank"}
            rel={rel ?? "noopener noreferrer"}
            className={`${className} ${ad && "tt-amzn"}`}
        >
            {text}
        </a>
        {ad && (<sup>#ad</sup>)}
    </>)

    return (
        <GatsbyLink
            to={url ?? "#"}
            state={state}
            className={className}
        >
            {text}
        </GatsbyLink>
    )
}

export const AssetLink = ({ ticker }) => {
    return <Link href={`https://finance.yahoo.com/quote/${ticker}`}>
        {ticker}
    </Link>
}

//==============================================================================
// end of file
