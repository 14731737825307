//==============================================================================
// Project:     www.TuringTrader.com
// Name:        utils/member-data
// Description: Access to member data.
// History:     2022, May 10, FUB, created
//==============================================================================

import React, { createContext, useContext, useState, useEffect } from "react"

import { /*useFirebaseMeta,*/ useKeyApi } from "./firebase"
import { getCookie, setCookie } from "./cookies"
import {
    useOutsetaInfo as useMembershipInfo,
    useOutsetaMeta as useMembershipMeta
} from "./outseta"
export { Outseta_onRenderBody as Membership_onRenderBody } from "./outseta"

//------------------------------------------------------------------------------
const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`MEMBER-DATA: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`MEMBER-DATA: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
// * * *   S u p p o r t   F u n c t i o n s   * * * * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

// hook to store metadata to local storage
/*const useLocalStorageMeta = (membership) => {
    const [meta, setMeta] = useState(undefined)

    //----- retrieve metadata
    useEffect(() => {
        let isActive = true
        let timer = null

        if (membership?.user?.id) {
            const getMeta = () => {
                if (isActive !== true) return

                try {
                    const savedMeta = localStorage.getItem("membership")
                    const parsedMeta = JSON.parse(savedMeta)
                    const metaId = parsedMeta?.membership.id
                    if (metaId === membership.user.id) {
                        //if (metaId === 1212887) {
                        //    // FIXME: remove this code in November 2022
                        //    // this code helps purge metadata after
                        //    // the leak that occourred on October 1st, 2022
                        //    DEBUG_MSG(`discard local meta data for ${metaId}`)
                        //    setMeta({})
                        //} else {
                        //    DEBUG_MSG(`load local meta data for ${metaId}`)
                        setMeta(parsedMeta)
                        //}
                    } else {
                        // discard existing metadata and create new
                        setMeta({})
                    }

                    // we want to make sure that there is some
                    // mechanism for syncing across browser tabs,
                    // even for Basic members.
                    // local data have low priority during merge,
                    // so speeding this up won't improve things
                    timer = setTimeout(getMeta, 60000) // once per minute
                }
                catch (error) {
                    ERROR_MSG(error)
                }
            }

            getMeta()
        } else {
            setMeta(null)
        }

        return () => {
            isActive = false
            if (timer) clearTimeout(timer)
        }
    }, [membership])

    //----- set metadata from the UI
    const externalSetMeta = (newMeta) => {
        // don't update before we received metadata here
        if (!meta) return

        const newMeta2 = cleanMeta(newMeta)
        setMeta(newMeta2)
        localStorage.setItem("membership", JSON.stringify(newMeta2))
    }

    return [meta, externalSetMeta]
}*/

// function to remove old metadata tags
export const cleanMeta = (meta, fnClean = function (obj, key) { delete obj[key] }) => {
    const keysToClean = Object.keys(meta)
        /*.filter(key => key.startsWith("dash/") ||
            key.startsWith("nav/") ||
            key.startsWith("member/") ||
            key.startsWith("spec/") ||
            key.startsWith("sim/") ||
            key === "isClean" ||
            key === "list" ||
            key === "memberid" ||
            key === "newSettings" ||
            key === "sendNotifications" ||
            key === "test" ||
            key === "ernie" ||
            key.length < 2
        )*/
        .filter(key => key !== "accounts" &&
            key !== "admin" &&
            key !== "created" &&
            key !== "membership" &&
            key !== "settings")

    const cleanedMeta = keysToClean.reduce(
        (acc, key) => { fnClean(acc, key); return acc },
        { ...meta }
    )

    return cleanedMeta
}

// compare two metadata objects
export const diffMeta = (meta1, meta2) => {
    const diffFields = [
        !meta1,
        !meta2,
        JSON.stringify(meta1?.accounts) !== JSON.stringify(meta2?.accounts),
        JSON.stringify(meta1?.admin) !== JSON.stringify(meta2?.admin),
        JSON.stringify(meta1?.created) !== JSON.stringify(meta2?.created),
        JSON.stringify(meta1?.membership) !== JSON.stringify(meta2?.membership),
        JSON.stringify(meta1?.settings) !== JSON.stringify(meta2?.settings)
    ]

    return diffFields.reduce((prev, current) => prev || current, false)
}

//------------------------------------------------------------------------------
// * * *   C o n t e x t   P r o v i d e r   * * * * * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

export const MemberDataContext = createContext(null)

export const MemberDataProvider = ({ children }) => {
    //----- info and meta directly from membership platform
    const memberinfo1 = useMembershipInfo()
    const [membermeta1, setMembermeta1] = useMembershipMeta(memberinfo1)

    //----- determine account creation date
    // we use the earliest occurence of joining a plan
    // or storing metadata for the account
    const [createDate, setCreateDate] = useState()
    useEffect(() => {
        const infoDate = memberinfo1?.user.firstJoin // this is a Date

        let metaDate = null
        try {
            if (membermeta1?.created?.date)
                metaDate = new Date(membermeta1.created.date)
        } catch (error) { }

        let date = new Date()
        if (infoDate && infoDate < date) date = infoDate
        if (metaDate && metaDate < date) date = metaDate

        setCreateDate(date)
    }, [memberinfo1, membermeta1])

    //----- determine account id
    // the account id can be overridden through a
    // metadata field. we use this to keep ids 
    // unchanged after MemberSpace => Outseta transition
    const [accountId, setAccountId] = useState()
    useEffect(() => {
        const infoId = memberinfo1?.user?.id
        const legacyId = membermeta1?.admin?.legacyId?.toString()
        const id = legacyId && legacyId.length > 0 ? legacyId : infoId

        if (id !== accountId)
            setAccountId(id)
    }, [memberinfo1, membermeta1, accountId])

    //----- grant/ block trial period
    // we grant a 14-day trial, which upgrades any
    // logged in user to level 2 (Premium)
    // we block repeated trials by making sure the
    // current account id matches the cookie we stored
    const [paidLevel, setPaidLevel] = useState()
    const [accessLevel, setAccessLevel] = useState()
    const [blacklisted, setBlacklisted] = useState()
    useEffect(() => {
        let paid = memberinfo1?.level?.paid ?? 0
        let access = memberinfo1?.level?.access ?? 0

        // grant 14-day trial
        const isInTrialPeriod = Date.parse(createDate) + 14 * 24 * 60 * 60 * 1000 > Date.now()

        if (isInTrialPeriod && access > 0)
            access = Math.max(access, 2)

        // block repeated trial
        // NOTE: this is hinged on the raw membership id,
        //       as provided by the membership solution.
        //       that way, changing the membership id via
        //       the admin settings won't result in blocking
        const cookie = "trialId"
        const currentTrialId = memberinfo1?.user?.id
        const previousTrialId = getCookie(cookie)?.toString()
        const isBlacklisted = access > 1 && paid < 2 &&
            previousTrialId && previousTrialId.length > 0 &&
            currentTrialId && currentTrialId !== previousTrialId

        if (isBlacklisted)
            access = paid

        if (currentTrialId && (!previousTrialId || previousTrialId.length === 0))
            setCookie(cookie, currentTrialId)

        setPaidLevel(paid)
        setAccessLevel(access)
        setBlacklisted(isBlacklisted)
    }, [memberinfo1, createDate, accountId])

    //----- compose new info object
    // this object has adjusted values for the account id,
    // account creation date, blacklisting, and access levels.
    // Further, this object includes flags to control access 
    // rights for basic, premium, and infinite features.
    const [memberinfo2, setMemberInfo2] = useState()
    useEffect(() => {
        const featureAccess = (featureLevel) => ({
            hasAccess: featureLevel <= accessLevel,
            hasFullAccess: featureLevel <= paidLevel,
            hasTrialAccess: featureLevel > paidLevel && featureLevel <= accessLevel,
            needsBasic: accessLevel < 1 && featureLevel === 1,
            needsTrial: accessLevel < 1 && featureLevel === 2,
            needsPremium: accessLevel >= 1 && accessLevel < 2 && featureLevel === 2,
            needsInfinite: accessLevel >= 1 && accessLevel < 3 && featureLevel === 3,
        })

        const memberInfo = {
            user: {
                ...memberinfo1?.user,
                id: accountId,
                created: createDate,
                isBlacklisted: blacklisted,
            },
            level: {
                ...memberinfo1?.level,
                paid: paidLevel,
                access: accessLevel,
                year: memberinfo1?.level.year,
                beta: memberinfo1?.level.beta,
                debug: memberinfo1?.level.debug,
            },
            links: {
                ...memberinfo1?.links,
            },
            features: {
                basic: featureAccess(1),
                premium: featureAccess(2),
                infinite: featureAccess(3),
                beta: {
                    hasAccess: memberinfo1?.level?.beta,
                },
                felix: {
                    hasAccess: [
                        "- DEBUG -",
                        1212887, // www.TuringTrader.com, premium@turingtrader.com
                    ].includes(accountId)
                },
                debug: {
                    hasAccess: memberinfo1?.level?.debug,
                }
            },
        }
        setMemberInfo2(memberInfo)
    }, [memberinfo1, createDate, accountId, blacklisted, paidLevel, accessLevel])

    //----- determine first referrer
    // we use the first referrer to attribute members to 
    // the site/ campaign that first referred them
    const cookieId = "firstReferrer"
    const cookieDays = 60
    const [firstReferrer, setFirstReferrer] = useState(getCookie(cookieId))
    useEffect(() => {
        if (firstReferrer && firstReferrer.length > 0) return

        const windowLocation = window?.location
        const searchParams = new URLSearchParams(windowLocation.search)

        const hasUtmParameters = searchParams.has("utm_source") ||
            searchParams.has("utm_medium") ||
            searchParams.has("utm_campaign")

        if (hasUtmParameters) {
            const utmSource = searchParams.get("utm_source")
            const utmMedium = searchParams.get("utm_medium")
            const utmCampaign = searchParams.get("utm_campaign")

            const referrerString = (utmSource ? "utm_source=" + utmSource : "") +
                (utmMedium ? "utm_medium=" + utmMedium : "") +
                (utmCampaign ? "utm_campaign=" + utmCampaign : "")

            DEBUG_MSG(`first referrer: ${referrerString}`)
            setFirstReferrer(referrerString)
            setCookie(cookieId, referrerString, cookieDays)

            return
        }

        // NOTE: this is done for compatibility w/ Rewardful
        const hasViaParameter = searchParams.has("via")

        if (hasViaParameter) {
            const referrerString = "via=" + searchParams.get("via")

            DEBUG_MSG(`first referrer: ${referrerString}`)
            setFirstReferrer(referrerString)
            setCookie(cookieId, referrerString, cookieDays)

            return
        }

        /*
        // NOTE: document.referrer doesn't work
        const documentReferrer = document?.referrer

        if (documentReferrer) {
            const referrerString = "referrer=" + documentReferrer

            DEBUG_MSG(`first referrer: ${referrerString}`)
            setFirstReferrer(referrerString)
            setCookie(cookieId, referrerString, cookieDays)

            return
        }
        */

        // no UTM parameters, no referrer: this must be direct traffic
        DEBUG_MSG(`first referrer: direct`)
        setFirstReferrer("direct")
        setCookie(cookieId, "direct", cookieDays)
    }, [firstReferrer])

    //----- create init-once metadata
    // no other metadata source ever sets these fields.
    // they get picked up by metadata sources with higher
    // priority and then stick
    const [initMeta, setInitMeta] = useState()
    useEffect(() => {
        const meta = {
            created: {
                date: createDate?.toISOString(),
                referrer: createDate > new Date("2023-09-01") ? (
                    firstReferrer && firstReferrer.length > 0 ?
                        firstReferrer :
                        undefined
                ) : (
                    "unknown"
                ),
            }
        }
        setInitMeta(meta)
    }, [createDate, firstReferrer])

    //----- create last access timestamp
    const [lastAccess, setLastAccess] = useState()
    useEffect(() => {
        let isActive = true

        const getTime = () => {
            if (!isActive) return

            const rounding = 5 * 60 * 1000 // 5m
            const epoch = rounding * Math.floor(Date.now() / rounding)
            setLastAccess(new Date(epoch))
            setTimeout(getTime, rounding)
        }
        getTime()

        return () => {
            isActive = false
        }
    }, [])

    //----- create highest priority TuringTrader-controlled metadata
    const [constMeta, setConstMeta] = useState()
    useEffect(() => {
        const meta = {
            membership: {
                "id": accountId,
                "firstName": memberinfo1?.user.firstName,
                "lastName": memberinfo1?.user.lastName,
                "email": memberinfo1?.user.email,
                "level": accessLevel,
                "paid": paidLevel,
                "timestamp": lastAccess ? lastAccess.toISOString() : undefined,
                "version": 3,
            },
        }
        setConstMeta(meta)
    }, [memberinfo1, accountId, accessLevel, paidLevel, lastAccess])

    //----- merge metadata from various sources
    const [membermeta2, setMembermeta2] = useState(undefined)
    const [chgMeta, setChgMeta] = useState({})
    useEffect(() => {
        const tmpMeta = {
            ...initMeta,    // init-once data - lowest priority
            ...membermeta1, // meta from membership platform
            ...chgMeta,     // pending changes
            ...constMeta,   // constant data - highest priority
        }
        const tmpMetaWithoutChanges = {
            ...initMeta,    // init-once data - lowest priority
            ...membermeta1, // meta from membership platform
            //...chgMeta,   // ignoring pending changes
            ...constMeta,   // constant data - highest priority
        }
        //DEBUG_MSG(`merged=${JSON.stringify(tmpMeta)}`)

        // save metadata to membership
        if (diffMeta(tmpMeta, membermeta1) === true)
            setMembermeta1(tmpMeta)

        // update merged metadata
        if (diffMeta(tmpMeta, membermeta2) === true)
            setMembermeta2(tmpMeta)

        // clear pending changes
        //if (diffMeta(chgMeta, {}) === true)
        if (diffMeta(tmpMeta, tmpMetaWithoutChanges) === false &&
            diffMeta(chgMeta, {}) === true) {
            //DEBUG_MSG("---")
            //DEBUG_MSG(`tmpMeta = ${JSON.stringify(tmpMeta?.settings)}`)
            //DEBUG_MSG(`tmpMetaWithoutChanges = ${JSON.stringify(tmpMetaWithoutChanges?.settings)}`)
            //DEBUG_MSG(`chgMeta = ${JSON.stringify(chgMeta?.settings)}`)
            setChgMeta({})
        }
    }, [initMeta, constMeta, membermeta1, chgMeta, membermeta2, setMembermeta1])

    //----- feed data into MemberDataContext
    const [contextData, setContextData] = useState({})
    useEffect(() => {
        setContextData({
            info: memberinfo2,
            meta: membermeta2,
            setMeta: setChgMeta,
        })
    }, [memberinfo2, membermeta2])

    return (
        <MemberDataContext.Provider value={contextData}>
            {children}
        </MemberDataContext.Provider>
    )
}

//------------------------------------------------------------------------------
// * * *   M e m b e r   d a t a   h o o k s   * * * * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

/**
 * React hook to retrieve the member's membership information.
 * 
 * Returns an object with user, level, and features fields.
 * 
 * @returns membership object
 */
export const useMembership = () => {
    const memberData = useContext(MemberDataContext)
    return memberData?.info
}

/**
 * React hook to retrieve the member's API key.
 * 
 * Returns a string with the API key.
 * 
 * @returns API key for this user
 */
export const useApiKey = () => {
    const membership = useMembership()
    const apiKey = useKeyApi(membership)

    return apiKey
}

/*export const useBetaFeature = () => {
    const membership = useMembership()

    return membership?.features?.beta?.hasAccess === true
}*/

/**
 * React hook to access the member's private data.
 * 
 * Returns an array in the style of useState.
 * First element contains the member's metadata. The second element is
 * a function to update the metadata with new fields or values.
 * 
 * @returns [memberMeta, setMemberMeta]
 */
export const useMemberdata = () => {
    const memberData = useContext(MemberDataContext)
    return [memberData?.meta, memberData.setMeta]
}

//==============================================================================
// end of file
