//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/portfolio-wiz
// Description: Portfolio finder wizard.
// Created:     FUB, May 30, 2022
//==============================================================================

import React from "react"
//import styled from "styled-components"
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"

export const Quote = ({ children, author, className }) => (
    <div
        css={`
            border: 1px dotted ${props => props.theme.colors.decorative};
            border-style: solid none solid none;
            & p {
                display: inline;
            }
            margin-left: 3rem;
            margin-right: 3rem;
            @media ${props => props.theme.breakpoints.desktop} {
                margin-left: 5rem;
                margin-right: 5rem;
            }
            margin-top: 3rem;
            margin-bottom: 3rem;
            color: ${props => props.theme.colors.accent};
            display: flex;
            justify-content: space-between;
            column-gap: ${props => props.theme.spacings.normal};
        `}
        className={className}
    >
        <div css={`font-size:2em;`}>
            <FaQuoteLeft />
        </div>
        <div><em>
            <div css={`text-align: center;`}>
                {children}
            </div>
            <div css={`text-align: right;`}>
                - {author}
            </div>
        </em></div>
        <div css={`font-size:2em;`}>
            <FaQuoteRight />
        </div>
    </div>
)

//==============================================================================
// end of file
