//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/content-plus-decor
// Description: Layout primitives.
// Created:     FUB, July 02, 2022
//==============================================================================

import React from "react"
//import styled from "styled-components"

//------------------------------------------------------------------------------
export const ContentPlusDecor = ({ children, decor }) => (<div
    css={`
        display: flex;
    `}
>
    <div
        css={`
            display: none;
            @media ${props => props.theme.breakpoints.desktop} {
                display: inline-block;
                flex: 1 0 ${props => props.theme.dimensions.largeIcon};
                font-size: ${props => props.theme.dimensions.largeIcon};
                color:${props => props.theme.colors.decorative};
                margin-right: ${props => props.theme.dimensions.largeIcon};
            }
        `}
    >
        {decor}
    </div>
    <div
        css={`
            flex:999 0 max(66%,300px);
        `}
    >
        {children}
    </div>
</div>)

//==============================================================================
// end of file
