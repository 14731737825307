import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`All Basic features, plus:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#premium-portfolios"
          }}>{`Trade with Premium portfolios`}</a></strong><br />{`
Choose from our family of proprietary portfolios for better returns at lower risk`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#all-stars-portfolios"
          }}>{`Trade with All-Stars portfolios`}</a></strong><br />{`
Successfully cover an even broader range of market situations with meta-portfolios`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#rebalance-alerts"
          }}>{`Receive Portfolio Rebalance Alerts`}</a></strong><br />{`
We’ll email you when your portfolio needs attention`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      