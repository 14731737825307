import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import styled from "styled-components";
import { Container } from "../widgets/container";
export const FooterContainer = styled(Container)`
    color: ${props => props.theme.colors.bgCanvas};
    background-color: ${props => props.theme.colors.text};
    font-size: smaller;
`;
export const FlexRow = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const FlexCol = styled.div`
    & p {
        margin-bottom: 0;
    }
    & ul {
        margin-top: 0;
        padding-left: 0.6em;
        list-style-type: "- ";
        & a {
            color: ${props => props.theme.colors.bgCanvas};
            text-decoration: none;
        }
    }
`;
export const _frontmatter = {};
const layoutProps = {
  FooterContainer,
  FlexRow,
  FlexCol,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <FooterContainer mdxType="FooterContainer">
      <FlexRow mdxType="FlexRow">
        <FlexCol mdxType="FlexCol">
          <p><strong parentName="p">{` Site `}</strong></p>
          <ul>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/sign-up/"
              }}>{`Plans`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/articles/"
              }}>{`Articles`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/company/newsletter/"
              }}>{`Newsletter`}</a></li>
          </ul>
        </FlexCol>
        <FlexCol mdxType="FlexCol">
          <p><strong parentName="p">{` Connect `}</strong></p>
          <ul>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.facebook.com/turingtrader"
              }}>{`Facebook`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.youtube.com/channel/UCcEpocSCQklPPw6-ybCkSfw"
              }}>{`YouTube`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.linkedin.com/in/felixbertram/"
              }}>{`LinkedIn`}</a></li>
          </ul>
        </FlexCol>
        <FlexCol mdxType="FlexCol">
          <p><strong parentName="p">{` Company `}</strong></p>
          <ul>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/company/disclaimer/"
              }}>{`Disclaimer`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/company/terms-of-use/"
              }}>{`Terms of Use`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/company/referral-program/"
              }}>{`Referral Program`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/company/contact/"
              }}>{`Contact`}</a></li>
          </ul>
        </FlexCol>
      </FlexRow>
      <p>{`©`}{` 2023 TuringTrader.com. All rights reserved.`}</p>
    </FooterContainer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      