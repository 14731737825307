//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/portfolio-ref
// Description: Widgets to reference charts and metrics from a portfolio.
// Created:     FUB, July 1, 2022
//==============================================================================

import React from "react"

import { usePortfolioLatest } from "../../utils/portfolio-list"
import { usePortfolioData } from "../../utils/portfolio-data"
import { Lightbox } from "./lightbox"
import { TableSimple } from "./table"
//------------------------------------------------------------------------------
export const PortfolioChart = ({ portfolio }) => {
    const pfLatest = usePortfolioLatest(portfolio)
    const pfData = usePortfolioData(pfLatest)

    return (<Lightbox title="Cumulative Returns" src={pfData?.cumulativePerformanceChart}>
        <img 
            css={`width:100%;`}
            src={pfData?.cumulativePerformanceChart} 
            alt="TuringTrader.com"
        />
    </Lightbox>)
}

export const PortfolioMonteCarlo = ({ portfolio }) => {
    const pfLatest = usePortfolioLatest(portfolio)
    const pfData = usePortfolioData(pfLatest)

    return (<Lightbox title="Monte Carlo Simulation" src={pfData?.monteCarloChart}>
        <img 
            css={`width:100%;`}
            src={pfData?.monteCarloChart} 
            alt="TuringTrader.com"
        />
    </Lightbox>)
}

export const PortfolioMonteCarlo2 = ({ portfolio }) => {
    const pfLatest = usePortfolioLatest(portfolio)
    const pfData = usePortfolioData(pfLatest)

    return (<Lightbox title="Monte Carlo Simulation" src={pfData?.monteCarloChart2}>
        <img 
            css={`width:100%;`}
            src={pfData?.monteCarloChart2} 
            alt="TuringTrader.com"
        />
    </Lightbox>)
}

export const PortfolioMetrics = ({ portfolio }) => {
    const pfLatest = usePortfolioLatest(portfolio)
    const pfData = usePortfolioData(pfLatest)

    return (<TableSimple
        data={pfData?.metricsTable}
    />)
}

//==============================================================================
