//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/mdx/portfolio.js
// Description: MDX page layout for portfolio page.
// Created:     FUB, May 05, 2022
//==============================================================================

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { MDXProvider } from "@mdx-js/react"

import { Page } from "../layout/page"
import { Border } from "../widgets/container"
import { ButtonHelp, ButtonPrimary, ButtonSecondary } from "../widgets/button"
import { Link } from "../widgets/link"
import { components } from "./component-mapping"
import { CumulativeReturns, Metrics, AnnualReturns, AssetAllocation, AssetAllocationDummy, MonteCarlo, RollingReturns, AccountWiz } from "../widgets/portfolio"
import { usePortfolioData } from "../../utils/portfolio-data"
import { useMembership } from "../../utils/member-data"
import { Breadcrumbs } from "../widgets/breadcrumbs"

//------------------------------------------------------------------------------

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`PF-TEMPLATE: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`PF-TEMPLATE: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
/*const Placeholder = styled.div`
    background-color: red;
    border: 3px solid black;
`*/

const PageGrid = styled.div`
    display: grid;
    grid-template-areas:
        "h1"
        "nav"
        "mdx1"
        "dynamic"
        "mdx2"
        "cta"
        "notes"
`

export const Summary = styled.div`
    grid-area: mdx1;
    & p {
        margin-top: 0;
    }
`

export const Review = styled.div`
    grid-area: mdx2;
`

export const Notes = styled.div`
    grid-area: notes;
    font-size: small;
    color: ${props => props.theme.colors.textLight};
    border-top: 1px dotted ${props => props.theme.colors.textLight};
    margin-top: 4em;
`

/*
export const Aside = styled(Box)`
    font-size: small;
    float: right;
    max-width: min(200px, 25%);
    margin-left: ${props => props.theme.spacings.normal};
    & p {
        color: ${props => props.theme.colors.accent};
        margin-bottom: 0.5rem;
    }
    & ul {
        margin-top: 0.5rem;
        padding-left: 1.5em;
    }
`
*/
export { Aside } from "../widgets/aside"

const NavArea = styled.div`
    grid-area: nav;
`

const DynamicContentArea = styled.div`
    grid-area: dynamic;
`

const Cta = styled.div`
    grid-area: cta;
`

const TwoCols = styled.div`
    /*width: 100%;*/
    /*max-width: 100%;*/
    display: flex;
`

const ColSeparator = styled.div`
    border-left: 1px solid ${props => props.theme.colors.decorative};
    margin-left: ${props => props.theme.spacings.normal};
    margin-right: ${props => props.theme.spacings.normal};
`

const Row = styled.div`
    display: flex;
    gap: 2em;
    padding-top: 1em;
    padding-bottom: 2em;
`

//------------------------------------------------------------------------------
const Nav = (props) => {
    return (
        <NavArea>
            <Breadcrumbs to="/portfolios/" text="Portfolios" />
            <h2>Summary</h2>
        </NavArea>

    )
}

//------------------------------------------------------------------------------
const CumulativePerformanceChart = ({ portfolio }) => {
    return (<>
        <CumulativeReturns portfolio={portfolio} />
    </>)
}

const AnnualPerformanceChart = ({ portfolio }) => {
    return (
        <AnnualReturns portfolio={portfolio} />
    )
}

const PerformanceMetricsTable = ({ portfolio }) => {
    return (
        <Metrics portfolio={portfolio} />
    )
}

const AssetAllocationTable = ({ portfolio, access }) => {
    return access?.hasAccess === true ? (
        <AssetAllocation portfolio={portfolio} access={access} />
    ) : (
        <AssetAllocationDummy access={access} />
    )
}

const MonteCarloChart = ({ portfolio }) => {
    return (
        <MonteCarlo portfolio={portfolio} />
    )
}

const RollingReturnsChart = ({ portfolio }) => {
    return (
        <RollingReturns portfolio={portfolio} />
    )
}

const StartInvesting = ({ portfolio, slot, access }) => {
    const [showWiz, setWiz] = useState(false)

    //----- slot #1: below summary text --------------------
    if (slot === 1) {
        return (<>
            <Row>
                <ButtonPrimary text="Invest In This Portfolio" onClick={() => setWiz(true)} />
                <ButtonHelp to="/help/tactical-asset-allocation/" text="How Our Portfolios Work" />
            </Row>
            {showWiz && <AccountWiz portfolio={portfolio} access={access} onCancel={() => setWiz(false)} />}
        </>)
    }

    //----- slot #2: below asset allocation --------------------
    if (slot === 2) {
        if (access?.hasAccess) {
            DEBUG_MSG(`portfolio=${portfolio}`)
            return (<>
                {access.hasTrialAccess && (
                    <Border>
                        <p>
                            You are currently enjoying <strong>trial access</strong> to
                            this portfolio. For continued access, please upgrade to
                            our <strong>Premium</strong> membership.
                        </p>
                        <ButtonSecondary text="Upgrade Now" to="/sign-up/" />
                    </Border>
                )}
                <Row>
                    <ButtonPrimary text="Invest In This Portfolio" onClick={() => setWiz(true)} />
                </Row>
                {showWiz && <AccountWiz portfolio={portfolio} access={access} onCancel={() => setWiz(false)} />}
            </>)
        }

        // no access
        return null // message shown in asset allocation table
        /*if (access?.needsBasic) return (
            <Box>
                <p>
                    You can see the asset allocation for this portfolio with
                    our <strong>free Basic membership</strong>.
                </p>
                <SignUpButton free={true}/>
            </Box>
        )

        if (access?.needsTrial) return (
            <Box>
                <p>
                    You can see the asset allocation for this portfolio with
                    our <strong>14-day free trial</strong>.
                </p>
                <SignUpButton />
            </Box>
        )

        if (access?.needsPremium) return (
            <Box>
                <p>
                    You can see the asset allocation for this portfolio with
                    our <strong>Premium membership</strong>.
                </p>
                <SignUpButton />
            </Box>
        )

        return null // we should never get here*/
    }

    //----- slot #3: bottom of page --------------------
    return (<>
        <Row>
            <ButtonPrimary text="Invest In This Portfolio" onClick={() => setWiz(true)} />
            <ButtonSecondary to="/portfolios/" text="Explore Portfolios" />
        </Row>
        {showWiz && <AccountWiz portfolio={portfolio} access={access} onCancel={() => setWiz(false)} />}
    </>)
}

const PortfolioSuperseded = () => (
    <Border>
        We superseded this portfolio with an updated and improved version.
        Click <Link href="./">here</Link> to see the latest release.
    </Border>
)

const DynamicContent = ({ portfolio, superseded, access }) => {
    const pfData = usePortfolioData(portfolio)

    return (
        <DynamicContentArea>
            {superseded === true && (
                <PortfolioSuperseded />
            )}

            <StartInvesting portfolio={portfolio} slot={1} access={access} />

            <h2>Performance</h2>

            <p>
                This table shows the portfolio's key performance metrics over
                the course of the simulation:
            </p>

            <br/>
            <PerformanceMetricsTable portfolio={portfolio} />
            <br/>

            <p>
                The following chart shows the portfolio's historical performance
                and drawdowns, compared to their benchmark, throughout the simulation:
            </p>

            <br/>
            <CumulativePerformanceChart portfolio={portfolio} />
            <br/>
            <br/>

            <p>
                This chart shows the portfolio's annual returns:
            </p>

            <br/>
            <AnnualPerformanceChart portfolio={portfolio} />
            <br/>

            <p>
                The following charts show the Monte-Carlo simulation of returns
                and drawdowns, the portfolios 12-months rolling returns, and
                how the portfolio is tracking to its benchmark:
            </p>

            <br/>
            <TwoCols>
                <MonteCarloChart portfolio={portfolio} />
                <ColSeparator />
                <RollingReturnsChart portfolio={portfolio} />
            </TwoCols>

            <h2>Asset Allocation</h2>

            <p>
                The portfolio last required rebalancing after the exchanges
                closed on <strong>{pfData?.lastRebalance}</strong>. Due to fluctuations
                in asset prices, the exact allocations vary daily, even when no
                rebalancing occurred. The current asset allocation is as follows:
            </p>

            <br/>
            <AssetAllocationTable portfolio={portfolio} access={access} />
            <StartInvesting portfolio={portfolio} slot={2} access={access} />

            <br />

        </DynamicContentArea>
    )
}

//------------------------------------------------------------------------------
export const PortfolioPage = ({ location, children, path, pageContext }) => {
    const { frontmatter } = pageContext
    const portfolioBase = path.replace("/portfolios/", "").replace("/", "")
    const portfolioLatest = parseInt(frontmatter.version)
    const [portfolioVersion, setPortfolioVersion] = useState(portfolioLatest)
    const membership = useMembership()
    const memberId = membership?.user?.id
    const access = pageContext.frontmatter.tags.includes("premium") ?
        membership?.features.premium : membership?.features.basic

    useEffect(() => {
        const search = location.search.length > 1 ? location.search.substring(1) : ""
        let version = frontmatter.version

        if (search.length > 0) {
            search.split("&").forEach((t) => {
                const kv = t.split("=")
                const k = kv[0]
                const v = parseInt(kv[1])

                if (k === "v" && v >= 1 && v <= portfolioLatest)
                    version = v
            })
        }
        setPortfolioVersion(version)
    }, [location, path, frontmatter.version, portfolioLatest, setPortfolioVersion])

    const portfolio = portfolioBase + (portfolioVersion !== 1 ? `-v${portfolioVersion}` : "")
    const superseded = portfolioVersion < portfolioLatest

    const isVisible = !pageContext.frontmatter.tags.includes("private") ||
        (pageContext.frontmatter.tags.includes("private") && pageContext.frontmatter.tags.includes(memberId?.toString()))

    return isVisible ? (
        <Page title={frontmatter.title} description={frontmatter.description} location={location}>

            <PageGrid>
                <MDXProvider components={components}>
                    {children}
                </MDXProvider>

                <Nav />
                <DynamicContent portfolio={portfolio} superseded={superseded} access={access} />

                <Cta>
                    <StartInvesting portfolio={portfolio} slot={3} access={access} />
                </Cta>
            </PageGrid>

        </Page>
    ) : `access to this page is restricted. memberId = ${memberId}`
}

export default PortfolioPage

//==============================================================================
// end of file
