//==============================================================================
// Project:     www.TuringTrader.com
// Name:        gatsby-browser.js
// Description: Gatsby browser APIs.
// History:     2022iv23, FUB, created
//==============================================================================

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// NOTE: can't mix ES6 and CommonJS
const React = require("react")
const { RootWrapper } = require("./src/utils/root-wrapper")
const { logPageView } = require("./src/utils/firebase")

//const { MemberDataProvider } = require("./src/utils/member-data")
//const { FirebaseContextProvider} = require("./src/utils/firebase")

//------------------------------------------------------------------------------
exports.wrapRootElement = ({ element }) => {
    return (
        <RootWrapper>
            {element}
        </RootWrapper>
    )

    /*return (
        <FirebaseContextProvider>
            <MemberDataProvider>
                {element}
            </MemberDataProvider>
        </FirebaseContextProvider>
    )*/
}

//------------------------------------------------------------------------------
// this is to support scrolling to ids.
// see https://github.com/gatsbyjs/gatsby/issues/8413#issuecomment-423857767
// https://github.com/CanRau/gatsby-scroll-bug/blob/master/gatsby-browser.js

const scrollTo = (id) => () => {
    const el = document.querySelector(id)
    if (el) return window.scrollTo(0, el.offsetTop - 20)
    return false
}

exports.onRouteUpdate = ({ location }) => {
    //----- implement scroll-to-id
    if (location.hash) {
        window.setTimeout(scrollTo(location.hash), 10)
    }

    //----- implement page view analytics
    setTimeout(() => {
        const pagePath = location
            ? location.pathname + location.search + location.hash
            : undefined
        logPageView(pagePath)
    }, 10)

    return null
}

//==============================================================================
// end of file
