//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/page.js
// Description: Global page layout.
// Created:     FUB, Apr 16, 2022
//==============================================================================

import React from "react"
import { ThemeProvider } from "styled-components"

import { theme } from "./theme"
import { GlobalStyles } from "./global-styles"
import { Seo } from "./seo"
import { Container } from "../widgets/container"
import { Header } from "./header"
import Footer from "./footer"
import Banner from "./banner"
import { DebugWarning } from "./debug-warning"

//------------------------------------------------------------------------------
export const Page = ({ title, description, h1, backName, backTo, location, children }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Seo title={title} description={description} location={location}/>

            <div
                css={`
                    display: flex;
                    flex-direction: column;
                    min-height: 100vh;
                    max-width: ${props => props.theme.dimensions.maxWidth};
                    margin-left: auto;
                    margin-right: auto;
                    background-color: ${props => props.theme.colors.bgCanvas};
                    overflow: hidden;            
                `}
            >
                <DebugWarning/>
                <Banner
                    css={`
                        flex-grow: 0;
                    `}
                />
                <Header
                    css={`
                        flex-grow: 0;
                    `}
                />

                <main
                    css={`
                        flex-grow: 1;
                    `}
                >

                    {/*
                        TODO: put h1 and breadcrumbs here

                    {h1 ? (
                        <h1>{h1}</h1>
                    ) : (
                        <h1
                            css={``}
                        >
                            &nbsp;
                        </h1>
                    )}

                    <div
                        css={`
                            font-size: small;
                            margin-top: -1.0em;
                        `}
                    >
                        <Link href={to}>
                            <FaBackward/>&nbsp;&nbsp;Back to {text}
                            </Link>
                    </div>

                    */}

                    <Container>
                        {children}
                    </Container>
                </main>

                <Footer
                    css={`
                        flex-grow: 0;
                        margin-top: 50px;                
                    `}
                />
            </div>

        </ThemeProvider>
    )
}

//==============================================================================
// end of file
