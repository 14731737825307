import { GenericPage } from "../../../../src/components/mdx/page.js";
import styled from "styled-components";
import { FaPlug } from "react-icons/fa";
import { ButtonPrimary, ButtonSecondary } from "../../../../src/components/widgets/button.js";
import { SignUpButton } from "../../../../src/components/widgets/sign-up-button";
import { HelpPopup } from "../../../../src/components/widgets/help-popup";
import { PricingTable } from "../../../../src/components/widgets/pricing-table";
import { Quote } from "../../../../src/components/widgets/quote";
import * as React from 'react';
export default {
  GenericPage,
  styled,
  FaPlug,
  ButtonPrimary,
  ButtonSecondary,
  SignUpButton,
  HelpPopup,
  PricingTable,
  Quote,
  React
};