//==============================================================================
// Project:     www.TuringTrader.com
// Name:        utils/format-helpers
// Description: Formatting helpers.
// History:     2022, May 25, FUB, created
//==============================================================================

export const cleanCurrency = (number) => {
    if (!number) return number

    return typeof number === 'number' ?
        number :
        parseFloat(number.replace("$", "").replace(",", ""))
}

export const formatCurrency = (number, digits = 2) => {
    if (!number) return "$$$"

    const clean = cleanCurrency(number)

    // label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);

    //return clean.toFixed(2).toLocaleString("en-US") // FIXME: why does this not work?
    return "$" + clean.toFixed(digits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatCagr = (number) => {
    if (!number) return number

    const plus = number > 0 ? "+" : ""
    return plus + number.toFixed(2)
}

export const formatDateTime = (timestamp) => {
    if (!timestamp) return timestamp

    const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
    }

    return new Date(timestamp).toLocaleDateString("en-US", options)
}

export const formatDateVerbose = (timestamp) => {
    if (!timestamp) return timestamp

    const options = {
        //weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        //hour: "numeric",
        //minute: "numeric",
        //timeZoneName: "short",
    }

    return new Date(timestamp).toLocaleDateString("en-US", options)
}

export const formatPercent = (value, digits = 2) => `${parseFloat(value).toFixed(digits)}%`

//==============================================================================
// end of file
