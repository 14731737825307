//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/button
// Description: Layout primitives.
// Created:     FUB, May 10, 2022
//==============================================================================

import React from "react"
import styled from "styled-components"

import { Link } from "./link"

//------------------------------------------------------------------------------
const ButtonStyle = styled.span`
    display: inline-block;
    color: ${props => props.theme.colors.text};
    font-size: ${props => props.small === true ? "0.70rem" : "smaller"};
    font-weight: 700;
    text-transform: uppercase; 
    letter-spacing: 0.05em;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    padding: ${props => props.small === true ? "0.0rem 0.2rem 0.0rem 0.2rem" : "0.25rem 0.5rem 0.25rem 0.5rem"};
    /*margin-left: 1rem;
    margin-right: 1rem;*/
    min-width: 3rem;
    text-align: center;
    border: 0.10rem solid;
    border-radius: 0.5rem;
    border-style: none;
    cursor: pointer;
`

export const Button = ({ text, to, onClick, className, small  }) => {
    // if we receive a function as 'to', we move it to 'onClick'
    const toIsFunction = to && typeof(to) !== 'string'
    const onClick2 = toIsFunction ? to : onClick

    return onClick2 ? (
        <ButtonStyle small={small} className={className} onClick={onClick2}>{text}</ButtonStyle>
        ) : (
        <Link href={to}>
            <ButtonStyle small={small} className={className}>{text}</ButtonStyle>
        </Link>
    )
}

export const ButtonPrimary = styled(Button)`
    color: white;
    background-color: ${props => props.theme.colors.cta};
    border-color: ${props => props.theme.colors.cta};
    border-style: solid;
    box-shadow: ${props => props.theme.shadows.box};
    text-shadow: 0px 0px 3px ${props => props.theme.colors.text};
`

export const ButtonSecondary = styled(Button)`
    color: ${props => props.theme.colors.active};
    background-color: ${props => props.theme.colors.bgCanvas};
    border-color: ${props => props.theme.colors.active};
    border-style: solid;
`

export const ButtonHelp = styled(Button)`
    color: ${props => props.theme.colors.textLight};
`

//==============================================================================
// end of file
