//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/top-portfolios
// Description: Widget to show three top portfolio picks.
// History:     2022 June 21, FUB, created
//==============================================================================

import React from "react"
import styled from "styled-components"

import { usePortfolioPicks } from "../../utils/portfolio-data"
import { Container } from "./container"
import { ButtonSecondary } from "./button"

//------------------------------------------------------------------------------
const Section1 = styled(Container)`
    grid-area: section1${props => props.id};
    border: 1px solid ${props => props.theme.colors.textLight};
    border-style: solid solid none solid;
    border-top-left-radius: ${props => props.theme.dimensions.cornerRadius};
    border-top-right-radius: ${props => props.theme.dimensions.cornerRadius};
    margin-top: 1em;
`

const Section2 = styled(Container)`
    grid-area: section2${props => props.id};
    border: 1px solid ${props => props.theme.colors.textLight};
    border-style: none solid solid solid;
    border-bottom-left-radius: ${props => props.theme.dimensions.cornerRadius};
    border-bottom-right-radius: ${props => props.theme.dimensions.cornerRadius};
    padding-top: 0;
`

//------------------------------------------------------------------------------
export const TopPortfolio = ({ info, id }) => {
    return (<>
        <Section1 id={id}>
            <div css={`
                background-color:${props => props.theme.colors.bgHighlight};
                margin-top: ${props => props.theme.spacings.negNormal};
                margin-left: ${props => props.theme.spacings.negNormal};
                margin-right: ${props => props.theme.spacings.negNormal};
                padding-top: 0;
                padding-left: ${props => props.theme.spacings.normal};
                padding-right: ${props => props.theme.spacings.normal};
                border-bottom: 1px solid ${props => props.theme.colors.decorative};
            `}>
                <div css={`text-align:right;font-size:smaller;color:${props => props.theme.colors.accent};`}><em>{info?.label}</em></div>
                <div css={`font-size:larger;font-weight:600;color:${props => props.theme.colors.accent};`}>{info?.title}</div>
            </div>
            <img css={`width:100%;margin-top:1em;`} src={info?.chart} alt={info?.title} />
            <p css={`font-size:smaller;`}>{info?.description}</p>
        </Section1>
        <Section2 id={id}>
            <p css={`font-size:smaller;`}>{info?.comparison}</p>
            <ul css={`font-size:smaller;`}>
                <li>{info?.compReturn}</li>
                <li>{info?.compRisk}</li>
            </ul>
            <p css={`text-align:center;`}>
                <br />
                <ButtonSecondary text="learn more" to={`/portfolios/${info?.slug}/`} />
            </p>
        </Section2>
    </>)
}

export const TopPortfolios = () => {
    const picks = usePortfolioPicks()

    return (<div
        css={`
            display: grid;
            grid-template-areas:
                "section1a"
                "section2a"
                "section1b"
                "section2b"
                "section1c"
                "section2c";
            @media ${props => props.theme.breakpoints.desktop} {
                grid-template-areas:
                    "section1a section1b section1c"
                    "section2a section2b section2c";
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: 1em;
            }
        `}
    >
        <TopPortfolio info={picks?.[0]} id="a" />
        <TopPortfolio info={picks?.[1]} id="b" />
        <TopPortfolio info={picks?.[2]} id="c" />
    </div>)
}

//==============================================================================
// end of file
