//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/portfolio/asset-allocation
// Description: Widgets for portfolio display
// Created:     FUB, May 05, 2022
//==============================================================================

import React from "react"

import { usePortfolioData } from "../../../utils/portfolio-data"
//import { Box } from "../container"
import { TableSimple } from "../table"
import { Link, AssetLink } from "../link"
import { Border } from "../container"
import { SignUpButton } from "../sign-up-button"

//------------------------------------------------------------------------------
export const AssetAllocationDummy = ({ access }) => {
    const space = <span>&nbsp;</span>
    const ellipsis = <span>&hellip;</span>
    /*const box = <em><center>
        [This table intentionally left blank]
    </center></em>*/
    let box = null
    if (access?.needsBasic) box = <Border><center>
        <p>
            You can see the asset allocation for this portfolio with
            our <strong>free Basic membership</strong>.
        </p>
        <SignUpButton free={true} />
    </center></Border>

    if (access?.needsTrial) box = <Border><center>
        <p>
            You can see the asset allocation for this portfolio with
            our <strong>14-day free trial</strong>.
        </p>
        <SignUpButton />
    </center></Border>

    if (access?.needsPremium) box = <Border><center>
        <p>
            You can see the asset allocation for this portfolio with
            our <strong>Premium membership</strong>.
        </p>
        <SignUpButton />
    </center></Border>

    const data = {
        columns: ["Symbol", "Name", "Allocation"],
        "Symbol": [ellipsis, ellipsis, ellipsis],
        "Name": [space, box, space],
        "Allocation": [ellipsis, ellipsis, ellipsis],
    }

    return (<>
        <TableSimple data={data} />
    </>)
}

export const AssetAllocation = ({ portfolio, access }) => {
    const data = usePortfolioData(portfolio)
    const table = data?.allocationTable

    const table2 = table && {
        ...table,
        columns: ["Symbol", "Name", "Allocation"],
        "Symbol": table["Symbol"].map(item => (<AssetLink ticker={item} />)),
    }

    return (<>
        <TableSimple data={table2} />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <small>
            <Link href={`/portfolios/history/?pf=${portfolio}`}>
                &nbsp;&gt;&gt; Historical Asset Allocations<br /><br />
            </Link>
        </small>
    </>)
}

//==============================================================================
// end of file
