//==============================================================================
// Project:     www.TuringTrader.com
// Name:        utils/portfolio-list.js
// Description: Provide portfolio list.
// Created:     FUB, May 15, 2022
//==============================================================================

import {useState, useEffect} from "react"
import {useStaticQuery, graphql} from "gatsby"

//------------------------------------------------------------------------------

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`PF-LIST: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`PF-LIST: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
/**
 * React hook to get list of all known portfolios.
 * 
 * This hook returns a list of all known portfolios. If multiple versions of a
 * portfolio exist, there will be only one entry pointing to the latest version.
 * The returned list has the following format:
 * 
 *     [
 *         {
 *             title: "Mean Kitty",
 *             author: "TuringTrader's",
 *             minCapital: 5000,
 *             slug: "tt-mean-kitty",
 *             slug2: "tt-mean-kitty-v2",
 *             tags: ["premium", "etf", mean-reversion"],
 *         },
 *         {
 *             ...
 *         }
 *     ]
 * 
 * @returns array w/ general portfolio information
 */
export const usePortfolioList = () => {
    const query = useStaticQuery(graphql`
        query MyQuery {
            allMdx(filter: {slug: {regex: "/^portfolios..+/"}}) {
                edges {
                    node {
                        id
                        slug
                        frontmatter {
                            title
                            author
                            version
                            tags
                            minCapital
                            shortTermTaxation
                        }
                    }
                }
            }
        }
    `)

    /*
        {
            "data": {
                "allMdx": {
                    "edges": [
                        {
                            "node": {
                                "id": "2e0704f7-9685-559c-8149-204836556078",
                                "slug": "portfolios/antonacci-dual-momentum/",
                                "frontmatter": {
                                    "title": "Dual Momentum"
                                }
                            }
                        },
                        {
                            ...
                        },
                    ]
                }
            },
            "extensions": {}
        }
    */

    const [pfList, setPfList] = useState()
    useEffect(() => {
        if (pfList) return
        
        const edges = query.allMdx.edges;

        edges.forEach((item) => {
            DEBUG_MSG(`pf=${JSON.stringify(item)}`)
        })

        const tmp = edges.map(e => ({
            title: e.node.frontmatter.title,
            author: e.node.frontmatter.author,
            minCapital: e.node.frontmatter.minCapital,
            shortTermTaxation: +(e.node.frontmatter?.shortTermTaxation?.replace("%", "") ?? 90),
            slug: e.node.slug.replace("portfolios/", "").replace("/", ""),
            slug2: e.node.slug.replace("portfolios/", "").replace("/", "") 
                + `-v${e.node.frontmatter.version}`.replace("-v1", ""),
            tags: e.node.frontmatter.tags,
        }))

        setPfList(tmp)
    
    }, [query, pfList])

    return pfList
}

/**
 * Clean portfolio slug from version appendix.
 * 
 * This function returns the portfolio base name, removing any version
 * appendix or slashes from the name.
 * 
 * @param {*} portfolio portfolio slug, possibly with version appendix
 * @returns portfolio slug, without version appendix
 */
export const getPortfolioBase = (portfolio) => portfolio.replace(/-v[0-9]+$/, "")

/**
 * React hook to find the latest version of a portfolio.
 * 
 * This hook returns the latest version of a portfolio. If only one
 * version exists, this will be the base slug, without a -v1 appendix.
 * If more versions exist, it will be the portfolio slug including the
 * appenix for the version, not including slashes.
 * 
 * @param {*} portfolio portfolio slug, with or without version,
 * not including any slashes
 * @returns latest version portfolio slug
 */
export const usePortfolioLatest = (portfolio) => {
    const list = usePortfolioList()

    if (!list) return list
    
    const portfolioBase = getPortfolioBase(portfolio)
    return list.filter(p => p.slug === portfolioBase)?.[0].slug2
}

//==============================================================================
// end of file
