//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/mdx/page.js
// Description: MDX page layout for generic page.
// Created:     FUB, Apr 16, 2022
//==============================================================================

import React from "react"
import { MDXProvider } from "@mdx-js/react"

import { Page } from "../layout/page"
import { components } from "./component-mapping"

export const GenericPage = ({ children, location, pageContext }) => {
    const { frontmatter } = pageContext

    return (
        <Page title={frontmatter.title} description={frontmatter.description} location={location}>
            <MDXProvider components={components}>
                {children}
            </MDXProvider>
        </Page>
    )
}

export default GenericPage

//==============================================================================
// end of file
