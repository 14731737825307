//==============================================================================
// Project:     www.TuringTrader.com
// Name:        utils/root-wrapper.js
// Description: Wrapper around root element.
// Created:     FUB, Apr 23, 2022
//==============================================================================

import React from "react"

import "./firebase"
//import { PortfolioDataProvider } from "./portfolio-data"
import { MemberDataProvider } from "./member-data"
import { AccountDataProvider } from "./account-data"

//------------------------------------------------------------------------------
export const RootWrapper = ({ children }) => (<>
    <MemberDataProvider>
        <AccountDataProvider>

            {children}

        </AccountDataProvider>
    </MemberDataProvider>
</>)

//==============================================================================
// end of file
