//==============================================================================
// Project:     www.TuringTrader.com
// Name:        utils/outseta
// Description: Outseta implementation.
// History:     2023viiii05, FUB, created
//==============================================================================

import { useEffect, useState, useRef } from "react"
import { useSearchParams2 } from "./search-params";
import { cleanMeta, diffMeta } from "./member-data"

//==============================================================================
// debug stuff

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`OUTSETA: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`OUTSETA: ${msg}`) // eslint-disable-line no-unused-vars

//==============================================================================
// Outseta configuration

const SITE = "TT.com"
//const SITE = "test"
//const SITE = "local"

const OUTSETA_TRANSITION = undefined
//const OUTSETA_TRANSITION = "/outseta/"

const OUTSETA_OPTIONS = {
    id: "outseta",
    domain: "turingtrader.outseta.com",
    monitorDom: true, // vital setting for a single page application  
    load: "auth,profile,leadCapture,support", // do not load the nocode module
    tokenStorage: "local", // store authentication token in localStorage, recognize users across tabs and visits
    auth: {
        authenticationCallbackUrl: {
            "TT.com": undefined,
            "test": "https://test.turingtrader.com/dashboard/",
            "local": "http://localhost:8000/dashboard/",
        }[SITE],
    },
    support: {
        mode: 'embed',
        selector: '#support-form'
    },
};

const PLANS = {
    "basic": {
        // basic access
        name: "Basic",
        id: "L9nO0VWZ",
        signup: OUTSETA_TRANSITION ?? "https://turingtrader.outseta.com/auth?widgetMode=register&planUid=L9nO0VWZ&planPaymentTerm=month&skipPlanOptions=true#o-anonymous",
        change: OUTSETA_TRANSITION ?? "https://turingtrader.outseta.com/profile?tab=planChange&stateProps=%7B%22planUid%22%3A%22L9nO0VWZ%22%2C%22planPaymentTerm%22%3A%22monthly%22%7D#o-authenticated",
        paid: 1,
        access: 1,
    },
    //---------- temporary plans (used before payment cleared)
    "premium-monthly-": {
        name: "Premium Monthly",
		id: 'A93Vk5Q0',
		// signup => Basic
		//signup: 'https://turingtrader.outseta.com/auth?widgetMode=register&planUid=L9nO0VWZ&planPaymentTerm=month&skipPlanOptions=true#o-anonymous',
        //change: "/sign-up/checkout/?plan=premium",
		level: 2,
        access: 2,
    },
    "premium-yearly-": {
        name: "Premium Yearly",
		id: 'A93Vk5Q0',
		// signup => Basic
		//signup: 'https://turingtrader.outseta.com/auth?widgetMode=register&planUid=L9nO0VWZ&planPaymentTerm=month&skipPlanOptions=true#o-anonymous',
        //change: "/sign-up/checkout/?plan=premium",
		level: 2,
        access: 2,
    },
    "infinite-monthly-": {
        name: "Infinite Monthly",
		id: 'nmD6Y29y',
		// signup => Basic
		//signup: 'https://turingtrader.outseta.com/auth?widgetMode=register&planUid=L9nO0VWZ&planPaymentTerm=month&skipPlanOptions=true#o-anonymous',
        //change: "/sign-up/checkout/?plan=infinite",
		level: 3,
        access: 3,
    },
    "infinite-yearly-": {
        //name: "Infinite Yearly",
		//id: 'nmD6Y29y',
		// signup => Basic
		signup: 'https://turingtrader.outseta.com/auth?widgetMode=register&planUid=L9nO0VWZ&planPaymentTerm=month&skipPlanOptions=true#o-anonymous',
        change: "/sign-up/checkout/?plan=infinite",
		level: 3,
        access: 3,
    },
    //---------- pricing table placeholders - no actual plans
    "premium-monthly": {
        name: "Premium Monthly",
        signup: OUTSETA_TRANSITION ?? "https://turingtrader.outseta.com/auth?widgetMode=register&planUid=L9nO0VWZ&planPaymentTerm=month&skipPlanOptions=true#o-anonymous",
        change: "/no-signup/",
    },
    "premium-yearly": {
        name: "Premium Yearly",
        signup: OUTSETA_TRANSITION ?? "https://turingtrader.outseta.com/auth?widgetMode=register&planUid=L9nO0VWZ&planPaymentTerm=month&skipPlanOptions=true#o-anonymous",
        change: "/no-signup/",
    },
    "infinite-monthly": {
        name: "Infinite Monthly",
        signup: OUTSETA_TRANSITION ?? "https://turingtrader.outseta.com/auth?widgetMode=register&planUid=L9nO0VWZ&planPaymentTerm=month&skipPlanOptions=true#o-anonymous",
        change: "/no-signup/",
    },
    "infinite-yearly": {
        name: "Infinite Yearly",
        signup: OUTSETA_TRANSITION ?? "https://turingtrader.outseta.com/auth?widgetMode=register&planUid=L9nO0VWZ&planPaymentTerm=month&skipPlanOptions=true#o-anonymous",
        change: "/no-signup/",
    },
    //---------- paid plans, disabled on 2024iii28
    "premium-monthly-2022": {
        // $49.95 per month. Introduced on 2022vii09
        name: "Premium Monthly",
        id: "pWrP0gQn",
        signup: OUTSETA_TRANSITION ?? "https://turingtrader.outseta.com/auth?widgetMode=register&planUid=pWrP0gQn&planPaymentTerm=month&skipPlanOptions=true#o-anonymous",
        change: OUTSETA_TRANSITION ?? "https://turingtrader.outseta.com/profile?tab=planChange&stateProps=%7B%22planUid%22%3A%22pWrP0gQn%22%2C%22planPaymentTerm%22%3A%22monthly%22%7D#o-authenticated",
        paid: 2,
        access: 2,
    },
    "premium-yearly-2022": {
        // $499 per year. Introduced on 2022vii09
        name: "Premium Yearly",
        id: "pWrP0gQn",
        signup: OUTSETA_TRANSITION ?? "https://turingtrader.outseta.com/auth?widgetMode=register&planUid=pWrP0gQn&planPaymentTerm=annual&skipPlanOptions=true#o-anonymous",
        change: OUTSETA_TRANSITION ?? "https://turingtrader.outseta.com/profile?tab=planChange&stateProps=%7B%22planUid%22%3A%22pWrP0gQn%22%2C%22planPaymentTerm%22%3A%22annual%22%7D#o-authenticated",
        paid: 2,
        access: 2,
    },
    "infinite-monthly-2022": {
        // $79.95 per month. Introduced on 2021vii07
        name: "Infinite Monthly",
        id: "E9L7qEQw",
        signup: OUTSETA_TRANSITION ?? "https://turingtrader.outseta.com/auth?widgetMode=register&planUid=E9L7qEQw&planPaymentTerm=month&skipPlanOptions=true#o-anonymous",
        change: OUTSETA_TRANSITION ?? "https://turingtrader.outseta.com/profile?tab=planChange&stateProps=%7B%22planUid%22%3A%22E9L7qEQw%22%2C%22planPaymentTerm%22%3A%22monthly%22%7D#o-authenticated",
        paid: 3,
        access: 3,
    },
    "infinite-yearly-2022": {
        // $799 per year. Introduced on 2021vii07
        name: "Infinite Yearly",
        id: "E9L7qEQw",
        signup: OUTSETA_TRANSITION ?? "https://turingtrader.outseta.com/auth?widgetMode=register&planUid=E9L7qEQw&planPaymentTerm=annual&skipPlanOptions=true#o-anonymous",
        change: OUTSETA_TRANSITION ?? "https://turingtrader.outseta.com/profile?tab=planChange&stateProps=%7B%22planUid%22%3A%22E9L7qEQw%22%2C%22planPaymentTerm%22%3A%22annual%22%7D#o-authenticated",
        paid: 3,
        access: 3,
    },
    //---------- retired plans
    "premium-2021": {
        // $49.95 per month; $499 per year. Introduced on 2021vii07, retired on 2022vii09
        name: "Premium v2021",
        id: "7maDEKWE",
        paid: 2,
        access: 2,
        year: 2021,
    },
    "premium-2020": {
        // $49.95 per month; $499 per year. Retired on 2021vii07
        name: "Premium v2020",
        id: "496yV7QX",
        paid: 3,
        access: 3,
        year: 2020,
    },
    //---------- private plans
    "besol": {
        // plan for Bertram Solutions clients
        name: "Bertram.Solutions",
        id: "ZmN8E792",
        paid: 3,
        access: 3,
    },
    "free": {
        // freebie for family & friends
        name: "Family & Friends",
        id: "L9P8Ej9J",
        paid: 2,
        access: 2,
    },
}

const PLAN_ACTIVE = [
    // see https://go.outseta.com/support/kb/articles/Kj9boWnd/how-billing-stages-are-defined
    2, // Trialing - A period in which the base subscription is free. If a user is currently on a free trial or subscribed to a free plan, their billing stage will be "Trialing." 
    3, // Subscribing - The user is currently on a paid subscription and is contributing to your monthly recurring revenue (MRR).
    4, // Canceling - The customer has indicated they want to cancel their base subscription.
    //5, // Expired - The base subscription has ended after cancelation.
    //6, // Trial Expired - When a user's free trial period has ended and the account has never paid.
    7, // Past due - The base subscription is current, but payment has failed. The user likely needs to update the credit card information that they have on file.
    8, // Cancelling Trial - When a user requests to cancel during a free trial period. The user will continue to have access for the duration of the trial period, but will not be subscribed at the end of the trial period.
]

//------------------------------------------------------------------------------
// override for testing
const _outsetaOverride = { // eslint-disable-line no-unused-vars
    Email: "felix@turingtrader.com",
    FirstName: "Felix",
    LastName: "Bertram",
    Created: "2023-11-08T18:13:24",
    Account: {
        AccountStage: 2,
        CurrentSubscription: {
            Plan: { Name: "Basic", Uid: "L9nO0VWZ" },
            //Plan: { Name: "Premium", Uid: "pWrP0gQn" },
            //Plan: { Name: "Infinite", Uid: "E9L7qEQw" },
            SubscriptionAddOns: [],
        },
    }
}
const outsetaOverride = undefined // normal functionality, no override
//const outsetaOverride = null // logged out
//const outsetaOverride = _outsetaOverride // logged in, settings from above

//==============================================================================
// install Outseta header script
// see https://go.outseta.com/support/kb/articles/A93nZlQ0/how-to-integrate-outseta-with-react
// and https://codesandbox.io/s/inject-script-react-outseta-demo-kw7f4

//------------------------------------------------------------------------------
// we don't need onRenderBody, we load Outseta script dynamically
export const Outseta_onRenderBody = (
    { setHeadComponents, setPostBodyComponents },
    pluginOptions
) => {
}

//------------------------------------------------------------------------------
// load Outseta script
// this code is taken verbatim from Outseta's demo

let outsetaPromise = null

const createScript = () => {
    const optionsKey = `${OUTSETA_OPTIONS.id}Options`

    // Set the options on window
    window[optionsKey] = OUTSETA_OPTIONS

    // Create external script element
    const script = document.createElement("script")
    script.src = "https://cdn.outseta.com/outseta.min.js"
    // Set name of options key on window
    script.dataset.options = optionsKey

    return script
}

const loadOutseta = async () => {
    // In case loadOutseta is called several times,
    // lets make sure we only make one promise.
    if (outsetaPromise) return outsetaPromise

    outsetaPromise = new Promise((resolve, reject) => {
        if (window[OUTSETA_OPTIONS.id]) {
            // If Outseta is initialized
            // lets resolve right away
            resolve(window[OUTSETA_OPTIONS.id])
        } else {
            // FIXME: delay loading a bit, so that the 
            //        site has time to render first?
            //setTimeout(() => {
            const script = createScript()

            script.onload = () => {
                if (window[OUTSETA_OPTIONS.id]) {
                    resolve(window[OUTSETA_OPTIONS.id])
                } else {
                    reject(new Error("Outseta.js not available"))
                }
            }

            script.onerror = () => {
                reject(new Error("Failed to load Outseta.js"))
            }

            document.head.appendChild(script)

            //}, 1 * 1000)
        }
    })

    return outsetaPromise
}

//------------------------------------------------------------------------------
// retrieve member information from Outseta
// this function is taken from Outseta's demo code 
// but slightly adapted to work as a hook

const useOutsetaInfoRaw = () => {
    const [searchParams, setSearchParams] = useSearchParams2(); // from TuringTrader's search-params
    const [status, setStatus] = useState("init")
    const [user, setUser] = useState()
    const outsetaRef = useRef()

    //--- initialize Outseta and update user
    useEffect(() => {
        const init = async () => {
            // Await injection of the script
            outsetaRef.current = await loadOutseta()

            // Set up handling of user related events
            handleOutsetaUserEvents(updateUser)

            // Get the access token from the callback url
            // FIXME: searchParams can be null
            const accessToken = searchParams?.get("access_token")

            if (accessToken) {
                // If there is an acccess token present
                // pass it along to Outseta
                outsetaRef.current.setAccessToken(accessToken)

                // Removing the access_token from the url is considered best practice
                // so that its not accidentally copied when sharing content.
                setSearchParams({})
            }

            if (outsetaRef.current.getAccessToken()) {
                // Outseta initalized with an authenticated user
                updateUser();
            } else {
                // Outseta initalized without authenticated user
                setStatus("ready");
            }
        };

        const updateUser = async () => {
            // Fetch the current user data from outseta
            const outsetaUser = await outsetaRef.current.getUser()
            // Update user state
            setUser(outsetaUser)
            // Make sure status = ready
            setStatus("ready")
        };

        const handleOutsetaUserEvents = (onEvent) => {
            // Subscribe to user related events
            // with onEvent function
            const outseta = outsetaRef.current;
            // FIXME: outseta can be null
            outseta?.on("subscription.update", onEvent)
            outseta?.on("profile.update", onEvent)
            outseta?.on("account.update", onEvent)
        };

        init();

        return () => {
            // Clean up user related event subscriptions
            handleOutsetaUserEvents(() => { });
        };
    }, [searchParams, setSearchParams]);

    const [outsetaInfo, setOutsetaInfo] = useState()

    //--- maintain basic Outseta info object
    useEffect(() => {
        const openLogin = async (options) => {
            outsetaRef.current.auth.open({
                widgetMode: "login|register",
                authenticationCallbackUrl: window.location.href,
                ...options
            })
        }

        const openSignup = async (options) => {
            outsetaRef.current.auth.open({
                widgetMode: "register",
                authenticationCallbackUrl: window.location.href,
                ...options
            })
        }

        const logout = () => {
            // Unset access token
            outsetaRef.current.setAccessToken("");
            // and remove user state
            setUser(null)
        }

        const openProfile = async (options) => {
            outsetaRef.current.profile.open({ tab: "profile", ...options });
        }

        const info = {
            user: typeof outsetaOverride !== "undefined" ?
                outsetaOverride :
                user,
            isLoading: status !== "ready",
            auth: outsetaRef.current?.getAccessToken(),
            openLogin,
            openSignup: OUTSETA_TRANSITION ?? openSignup,
            openProfile: OUTSETA_TRANSITION ?? openProfile,
            logout,
        }
        setOutsetaInfo(info)
        DEBUG_MSG(`${user ? user.Email + " logged in" : "logged out"}`)
    }, [user, status])

    return outsetaInfo
}

/*const sampleOutsetaInfo = {
    "user": {
        "Email": "felix@turingtrader.com",
        "FirstName": "",
        "LastName": "",
        "MailingAddress": null,
        "PasswordLastUpdated": "2023-09-06T16:54:51",
        "PasswordMustChange": false,
        "PhoneMobile": "",
        "PhoneWork": "",
        "ProfileImageS3Url": null,
        "Title": null,
        "Timezone": "America/Los_Angeles",
        "Language": "en-US, en; q=0.9, de; q=0.8",
        "IPAddress": "71.231.12.118",
        "Referer": "http://localhost:8000/",
        "UserAgent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36 Edg/116.0.1938.69",
        "LastLoginDateTime": "2023-09-06T17:34:00",
        "OAuthGoogleProfileId": null,
        "PersonAccount": null,
        "DealPeople": null,
        "LeadFormSubmissions": null,
        "EmailListPerson": null,
        "Account": {
            "Name": "felix@turingtrader.com",
            "ClientIdentifier": null,
            "InvoiceNotes": null,
            "IsDemo": false,
            "BillingAddress": null,
            "MailingAddress": null,
            "AccountStage": 2,
            "PaymentInformation": null,
            "PersonAccount": null,
            "Subscriptions": null,
            "Deals": null,
            "LastLoginDateTime": "2023-09-06T17:34:00",
            "AccountSpecificPageUrl1": null,
            "AccountSpecificPageUrl2": null,
            "AccountSpecificPageUrl3": null,
            "AccountSpecificPageUrl4": null,
            "AccountSpecificPageUrl5": null,
            "AccountSpecificPageUrl6": null,
            "AccountSpecificPageUrl7": null,
            "AccountSpecificPageUrl8": null,
            "AccountSpecificPageUrl9": null,
            "AccountSpecificPageUrl10": null,
            "RewardFulReferralId": null,
            "TaxIds": null,
            "TaxStatus": "none",
            "AccountStageLabel": "Trialing",
            "CurrentSubscription": {
                "BillingRenewalTerm": 1,
                "Account": null,
                "Plan": {
                    "Name": "Basic",
                    "Uid": "L9nO0VWZ"
                },
                "Quantity": null,
                "StartDate": "2023-09-06T16:24:59",
                "EndDate": null,
                "ExpirationDate": null,
                "RenewalDate": "2023-10-06T16:24:59",
                "NewRequiredQuantity": null,
                "IsPlanUpgradeRequired": false,
                "PlanUpgradeRequiredMessage": null,
                "SubscriptionAddOns": [{
                    "ActivityEventData": null,
                    "AddOn": {
                        "Name": "Infinite for Free",
                        "Uid": "49E56z97",
                    },
                    "BillingRenewalTerm": 4,
                    "Created": "2023-09-07T18:13:24",
                    "EndDate": "2099-12-31T00:00:00",
                    "NewRequiredQuantity": null,
                    "Quantity": null,
                    "RenewalDate": null,
                    "StartDate": "2023-09-07T18:13:24",
                    "Subscription": null,
                    "Uid": "Dmwk34m4",
                    "Updated": "2023-09-07T18:13:24"
                }, {
                    "ActivityEventData": null,
                    "AddOn": {
                        "Name": "Premium for Free",
                        "Uid": "Rm8rAy94",
                    },
                    "BillingRenewalTerm": 4,
                    "Created": "2023-09-07T18:13:24",
                    "EndDate": "2099-12-31T00:00:00",
                    "NewRequiredQuantity": null,
                    "Quantity": null,
                    "RenewalDate": null,
                    "StartDate": "2023-09-07T18:13:24",
                    "Subscription": null,
                    "Uid": "yW1rZDmB",
                    "Updated": "2023-09-07T18:13:24"
                }],
                "DiscountCouponSubscriptions": [],
                "DiscountCode": null,
                "LatestInvoice": null,
                "Rate": 0,
                "ActivityEventData": null,
                "Uid": "dQG7YEqQ",
                "Created": "2023-09-06T16:24:59",
                "Updated": "2023-09-06T16:24:59"
            },
            "DomainName": null,
            "HasLoggedIn": true,
            "LatestSubscription": null,
            "LifetimeRevenue": 0,
            "PrimaryContact": {
                "Email": "felix@turingtrader.com",
                "FirstName": "",
                "LastName": "",
                "MailingAddress": null,
                "PasswordLastUpdated": null,
                "PasswordMustChange": false,
                "PhoneMobile": "",
                "PhoneWork": "",
                "ProfileImageS3Url": null,
                "Title": null,
                "Timezone": null,
                "Language": null,
                "IPAddress": null,
                "Referer": null,
                "UserAgent": null,
                "LastLoginDateTime": null,
                "OAuthGoogleProfileId": null,
                "PersonAccount": null,
                "DealPeople": null,
                "LeadFormSubmissions": null,
                "EmailListPerson": null,
                "Account": null,
                "AccountUids": null,
                "FullName": "felix@turingtrader.com",
                "HasLoggedIn": false,
                "OAuthIntegrationStatus": 0,
                "UserAgentPlatformBrowser": "",
                "HasUnsubscribed": false,
                "DiscordUser": null,
                "IsConnectedToDiscord": false,
                "ActivityEventData": null,
                "Uid": "xmekPx59",
                "Created": "0001-01-01T00:00:00",
                "Updated": "0001-01-01T00:00:00"
            },
            "PrimarySubscription": null,
            "RecaptchaToken": null,
            "TaxIdType": null,
            "TaxId": null,
            "WebflowSlug": null,
            "ActivityEventData": null,
            "Uid": "vW5kv06Q",
            "Created": "2023-09-06T16:24:59",
            "Updated": "2023-09-06T17:34:00"
        },
        "AccountUids": null,
        "FullName": "felix@turingtrader.com",
        "HasLoggedIn": true,
        "OAuthIntegrationStatus": 0,
        "UserAgentPlatformBrowser": "WinNT (Chrome)",
        "HasUnsubscribed": false,
        "DiscordUser": null,
        "IsConnectedToDiscord": false,
        "ActivityEventData": null,
        "Uid": "xmekPx59",
        "Created": "2023-09-06T16:24:59",
        "Updated": "2023-09-06T17:34:00"
    },
    "isLoading": true
}*/

//==============================================================================
// custom hook providing abstracted membership information
export const useOutsetaInfo = () => {
    const outsetaInfo = useOutsetaInfoRaw()
    const [membership, setMembership] = useState(undefined)

    useEffect(() => {
        var paidLevel = 0
        var accessLevel = 0
        var planYear = 9999
        var beta = false
        var subscriptions = []
        var firstJoin = new Date()

        if (outsetaInfo?.user) {
            // we are logged in

            // make sure users have Basic access after
            // their paid subscription expired and
            // before they joined Basic again
            if (true) {
                paidLevel = 1
                accessLevel = 1
                beta = false
            }

            // trial period is 14 days after account creation
            firstJoin = new Date(outsetaInfo.user.Created)

            if (PLAN_ACTIVE.includes(outsetaInfo.user.Account.AccountStage)) {
                // upate access levels according to current membership and options
                const activeMemberships = [
                    outsetaInfo.user.Account.CurrentSubscription.Plan,
                    ...outsetaInfo.user.Account.CurrentSubscription.SubscriptionAddOns.map(a => a.AddOn),
                ]

                subscriptions = activeMemberships.map(p => p.Name)

                activeMemberships.forEach((myMembership) => {
                    const planTemplateName = Object.keys(PLANS)
                        .filter(name => PLANS[name].id === myMembership.Uid)?.[0]

                    const planTemplate = PLANS[planTemplateName]

                    if (planTemplate?.paid) paidLevel = Math.max(paidLevel, planTemplate.paid)
                    if (planTemplate?.access) accessLevel = Math.max(accessLevel, planTemplate.access)
                    if (planTemplate?.year) planYear = Math.min(planYear, planTemplate.year)
                    if (planTemplate?.beta) beta = beta || planTemplate.beta
                })
            }

            // TODO: this second pass is a hack for now
            //activeMemberships.forEach((myMembership) => {
            //    if (myMembership.name === "Infinite Upgrade" && accessLevel >= 2) {
            //        paidLevel = 3;
            //        accessLevel = 3;
            //    }
            //})
        }

        const m = {
            user: {
                firstName: outsetaInfo?.user?.FirstName?.length > 0
                    ? outsetaInfo?.user?.FirstName
                    : outsetaInfo?.user?.FullName,
                lastName: outsetaInfo?.user?.LastName,
                id: outsetaInfo?.user?.Uid,
                email: outsetaInfo?.user?.Email,
                plans: subscriptions,
                firstJoin,
                auth: outsetaInfo?.auth,
            },
            level: {
                // membership level: 0=free, 1=basic, 2=premium, 3=infinite
                paid: paidLevel,
                access: accessLevel,
                year: planYear,
                beta: beta,
                debug: SITE !== "TT.com",
            },
            links: {
                //login: "https://turingtrader.outseta.com/auth?widgetMode=login#o-anonymous",
                //account: "https://turingtrader.outseta.com/profile?#o-authenticated",
                login: outsetaInfo?.openLogin,     // this is a function!
                account: outsetaInfo?.openProfile, // this is a function!
                logout: outsetaInfo?.logout,       // this is a function!
                //---
                signupBasic: accessLevel === 0 ? PLANS?.["basic"]?.signup : PLANS?.["basic"]?.change,
                signupTrial: accessLevel === 0 ? PLANS?.["basic"]?.signup : PLANS?.["basic"]?.change,
                signupPremiumMonthly: accessLevel === 0 ? PLANS?.["premium-monthly"]?.signup : PLANS["premium-monthly"]?.change,
                signupPremiumAnnual: accessLevel === 0 ? PLANS?.["premium-yearly"]?.signup : PLANS["premium-yearly"]?.change,
                signupInfiniteMonthly: accessLevel === 0 ? PLANS?.["infinite-monthly"]?.signup : PLANS["infinite-monthly"].change,
                signupInfiniteAnnual: accessLevel === 0 ? PLANS?.["infinite-yearly"]?.signup : PLANS["infinite-yearly"].change,
            },
        }

        setMembership(m)
    }, [outsetaInfo, setMembership])

    return membership
}

//------------------------------------------------------------------------------
// custom hook providing access to Outseta meta-information
// see https://documenter.getpostman.com/view/3613332/outseta-rest-api-v1/7TNfr6k#d306291e-aaa5-3a6f-1c2d-6d9c423a1ba1
// use Outseta user's auth token

/*const sampleOutsetaProfile = {
    "Email": "felix@turingtrader.com",
    "FirstName": "Felix",
    "LastName": "Bertram",
    "MailingAddress": {
        "AddressLine1": null,
        "AddressLine2": null,
        "AddressLine3": null,
        "City": "",
        "State": "",
        "PostalCode": "98033",
        "Country": null,
        "GeoLocation": null,
        "ActivityEventData": null,
        "Uid": "pWrwDX0m",
        "Created": "2023-09-08T20:21:11",
        "Updated": "2023-09-08T20:54:13"
    },
    "PasswordLastUpdated": "2023-09-06T16:54:51",
    "PasswordMustChange": false,
    "PhoneMobile": "",
    "PhoneWork": "",
    "ProfileImageS3Url": null,
    "Title": null,
    "Timezone": "America/Los_Angeles",
    "Language": "en-US, en; q=0.9, de; q=0.8",
    "IPAddress": "71.231.12.118",
    "Referer": "http://localhost:8000/",
    "UserAgent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36 Edg/116.0.1938.69",
    "LastLoginDateTime": "2023-09-08T16:14:38",
    "OAuthGoogleProfileId": null,
    "PersonAccount": [{
\        "Person": null,
        "Account": null,
        "IsPrimary": true,
        "ReceiveInvoices": false,
        "ActivityEventData": null,
        "Uid": "E9L1bnPW",
        "Created": "2023-09-06T16:24:59",
        "Updated": "2023-09-06T16:24:59"
    }],
    "DealPeople": [],
    "LeadFormSubmissions": null,
    "EmailListPerson": null,
    "Account": null,
    "AccountUids": null,
    "FullName": "Felix Bertram",
    "HasLoggedIn": true,
    "OAuthIntegrationStatus": 0,
    "UserAgentPlatformBrowser": "WinNT (Chrome)",
    "HasUnsubscribed": false,
    "DiscordUser": null,
    "IsConnectedToDiscord": false,
    "ActivityEventData": null,
    "Uid": "xmekPx59",
    "Created": "2023-09-06T16:24:59",
    "Updated": "2023-09-08T20:56:16"
}*/

const getProfile = (authentication, options) => {
    // Outseta REST API v1: Get Profile
    //DEBUG_MSG(`Get Profile`)

    var reqHeaders = new Headers();
    reqHeaders.append("Authorization", authentication)
    reqHeaders.append("Content-Type", "application/json")

    var reqOptions = {
        method: 'GET',
        headers: reqHeaders,
        redirect: 'follow'
    }

    return new Promise((resolve, reject) => {
        const reqUrl = `https://${OUTSETA_OPTIONS.domain}/api/v1/profile?${options}`
        fetch(reqUrl, reqOptions)
            .then(response => response.status >= 200 && response.status < 300 ?
                Promise.resolve(response) :
                Promise.reject(new Error(response.statusText)))
            .then(response => response.json())
            .then(result => {
                //DEBUG_MSG(JSON.stringify(result))
                resolve(result)
            })
            .catch(error => reject(new Error(error)))
    })
}

const updateProfile = (profile, authentication, options) => {
    // Outseta REST API v1 - Update Profile
    //DEBUG_MSG(`Update Profile`)

    var reqHeaders = new Headers();
    reqHeaders.append("Authorization", authentication)
    reqHeaders.append("Content-Type", "application/json")

    const reqBody = JSON.stringify(profile)
    //DEBUG_MSG(`profile=${reqBody}`)

    const reqOptions = {
        method: 'PUT',
        headers: reqHeaders,
        body: reqBody,
        redirect: 'follow'
    }

    return new Promise((resolve, reject) => {
        const reqUrl = `https://${OUTSETA_OPTIONS.domain}/api/v1/profile?${options}`
        resolve(true)
        fetch(reqUrl, reqOptions)
            .then(response => response.status >= 200 && response.status < 300 ?
                Promise.resolve(response) :
                Promise.reject(new Error(response.statusText)))
            .then(response => response.text())
            .then(result => {
                //DEBUG_MSG(result)
                resolve(true)
            })
            .catch(error => reject(new Error(error)));
    })
}

export const useOutsetaMeta = (membership) => {
    //----- retrieve metadata
    const [metaValue, setMetaValue] = useState(undefined)

    useEffect(() => {
        let timer = null
        let isActive = true

        // keep polling for changes
        const getMetaTimer = async () => {
            // NOTE: we must pass in fields=*, so that we actually
            //       see our custom properties
            const profile = await getProfile(`bearer ${membership.user.auth}`, "fields=*")

            // we add the various fields one after another to make sure
            // we handle parsing errors and undefined values gracefully
            let meta = {}

            try {
                const accountsField = JSON.parse(profile?.TuringTrader_Accounts)
                if (accountsField) meta.accounts = accountsField
            } catch (error) { }

            // NOTE: this value is read only; we never write it back to Outseta
            try {
                const adminField = JSON.parse(profile?.TuringTrader_Admin)
                if (adminField) meta.admin = adminField
            } catch (error) { }

            try {
                const createdField = JSON.parse(profile?.TuringTrader_Created)
                if (createdField) meta.created = createdField
            } catch (error) { }

            try {
                const membershipField = JSON.parse(profile?.TuringTrader_Membership)
                if (membershipField) meta.membership = membershipField
            } catch (error) { }

            try {
                const settingsField = JSON.parse(profile?.TuringTrader_Settings)
                if (settingsField) meta.settings = settingsField
            } catch (error) { }

            if (isActive) {
                if (diffMeta(metaValue, meta)) {
                    setMetaValue(meta)
                    DEBUG_MSG(`read meta=${JSON.stringify(meta)}`)
                }

                timer = setTimeout(getMetaTimer, 20 * 1000)
            }
        }

        if (membership?.level.access > 0 &&
            typeof outsetaOverride === "undefined"
        )
            getMetaTimer()
        else
            setMetaValue(undefined)

        return () => {
            clearTimeout(timer)
            isActive = false
        }
    }, [membership, metaValue])

    //----- set metadata from the UI
    const metaSetter = (newMeta) => {
        if (!metaValue) return

        const cleanedMeta = cleanMeta(newMeta)

        if (diffMeta(metaValue, cleanedMeta)) {
            const profile = {
                TuringTrader_Accounts: JSON.stringify(cleanedMeta?.accounts),
                // NOTE: TuringTrader_Admin missing here!
                TuringTrader_Created: JSON.stringify(cleanedMeta?.created),
                TuringTrader_Membership: JSON.stringify(cleanedMeta?.membership),
                TuringTrader_Settings: JSON.stringify(cleanedMeta?.settings),
            }

            // FIXME: should we await this?
            updateProfile(profile, `bearer ${membership.user.auth}`, "donotlog=1")
            setMetaValue(cleanedMeta)
            DEBUG_MSG(`write meta=${JSON.stringify(profile)}`)
        }
    }

    return [metaValue, metaSetter]
}

//==============================================================================
// end of file
