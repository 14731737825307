//==============================================================================
// Project:     www.TuringTrader.com
// Name:        link-store.js
// Description: central store for external links.
// History:     2022 June 22, FUB, created
//==============================================================================

/*
    //---------- author homepages
    "@home-gary-antonacci": "https://www.optimalmomentum.com/about-us/",
    "@home-laurens-bensdorp": "https://tradingmasteryschool.com/about",
    "@home-andreas-clenow": "https://www.followingthetrend.com/about/",
    "@home-larry-connors": "https://tradingmarkets.com/",
    "@home-cesar-alvarez": "https://alvarezquanttrading.com/bio/",
    "@home-mebane-faber": "https://mebfaber.com/about/",
    "@home-eric-richardson":
        "https://mebfaber.com/2018/01/31/update-cambria-family/",
    "@home-wouter-keller":
        "https://papers.ssrn.com/sol3/cf_dev/AbsByAuth.cfm?per_id=1935527",
    "@home-adam-butler": "https://investresolve.com/adam-butler/",
    "@home-ilya-kipnis": "https://quantstrattrader.wordpress.com/about/",
    "@home-jan-willem-keuning": "https://indexswingtrader.blogspot.com/",
    "@home-brian-livingston": "http://brianlivingston.com/",
    "@home-tony-robbins": "https://www.tonyrobbins.com/",
    "@home-frank-grossmann": "https://logical-invest.com/about/about-us/",
    "@home-harry-browne": "http://www.harrybrowne.org/",
    "@home-scott-juds": "https://sumgrowth.com/InfoPages/company.aspx",


    //---------- other sources
    "@source-engineered-portfolio-accelerating-dual-momentum":
        "https://engineeredportfolio.com/2018/05/02/accelerating-dual-momentum-investing/",
    "@source-keller-classical-asset-allocation":
        "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=2606884",
    "@source-keller-defensive-asset-allocation":
        "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3212862",
    "@source-keller-lethargic-asset-allocation":
        "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3498092",
    "@source-univeral-investment-strategy":
        "https://logical-invest.com/app/strategy/uis/universal-investment-strategy",
    "@source-universal-investment-strategy-2":
        "https://logical-invest.com/universal-investment-strategy/",
    "@source-universal-investment-strategy-3":
        "https://logical-invest.com/leveraged-universal-investment-strategy/",
    "@source-universal-investment-strategy-4":
        "https://www.youtube.com/watch?v=uvjSLrxbrDc",
    "@source-universal-investment-strategy-5":
        "https://www.youtube.com/watch?v=1F-ZZnxxTXE",
    "@source-universal-investment-strategy-6":
        "https://www.youtube.com/watch?v=rforif7M89s",
    "@source-universal-investment-strategy-7":
        "https://github.com/fbertram/TuringTrader/blob/develop/BooksAndPubs/LI_UniversalInvestmentStrategy.cs",

    //---------- term explanations
    "@explain-sp-500": "https://us.spindices.com/indices/equity/sp-500",
    "@explain-bond-valuation": "https://en.wikipedia.org/wiki/Bond_valuation",

*/

export const LinkStore = {
    //---------- books on Amazon.com
    //"@book-antonacci-dual-momentum-investing": "https://www.amazon.com/Dual-Momentum-Investing-Innovative-Strategy/dp/0071849440/",
    "@book-antonacci-dual-momentum-investing": "https://amzn.to/3326jK6",
    //"@book-bensdorp-30-minute-stock-trader": "https://www.amazon.com/30-Minute-Stock-Trader-Stress-Free-Financial/dp/1619615738/",
    "@book-bensdorp-30-minute-stock-trader": "https://amzn.to/35opEIj",
    //"@book-larimore-bogleheads-three-fund-portfolio": "https://www.amazon.com/Bogleheads-Guide-Three-Fund-Portfolio-Outperforms/dp/1119487331/",
    "@book-larimore-bogleheads-three-fund-portfolio": "https://amzn.to/336uKWL",
    //"@book-clenow-stocks-on-the-move": "https://www.amazon.com/Stocks-Move-Beating-Momentum-Strategies/dp/1511466146/",
    "@book-clenow-stocks-on-the-move": "https://amzn.to/3jWf2Et",
    //"@book-connors-alpha-formula": "https://www.amazon.com/Alpha-Formula-Powered-Strategies-Market/dp/0578530848/",
    "@book-connors-alpha-formula": "https://amzn.to/3592G7K",
    //"@book-connors-high-probability-etf-trading": "https://www.amazon.com/High-Probability-ETF-Trading-Professional/dp/0615297412/",
    "@book-connors-high-probability-etf-trading": "https://amzn.to/3jQwZnD",
    //"@book-connors-short-term-trading-strategies": "https://www.amazon.com/Short-Term-Trading-Strategies-That/dp/0981923909/",
    "@book-connors-short-term-trading-strategies": "https://amzn.to/35cTI9G",
    //"@book-faber-ivy-portfolio": "https://www.amazon.com/Ivy-Portfolio-Invest-Endowments-Markets/dp/1118008855/",
    "@book-faber-ivy-portfolio": "https://amzn.to/2F8DDXq",
    //"@book-livingston-muscular-portfolios": "https://www.amazon.com/Muscular-Portfolios-Investing-Revolution-Superior/dp/194688538X/",
    "@book-livingston-muscular-portfolios": "https://amzn.to/3bvGDcF",
    //"@book-robbins-money-master-the-game": "https://www.amazon.com/MONEY-Master-Game-Financial-Freedom/dp/1476757801/",
    "@book-robbins-money-master-the-game": "https://amzn.to/2R2mC40",
    // "@book-rowland-permanent-portfolio": "https://www.amazon.com/Permanent-Portfolio-Long-Term-Investment-Strategy/dp/1118288254/",
    "@book-rowland-permanent-portfolio": "https://amzn.to/30t1gBE",
    //"@book-juds-seven-faces": "https://www.amazon.com/Conquering-Seven-Faces-Risk-Strategies/dp/154391375X/",
    "@book-juds-seven-faces": "https://amzn.to/3fxGAic",
    //"@book-boucher-hedge-fund-edge": https://www.amazon.com/Hedge-Fund-Edge-Maximum-Strategies/dp/0471185388/,
    "@book-boucher-hedge-fund-edge": "https://amzn.to/3Lx8kmN",
}

//==============================================================================
// end of file
