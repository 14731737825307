//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/layout/debug-warning
// Description: Global warning for debug code.
// Created:     FUB, October 2, 2022
//==============================================================================

import React from "react"
//import styled from "styled-components"
import { useMembership } from "../../utils/member-data"

export const DebugWarning = () => {
    const membership = useMembership()
    
    //return <div>{JSON.stringify(membership?.features.debug)}</div>
    return membership?.features.debug.hasAccess === true ?
        <div css={`background-color:red;text-align:center;font-weight:bold;`}><p>Membership Debug</p></div> :
        null
}

//==============================================================================
// end of file
