//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/layout/header.js
// Description: Global page header.
// Created:     FUB, Apr 16, 2022
//==============================================================================

import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { FaBars } from "react-icons/fa"
import { MdOutlineAccountCircle } from "react-icons/md"

import { Container } from "../widgets/container"
import { Button, ButtonSecondary } from "../widgets/button"
import { Link } from "../widgets/link"
import { useMembership } from "../../utils/member-data"

//------------------------------------------------------------------------------
const LogoPlusText = ({ className }) => (
    <Link href="/"
        css={`
            width: 7.0rem;
        `}
    >
        <StaticImage
            className={className}
            //src="../../images/brand/logo+text-1600x500.png" alt="TuringTrader.com"
            src="../../images/brand/logo+text-200x50.png"
            alt="TuringTrader.com"
        //loading="eager"
        />
    </Link>
)

const Logo = ({ className }) => (
    <Link href="/"
        css={`
            width: 2rem;
            margin-left: auto;
            margin-right: auto;
        `}
    >
        <StaticImage
            className={className}
            src="../../images/brand/logo-512x512.png"
            alt="TuringTrader.com"
        />
    </Link>
)

//------------------------------------------------------------------------------
const navItems = [
    {
        text: "Portfolios",
        link: "/portfolios/",
    }, {
        text: "Dashboard",
        link: "/dashboard/",
    }, {
        text: "Help",
        link: "/help/",
    },
]

const NavBar = styled(Container)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 0;
`

//------------------------------------------------------------------------------
const HeaderBigScreen = () => {
    const membership = useMembership()
    const isLoggedIn = membership?.level.access > 0

    return (
        <div
            css={`
                display: none;
                @media ${props => props.theme.breakpoints.desktop} {
                    display: inline-block;
                }
            `}
        >

            <NavBar
                css={`
                    overflow: visible;
                `}
            >
                <LogoPlusText />

                {navItems.map(i => (
                    <Button key={i.text} text={i.text} to={i.link} />
                ))}

                {isLoggedIn ?
                    <div
                        css={`
                            position: relative;
                            display: inline-block;
                            cursor: pointer;
                            &:hover {
                                div {
                                    display: block;
                                }
                            }
                        `}
                    >
                        {/*<Button text="Your Account" /><FaChevronDown />*/}
                        &nbsp;&nbsp;<MdOutlineAccountCircle />

                        <Container
                            css={`
                                display: none;
                                position: absolute;
                                right: 0;
                                z-index: 1;
                                background-color: ${props => props.theme.colors.bgHighlight};
                                border-left: 1px solid ${props => props.theme.colors.decorative};
                                border-bottom: 1px solid ${props => props.theme.colors.decorative};
                                border-radius: 0 0 0 ${props => props.theme.dimensions.cornerRadius};
                                box-shadow: ${props => props.theme.shadows.box};
                                width: 6em;
                            `}
                        >
                            <Button text="Account" to={membership?.links?.account} /><br />
                            <Button text="Log Out" to={membership?.links?.logout} />
                        </Container>
                    </div> :
                    <ButtonSecondary text="Member Login" to={membership?.links?.login} />
                }
            </NavBar>
        </div>
    )
}

//------------------------------------------------------------------------------
const HeaderSmallScreenDrawer = (props) => {
    const membership = useMembership()
    const isLoggedIn = membership?.level.access > 0

    return (
        <div
            css={`
                z-index: 999;
                position: fixed;
                top: 0;
                /*left: 0; set dynamically */
                height: 100vh;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                padding: 2rem;
                background-color: ${props => props.theme.colors.bgCanvas};
                text-align: center;
                transition-property: left;
                transition-duration: 0.5s;
                box-shadow: 50px 0px 100px 10px;
            `}
            {...props}
        >
            <Logo />

            {navItems.map(i => (
                <Button key={i.text} text={i.text} to={i.link} />
            ))}

            <div>
                &mdash;
            </div>
            {isLoggedIn ?
                <>
                    <Button text="Account" to={membership?.links?.account} />
                    <Button text="Log Out" to={membership?.links?.logout} />
                </> :
                <ButtonSecondary text="Member Login" to={membership?.links?.login} />
            }

        </div>
    )
}

const HeaderSmallScreenShade = styled.div`
    z-index 998;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /*background-color: black;*/
    /*opacity: 75%;*/
`

const HeaderSmallScreen = () => {
    const [isVisible, setVisible] = useState(false)

    const toggleMenu = (newMenuOpen) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return
        }

        setVisible(newMenuOpen)
    }

    useEffect(() => {
        const timer = isVisible ?
            setTimeout(() => setVisible(false), 5000) :
            null

        return () => {
            clearTimeout(timer)
        }
    }, [isVisible])

    const drawerTransition = {
        left: isVisible ? "0" : "-100%",
    }

    const shadeTransition = {
        display: isVisible ? "block" : "none",
    }

    return (
        <div
            css={`
                display: inline-block;
                @media ${props => props.theme.breakpoints.desktop} {
                    display: none;
                }
            `}
        >

            <NavBar>
                <LogoPlusText />
                <ButtonSecondary text={<FaBars />} onClick={toggleMenu(true)} />

                <HeaderSmallScreenDrawer onClick={toggleMenu(false)} style={drawerTransition} />
                <HeaderSmallScreenShade onClick={toggleMenu(false)} style={shadeTransition} />
            </NavBar>
        </div>
    )
}

//------------------------------------------------------------------------------
export const Header = () => {
    return (
        <>
            <HeaderBigScreen />
            <HeaderSmallScreen />
        </>
    )
}

//==============================================================================
// end of file
