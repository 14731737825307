//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/market-vane
// Description: Market Vane indicator widget.
// History:     2022 May 25, FUB, created
//==============================================================================

import React from "react"
//import { StaticImage } from "gatsby-plugin-image"
//import styled from "styled-components"
import { FaChartLine, FaBolt } from "react-icons/fa"

import { useMarketVane } from "../../utils/portfolio-data"
import { Lightbox } from "./lightbox"

//------------------------------------------------------------------------------
const MV_MIN = -3
const MV_NEG = -0.1
const MV_POS = 0.1
const MV_MAX = 10

/*
// GaugeOld: bar growing from left to right
const Gauge = ({ value, min, neg, pos, max, label }) => {
    const totalRange = max - min
    const red = 100 * (neg - min) / totalRange
    const yellow = 100 * (pos - neg) / totalRange
    const green = 100 * (max - pos) / totalRange
    const indicator = 100 * (value - min) / totalRange
    const indicatorCoerced = Math.min(100, Math.max(2, indicator))

    return (<div css={`display;width:15em;margin:0.5em;`}>
        <div css={`display:flex;width:100%;height:0.5em;border-radius:${props => props.theme.dimensions.cornerRadius};overflow:hidden;position:relative;`}>
            <div css={`flex:${red};background-color:${props => props.theme.colors.orange.base};`}>&nbsp;</div>
            <div css={`flex:${yellow};background-color:${props => props.theme.colors.textLight};`}>&nbsp;</div>
            <div css={`flex:${green};background-color:${props => props.theme.colors.green.base};`}>&nbsp;</div>
            <div css={`position:absolute;right:0;top:0;width:${100 - indicatorCoerced}%;background-color:${props => props.theme.colors.bgCanvas};opacity:80%;`}>&nbsp;</div>
        </div>
        <div css={`text-align:center;font-size:0.75em;`}>{label}</div>
    </div>)
}
*/

// Gauge: bar growing from center to right (positive), or left (negative)
/**
 * Bar-graph gauge for use with Market Vane
 * 
 * @param {*} value value to render
 * @param {*} min minimum (leftmost value). Typically -3.
 * @param {*} max maximum (rightmost value). Typically +10.
 * @param {*} neg start of negative (orange) range. Typically 0.
 * @param {*} pos start of positive (green) range. Typically +3.
 * @param {*} label label text 
 * @returns rendered component
 */
const Gauge = ({ value, label }) => {
    const totalRange = MV_MAX - MV_MIN
    const negRange = 100 * (MV_NEG - MV_MIN) / totalRange
    const zeroRange = 100 * (MV_POS - MV_NEG) / totalRange
    const posRange = 100 * (MV_MAX - MV_POS) / totalRange
    const neg = 100 * Math.max(0, Math.min(MV_NEG - MV_MIN, MV_NEG - value)) / (MV_NEG - MV_MIN)
    const pos = 100 * Math.max(0, Math.min(MV_MAX - MV_POS, value - MV_POS)) / (MV_MAX - MV_POS)

    return (<div css={`display;width:15em;margin:0.5em;`}>
        <div css={`display:flex;width:100%;height:0.5em;`}>
            <div css={`width:${negRange}%;height100%;position:relative;`}>
                <div css={`width:100%;height:100%;border-top-left-radius:100px;border-bottom-left-radius:100px;border:1px solid ${props => props.theme.colors.orange.base};opacity:50%;`} />
                <div css={`width:100%;height:100%;border-top-left-radius:100px;border-bottom-left-radius:100px;border:1px solid ${props => props.theme.colors.orange.base};opacity:10%;background-color:${props => props.theme.colors.orange.base};position:absolute;top:0;right:0;`} />
                <div css={`width:${neg}%;height:100%;border-top-left-radius:100px;border-bottom-left-radius:100px;border:1px solid ${props => props.theme.colors.orange.base};background-color:${props => props.theme.colors.orange.base};position:absolute;top:0;right:0;`} />
            </div>
            <div css={`width:${zeroRange}%;height:100%;position:relative;`}>
                <div css={`width:100%;height:100%;border:1px solid ${props => props.theme.colors.textLight};`} />
                <div css={`width:100%;height:100%;border:1px solid ${props => props.theme.colors.textLight};background-color:${props => props.theme.colors.textLight};position:absolute;top:0;left:0;`} />
            </div>
            <div css={`width:${posRange}%;height:100%;position:relative;`}>
                <div css={`width:100%;height:100%;border-top-right-radius:100px;border-bottom-right-radius:100px;border:1px solid ${props => props.theme.colors.green.base};opacity:50%;`} />
                <div css={`width:100%;height:100%;border-top-right-radius:100px;border-bottom-right-radius:100px;border:1px solid ${props => props.theme.colors.green.base};opacity:10%;background-color:${props => props.theme.colors.green.base};position:absolute;top:0;left:0;`} />
                <div css={`width:${pos}%;height:100%;border-top-right-radius:100px;border-bottom-right-radius:100px;border:1px solid ${props => props.theme.colors.green.base};background-color:${props => props.theme.colors.green.base};position:absolute;top:0;left:0;`} />
            </div>
        </div>
        <div css={`text-align:center;font-size:0.75em;`}>{label}</div>
    </div>)
}

export const MarketVane = () => {
    const marketVane = useMarketVane()

    return (<div css={`display:flex;justify-content:space-around;width:100%;`}>
        <div css={`text-align:center;`}>
            {marketVane?.marketRegime > 0 ? (
                <div css={`
                    font-size:5em;color:${props => props.theme.colors.accent};
                    filter: drop-shadow(5px 5px 5px ${props => props.theme.colors.green.base});
                `}>
                    <FaChartLine />
                </div>
            ) : (
                <div css={`
                    font-size:5em;color:${props => props.theme.colors.accent};
                    filter: drop-shadow(5px 5px 5px ${props => props.theme.colors.orange.base});
                `}>
                    <FaBolt />
                </div>
            )}
            <div><strong>
                {marketVane?.marketRegime > 0 ?
                    "Overall Bullish Market Conditions" :
                    "Overall Bearish Market Conditions"}
            </strong></div>
        </div>
        <div>
            <div>
                <Gauge value={marketVane?.stockMarketMomentum} label="Stock Market Momentum"/>
                <Gauge value={marketVane?.stocksOverBonds} label="Stock Outperformance of Bonds"/>
                <Gauge value={marketVane?.economicMomentum} label="Economic Health"/>
            </div>
            <div>
                <Gauge value={marketVane?.vixMargin} label="Volatility Budget"/>
                <Gauge value={marketVane?.drawdownMargin} label="Drawdown Budget"/>
            </div>
        </div>
    </div>)
}

//------------------------------------------------------------------------------
const IndicatorChart = (chartField, title) => {
    const data = useMarketVane()
    const imgUrl = data?.[chartField]

    return imgUrl ? (<div>
        <Lightbox title={title} src={imgUrl}>
            <img css={`width:100%;`}
                src={imgUrl}
                alt="TuringTrader.com"
            />
        </Lightbox>
    </div>) : null
}

export const MonthlyIndicatorsChart = () =>
    IndicatorChart(
        "monthlyIndicatorsChart",
        "Market Regime: Chart of Monthly Indicators"
    )

export const DailyIndicatorsChart = () =>
    IndicatorChart(
        "dailyIndicatorsChart",
        "Market Regime: Chart of Daily Indicators"
    )

export const PortfolioPerformanceChart = () =>
    IndicatorChart(
        "portfolioPerformanceChart",
        "Market Vane: Trading Performance"
    )

    //==============================================================================
// end of file
