//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/youtube.js
// Description: Lightbox component for youtube.
// History:     2022 June 21, FUB, created
//==============================================================================

import React, { useState } from "react"
//import styled from "styled-components"

import { Popup } from "./popup"
import { ButtonSecondary } from "./button"

//------------------------------------------------------------------------------
/* YouTube embed code
    <iframe 
        width="560" 
        height="315" 
        src="https://www.youtube.com/embed/S0X_GO0b7Lg" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; 
        autoplay; 
        clipboard-write; 
        encrypted-media; 
        gyroscope; 
        picture-in-picture" 
        allowfullscreen
    >
    </iframe>
*/
// see https://developers.google.com/youtube/player_parameters

/*
*/

export const YouTube = ({ text = "watch video", title = "TuringTrader video", url }) => {
    const [isVisible, setVisible] = useState(false)

    return (<>
        <ButtonSecondary onClick={() => setVisible(true)} text={text} />
        {isVisible && (
            <Popup title={title} onCancel={() => setVisible(false)}>
                <div css={`
                    width: 90vw;
                    margin: ${props => props.theme.spacings.negNormal};
                    padding: 0;
                    position: relative;
                `}>
                    <div css={`
                        width:0;
                        height:0;
                        padding-bottom:56.25%;
                        margin:0;
                        background-color:red;`}
                    />
                    <iframe
                        title={title}
                        css={`
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            margin: 0;
                            padding: 0;
                        `}
                        src={`${url}?autoplay=0&controls=1&fs=0&modestbranding=1&origin=https://www.turingtrader.com&rel=0`}
                    />
                </div>
            </Popup>
    )
}
    </>)
}
//------------------------------------------------------------------------------
/*
const useStyles = makeStyles((theme) => ({
    lightbox: {
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "999",
        backgroundColor: "rgba(0,0,0,0.8)",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        //overflow: "auto",
        padding: "10px",
    },
    videoContainer: {
        flex: "none",
        position: "relative",
        width: "100%",
        height: "0",
        paddingBottom: "56.25%", // 19:9 aspect ratio
    },
    videoIframe: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        border: "none",
    },
}))

//------------------------------------------------------------------------------

export const YouTubeXXX = ({ cta, url }) => {
    const [isPlaying, setPlaying] = useState(false)
    const styles = useStyles()

    return (
        <>
            <CTA
                tier2={true}
                text={cta}
                onClick={() => setPlaying(true)}
            />

            {isPlaying && (
                <div className={styles.lightbox} onClick={() => setPlaying(false)}>
                    <div className={styles.videoContainer}>
                        <iframe
                            title="TuringTrader video"
                            className={styles.videoIframe}
                            src={`${url}?autoplay=0&controls=1&fs=0&modestbranding=1&origin=https://www.turingtrader.com&rel=0`}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
*/

 //==============================================================================
 // end of file
