//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/mdx/component.js
// Description: MDX layout for components.
// Created:     FUB, Apr 19, 2022
//==============================================================================

import React from "react"
import { MDXProvider } from "@mdx-js/react"

import { components } from "./component-mapping"

export const MdxComponent = ({ children }) => {
    return (
        <MDXProvider components={components}>
            {children}
        </MDXProvider>
    )
}

export default MdxComponent

//==============================================================================
// end of file
