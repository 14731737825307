//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/portfolio/metrics
// Description: Widgets for portfolio display
// Created:     FUB, May 05, 2022
//==============================================================================

import React from "react"

import { usePortfolioData } from "../../../utils/portfolio-data"
import { TableSimple } from "../table"

export const Metrics = ({ portfolio }) => {
    const data = usePortfolioData(portfolio)
    const table = data?.metricsTable

    return (<TableSimple data={table} />)
}

//==============================================================================
// end of file
