//==============================================================================
// Project:     www.TuringTrader.com
// Name:        utils/portfolio-data
// Description: Access to portfolio data.
// History:     2022iv23, FUB, created
//==============================================================================

import { /*createContext, useContext,*/ useState, useEffect } from "react"

import { useTextFile, useJsonFile, useImageUrl, useDownloadChartApi, useDownloadAllocApi } from "./firebase"
import { usePortfolioList } from "./portfolio-list"

//------------------------------------------------------------------------------

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`PF-DATA: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`PF-DATA: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
/*
export const PortfolioDataContext = createContext(null)

export const PortfolioDataProvider = ({ children }) => {
    const [portfolioData, setPortfolioData] = useState({})

    const mergePortfolioData = (newData) => {
        const mergedData = {
            ...portfolioData,
            ...newData,
        }

        setPortfolioData(mergedData)
    }

    const contextData = {
        portfolioData,
        mergePortfolioData
    }

    return (
        <PortfolioDataContext.Provider value={contextData}>
            {children}
        </PortfolioDataContext.Provider>
    )
}
*/

//------------------------------------------------------------------------------
/**
 * Hook to fetch all of a portfolio's data.
 * 
 * This hook loads all data for a specific portfolio, including the asset
 * allocation, the metrics table, links to images, and the last update time. 
 * Because the data is stored in multiple text and json files, the fields
 * trickle in and might not all be available at the same time.
 * 
 * @param {*} portfolio the portfolio's name including an appendix with the version
 * but without any slashes
 * @return object with all portfolio data
 */
export const usePortfolioData = (portfolio) => {
    const [theData, setData] = useState()

    const lastUpdate = useTextFile(portfolio, "last-update.txt")
    useEffect(() => {
        if (!lastUpdate ||
            theData?.lastUpdate === lastUpdate) return

        const mergedData = {
            ...theData,
            lastUpdate,
        }

        setData(mergedData)

    }, [lastUpdate, theData])

    const lastRebalance = useTextFile(portfolio, "last-rebalance.txt")
    useEffect(() => {
        if (!lastRebalance ||
            theData?.lastRebalance === lastRebalance) return

        const mergedData = {
            ...theData,
            lastRebalance,
        }

        setData(mergedData)

    }, [lastRebalance, theData])

    // TODO: use usePortfolioAlloc here to avoid code duplication
    const rawAllocation = useJsonFile(portfolio, "portfolio-holdings.json")
    useEffect(() => {
        if (//!allocationTable || // we accept a missing table
            JSON.stringify(theData?._rawAllocation) === JSON.stringify(rawAllocation)) return

        // table format received:
        /* const rawAllocation = [
            ["Symbol","Name","Allocation","Price"],
            ["AGG","<a href='https://...' target='_blank' rel='noopener noreferrer'>iShares Core US Aggregate Bond ETF</a>","32.63%","102"],
            ["SPYV","<a href='https://...' target='_blank' rel='noopener noreferrer'>SPDR Portfolio S&P 500 Value ETF</a>","17.16%","40.029998779296875"],
            ["XLV","<a href='https://...' target='_blank' rel='noopener noreferrer'>Health Care Select Sector SPDR ETF</a>","17.01%","130.19000244140625"],
            ["BIL","<a href='https://...' target='_blank' rel='noopener noreferrer'>SPDR Bloomberg 1-3 Month T-Bill ETF</a>","13.22%","91.44000244140625"],
            ["IEF","<a href='https://....' target='_blank' rel='noopener noreferrer'>iShares 7-10 Year Treasury Bond ETF</a>","4.96%","101.66999816894531"],
            ["VIXY","<a href='https://...' target='_blank' rel='noopener noreferrer'>ProShares VIX Short-Term Futures ETF</a>","4.96%","20.450000762939453"],
            ["SPY","<a href='https://...' target='_blank' rel='noopener noreferrer'>SPDR S&P 500 Trust ETF</a>","4.96%","413.80999755859375"],
            ["TIP","<a href='https://...' target='_blank' rel='noopener noreferrer'>iShares TIPS Bond ETF</a>","1.65%","118.48999786376953"],
            ["GLD","<a href='https://...' target='_blank' rel='noopener noreferrer'>SPDR Gold Shares ETF</a>","1.65%","175.1300048828125"],
            ["DBC","<a href='https://...' target='_blank' rel='noopener noreferrer'>Invesco DB Commodity Index Tracking ETF</a>","1.65%","28.270000457763672"]
        ] */

        // format table as follows:
        /* const allocationTable = {
            columns: ["header 1", "header 2", "header 3"],
            "header 1": ["row 1", "row 2", "row 3"],
            "header 2": ["row 1", "row 2", "row 3"],
            "header 3": ["row 1", "row 2", "row 3"],
        } */

        const allocationTotal = rawAllocation ? rawAllocation
            .filter((item, index) => index > 0)
            .reduce((prev, item) => {
                const num = parseFloat(item[2])
                return isNaN(num) ? prev : (prev + num / 100)
            }, 0) : 1
        const allocationScale = Math.max(1, allocationTotal)

        const allocationTable1 = rawAllocation ? {
            columns: ["Symbol", "Name", "Allocation", "Price"],
            "Symbol": rawAllocation.filter((item, index) => index > 0).map(item => item[0]),
            "Name": rawAllocation.filter((item, index) => index > 0).map(item => item[1].replaceAll(/<[^>]+>/g, "")),
            "Allocation": rawAllocation.filter((item, index) => index > 0).map(item => isNaN(parseFloat(item[2])) ? "---" : `${(parseFloat(item[2]) / allocationScale).toFixed(2)}%`),
            "Price": rawAllocation.filter((item, index) => index > 0).map(item => parseFloat(item[3].replace(/[$,]/g, ""))),
        } : {
            columns: ["Symbol", "Name", "Allocation"],
            "Symbol": [],
            "Name": [],
            "Allocation": [],
            "Price": [],
        }
        const allocationTable = allocationTotal > 0.95 ? {
            ...allocationTable1,
        } : {
            columns: allocationTable1.columns,
            "Symbol": [...allocationTable1["Symbol"], null],
            "Name": [...allocationTable1["Name"], "Idle Cash"],
            "Allocation": [...allocationTable1["Allocation"], `${(100 * (1 - allocationTotal)).toFixed(2)}%`],
            "Price": [...allocationTable1["Price"], null],
        }
        const mergedData = {
            ...theData,
            _rawAllocation: rawAllocation,
            allocationTable,
        }

        setData(mergedData)
    }, [rawAllocation, theData])

    const rawMetrics = useJsonFile(portfolio, "performance-table.json")
    useEffect(() => {
        if (!rawMetrics ||
            JSON.stringify(theData?._rawMetrics) === JSON.stringify(rawMetrics)) return

        // table format received:
        /* const rawMetrics = [
            ["Metric","","TT's All-Stars: Total Return v3","Vanilla 60/40"],
            ["Simulation Start","02/01/2008","$1,000.00","$1,000.00"],
            ["Simulation End","05/05/2022","$6,852.84","$2,787.81"],
            ["Simulation Period","14.3 years","",""],
            ["Compound Annual Growth Rate","","14.45%","7.46%"],
            ["Stdev of Returns (Monthly, Annualized)","","8.89%","11.37%"],
            ["Maximum Drawdown (Daily)","","13.36%","34.01%"],
            ["Maximum Flat Days","","266.00 days","882.00 days"],
            ["Sharpe Ratio (Rf=T-Bill, Monthly, Annualized)","","1.46","0.61"],
            ["Beta (To S&P 500, Monthly)","","0.28","0.60"],
            ["Ulcer Index","","2.57%","6.72%"],
            ["Ulcer Performance Index (Martin Ratio)","","5.62","1.11"]
        ] */

        // format table as follows:
        /* const metricsTable = {
            columns: ["header 1", "header 2", "header 3"],
            "header 1": ["row 1", "row 2", "row 3"],
            "header 2": ["row 1", "row 2", "row 3"],
            "header 3": ["row 1", "row 2", "row 3"],
        } */

        const metricsTable = {
            columns: [rawMetrics[0][0], rawMetrics[0][2], rawMetrics[0][3]],
            [rawMetrics[0][0]]: [
                `${rawMetrics[1][0]} (${rawMetrics[1][1]})`,
                `${rawMetrics[2][0]} (${rawMetrics[2][1]})`,
                rawMetrics[3][0], rawMetrics[4][0], rawMetrics[5][0], rawMetrics[6][0],
                rawMetrics[7][0], rawMetrics[8][0], rawMetrics[9][0], rawMetrics[10][0], rawMetrics[11][0]
            ],
            [rawMetrics[0][2]]: [
                rawMetrics[1][2], rawMetrics[2][2], rawMetrics[3][1], rawMetrics[4][2], rawMetrics[5][2],
                rawMetrics[6][2], rawMetrics[7][2], rawMetrics[8][2], rawMetrics[9][2], rawMetrics[10][2], rawMetrics[11][2],
            ],
            [rawMetrics[0][3]]: [
                rawMetrics[1][3], rawMetrics[2][3], rawMetrics[3][1], rawMetrics[4][3], rawMetrics[5][3],
                rawMetrics[6][3], rawMetrics[7][3], rawMetrics[8][3], rawMetrics[9][3], rawMetrics[10][3], rawMetrics[11][3],
            ],
        }

        const mergedData = {
            ...theData,
            _rawMetrics: rawMetrics,
            metricsTable,
        }

        setData(mergedData)
    }, [rawMetrics, theData])

    const cumulativePerformanceChart = useImageUrl(portfolio, "performance-drawdown.jpg")
    useEffect(() => {
        if (!cumulativePerformanceChart ||
            theData?.cumulativePerformanceChart === cumulativePerformanceChart) return

        const mergedData = {
            ...theData,
            cumulativePerformanceChart,
        }

        setData(mergedData)
    }, [cumulativePerformanceChart, theData])

    const rawCumulativePerformanceTable = useJsonFile(portfolio, "performance-chart.json")
    useEffect(() => {
        if (!rawCumulativePerformanceTable ||
            JSON.stringify(theData?._rawCumulativePerformanceTable) === JSON.stringify(rawCumulativePerformanceTable)) return

        // data format received:
        // const rawCumulativeChart = {
        //     labels: ["Date", "Portfolio", "Benchmark"],
        //     data: [
        //         ["01/02/2007", 1000.00, 1000.00],
        //     ]
        // }

        // format table as follows
        // cumulativeChartTable

        const cumulativePerformanceTable = {
            columns: rawCumulativePerformanceTable.labels,
            [rawCumulativePerformanceTable.labels[0]]: rawCumulativePerformanceTable.data.map(tuple => tuple[0]),
            [rawCumulativePerformanceTable.labels[1]]: rawCumulativePerformanceTable.data.map(tuple => 1000 * tuple[1] / rawCumulativePerformanceTable.data[0][1]),
            [rawCumulativePerformanceTable.labels[2]]: rawCumulativePerformanceTable.data.map(tuple => 1000 * tuple[2] / rawCumulativePerformanceTable.data[0][2]),
        }

        const mergedData = {
            ...theData,
            _rawCumulativePerformanceTable: rawCumulativePerformanceTable,
            cumulativePerformanceTable,
        }

        setData(mergedData)
    }, [rawCumulativePerformanceTable, theData])

    const annualPerformanceChart = useImageUrl(portfolio, "annual-returns.jpg")
    useEffect(() => {
        if (!annualPerformanceChart ||
            theData?.annualPerformanceChart === annualPerformanceChart) return

        const mergedData = {
            ...theData,
            annualPerformanceChart,
        }

        setData(mergedData)
    }, [annualPerformanceChart, theData])

    const monteCarloChart = null //useImageUrl(portfolio, "return-monte-carlo.jpg")
    useEffect(() => {
        if (!monteCarloChart ||
            theData?.monteCarloChart === monteCarloChart) return

        const mergedData = {
            ...theData,
            monteCarloChart,
        }

        setData(mergedData)
    }, [monteCarloChart, theData])

    const monteCarloChart2 = useImageUrl(portfolio, "return-monte-carlo-2.jpg")
    useEffect(() => {
        if (!monteCarloChart2 ||
            theData?.monteCarloChart2 === monteCarloChart2) return

        const mergedData = {
            ...theData,
            monteCarloChart2,
        }

        setData(mergedData)
    }, [monteCarloChart2, theData])

    const rollingReturnChart = useImageUrl(portfolio, "rolling-returns.jpg")
    useEffect(() => {
        if (!rollingReturnChart ||
            theData?.rollingReturnChart === rollingReturnChart) return

        const mergedData = {
            ...theData,
            rollingReturnChart,
        }

        setData(mergedData)
    }, [rollingReturnChart, theData])

    return theData
}

export const usePortfolioAlloc = (portfolio) => {
    const [theAlloc, setAlloc] = useState()

    const rawAllocation = useJsonFile(portfolio, "portfolio-holdings.json")
    useEffect(() => {
        if (!portfolio)
            return

        if (!rawAllocation)
            return

        // table format received:
        /* const rawAllocation = [
            ["Symbol","Name","Allocation","Price"],
            ["AGG","<a href='https://...' target='_blank' rel='noopener noreferrer'>iShares Core US Aggregate Bond ETF</a>","32.63%","102"],
            ["SPYV","<a href='https://...' target='_blank' rel='noopener noreferrer'>SPDR Portfolio S&P 500 Value ETF</a>","17.16%","40.029998779296875"],
            ["XLV","<a href='https://...' target='_blank' rel='noopener noreferrer'>Health Care Select Sector SPDR ETF</a>","17.01%","130.19000244140625"],
            ["BIL","<a href='https://...' target='_blank' rel='noopener noreferrer'>SPDR Bloomberg 1-3 Month T-Bill ETF</a>","13.22%","91.44000244140625"],
            ["IEF","<a href='https://....' target='_blank' rel='noopener noreferrer'>iShares 7-10 Year Treasury Bond ETF</a>","4.96%","101.66999816894531"],
            ["VIXY","<a href='https://...' target='_blank' rel='noopener noreferrer'>ProShares VIX Short-Term Futures ETF</a>","4.96%","20.450000762939453"],
            ["SPY","<a href='https://...' target='_blank' rel='noopener noreferrer'>SPDR S&P 500 Trust ETF</a>","4.96%","413.80999755859375"],
            ["TIP","<a href='https://...' target='_blank' rel='noopener noreferrer'>iShares TIPS Bond ETF</a>","1.65%","118.48999786376953"],
            ["GLD","<a href='https://...' target='_blank' rel='noopener noreferrer'>SPDR Gold Shares ETF</a>","1.65%","175.1300048828125"],
            ["DBC","<a href='https://...' target='_blank' rel='noopener noreferrer'>Invesco DB Commodity Index Tracking ETF</a>","1.65%","28.270000457763672"]
        ] */

        // format table as follows:
        /* const allocationTable = {
            columns: ["Symbol", "Name", "Allocation", "Price"],
            "Symbol":     ["row 1", "row 2", "row 3"],
            "Name":       ["row 1", "row 2", "row 3"],
            "Allocation": ["row 1", "row 2", "row 3"],
            "Price":      ["row 1", "row 2", "row 3"],
        } */

        const allocationTotal = rawAllocation ? rawAllocation
            .filter((item, index) => index > 0)
            .reduce((prev, item) => {
                const num = parseFloat(item[2])
                return isNaN(num) ? prev : (prev + num / 100)
            }, 0) : 1
        const allocationScale = Math.max(1, allocationTotal)

        const allocationTable1 = rawAllocation ? {
            columns: ["Symbol", "Name", "Allocation", "Price"],
            "Symbol": rawAllocation.filter((item, index) => index > 0).map(item => item[0]),
            "Name": rawAllocation.filter((item, index) => index > 0).map(item => item[1].replaceAll(/<[^>]+>/g, "")),
            "Allocation": rawAllocation.filter((item, index) => index > 0).map(item => isNaN(parseFloat(item[2])) ? "---" : `${(parseFloat(item[2]) / allocationScale).toFixed(2)}%`),
            "Price": rawAllocation.filter((item, index) => index > 0).map(item => parseFloat(item[3].replace(/[$,]/g, ""))),
        } : {
            columns: ["Symbol", "Name", "Allocation", "Price"],
            "Symbol": [],
            "Name": [],
            "Allocation": [],
            "Price": [],
        }
        const allocationTable = allocationTotal > 0.95 ? {
            ...allocationTable1,
        } : {
            columns: allocationTable1.columns,
            "Symbol": [...allocationTable1["Symbol"], "---"],
            "Name": [...allocationTable1["Name"], "Idle Cash"],
            "Allocation": [...allocationTable1["Allocation"], `${(100 * (1 - allocationTotal)).toFixed(2)}%`],
            "Price": [...allocationTable1["Price"], NaN],
        }
        DEBUG_MSG(`portfolio = ${portfolio}, allocationTable=${JSON.stringify(allocationTable)}`)
        setAlloc({
            portfolio,
            table: allocationTable
        })
    }, [rawAllocation, portfolio])

    return portfolio &&
        theAlloc?.portfolio === portfolio &&
        theAlloc.table
}

export const usePortfolioHistory = (portfolio) => {
    const [theData, setData] = useState()

    const rawHistoryTable = useJsonFile(portfolio, "holdings-history.json")
    useEffect(() => {
        if (!rawHistoryTable ||
            JSON.stringify(theData?._rawHistoryTable) === JSON.stringify(rawHistoryTable)) return

        // data format received:
        // const rawHistory = [
        //     ["Date","Allocation"],
        //     ["1/10/2007 4:00:00 PM","TSG-200703=20.13%, HLT-200710=7.90%, HAS=7.51%, UIS=7.45%, IFF=6.98%, DTV-201507=6.49%, LXK-201611=6.02%, PTV-201011=5.73%, ASH=5.28%, TPR=4.94%, EOP-200702=4.88%, CELG-201911=3.95%, CBRE=3.20%, IPG=2.61%, ATI=2.41%, IAC=2.29%, TEX=1.93%"],
        //     ["1/17/2007 4:00:00 PM","TSG-200703=23.11%, IFF=8.09%, LXK-201611=7.57%, CELG-201911=7.38%, HLT-200710=6.78%, ASH=6.70%, IPG=6.57%, IAC=5.88%, PTV-201011=5.67%, TPR=5.04%, CBRE=4.76%, ATI=4.69%, UIS=3.97%, TEX=3.79%"],
        //     ...
        // ]

        const historyTable = {
            columns: ["Date", "Allocation"],
            ["Date"]: rawHistoryTable // eslint-disable-line no-useless-computed-key
                .filter((row, idx) => idx > 0)
                .map(row => row[0].split(" ")[0])
                .reverse(),
            ["Allocation"]: rawHistoryTable // eslint-disable-line no-useless-computed-key
                .filter((row, idx) => idx > 0)
                .map(row => row[1])
                .reverse(),
        }

        const mergedData = {
            ...theData,
            _rawHistoryTable: rawHistoryTable,
            ...historyTable,
        }

        setData(mergedData)
    }, [rawHistoryTable, theData])

    return theData
}

//------------------------------------------------------------------------------
/**
 * React hook to load overview metrics for all portfolios.
 * 
 * This hook loads the overview metrics used for portfolios comparisons. The
 * object returned is keyed with the portfolio slugs including an appendix for
 * the version but without slashes. Under each key there is an object with one
 * field for each metric.
 * 
 * @returns object with metrics for all portfolios
 */
export const usePortfolioOverview = (refresh = 60 * 60 * 1000) => {
    const [theData, setData] = useState()

    const allMetrics = useJsonFile(null, "portfolio-comp.json", refresh)
    const allRebals = useJsonFile(null, "portfolio-rebal.json", refresh)
    //DEBUG_MSG(`allRebals=${JSON.stringify(allRebals)}`)

    useEffect(() => {
        if (!allMetrics || !allRebals) return

        /*
            allMetrics = {
                "portfolio": ["TT's All-Stars Leveraged v2"],
                "slug": ["tt-all-stars-lev-v2", ...],
                "cagr-1w": [],
                "cagr-1m": [],
                "cagr-3m": [],
                "cagr-1y": [],
                "cagr-2y": [],
                "cagr-5y": [],
                "cagr-10y": [],
                "cagr-max": [],
                "stdev": [],
                "mdd": [],
                "ulcer": [],
                "sharpe": [],
                "martin": [],
            }
        */

        let tmp = {
            //slugs: allMetrics.slug,
        }
        allMetrics.slug.forEach((slug, index) => {
            const tmp2 = {
                "name": allMetrics["portfolios"][index],
                "cagr-max": allMetrics["cagr-max"][index],
                "stdev": allMetrics["stdev"][index],
                "mdd": allMetrics["mdd"][index],
                "ulcer": allMetrics["ulcer"][index],
                "sharpe": allMetrics["sharpe"][index],
                "martin": allMetrics["martin"][index],
                //---
                "cagr-1w": allMetrics["cagr-1w"][index],
                "cagr-1m": allMetrics["cagr-1m"][index],
                "cagr-3m": allMetrics["cagr-3m"][index],
                "cagr-1y": allMetrics["cagr-1y"][index],
                "cagr-2y": allMetrics["cagr-2y"][index],
                "cagr-5y": allMetrics["cagr-5y"][index],
                "cagr-10y": allMetrics["cagr-10y"][index],
                "nav-end": allMetrics["nav-end"][index].toFixed(2),
                "cagr-5th": (function () { try { return JSON.parse(allMetrics?.["cagr-5th"]?.[index]) } catch (err) { return null } })(),
                "mdd-5th": allMetrics["mdd-5th"][index],
                "date-end": allMetrics["date-end"][index],
                "updated": allMetrics["updated"][index],
                "last-rebal": allRebals?.[slug],
            }
            //DEBUG_MSG(`pf=${slug} data=${JSON.stringify(tmp2)}`)

            tmp[slug] = tmp2
        })

        setData(tmp)
    }, [allMetrics, allRebals])

    return theData
}

const usePortfolioPick = (portfolio, label, description) => {
    const list = usePortfolioList()
    const info = list
        ?.filter(i => i.slug === portfolio)
        ?.[0]
    const metrics = useJsonFile(info?.slug2, "performance-table.json")
    const chart = useImageUrl(info?.slug2, "performance-drawdown.jpg")

    const [theData, setData] = useState()
    useEffect(() => {
        if (!metrics || !chart) return

        const benchmark = metrics[0][3].includes("500") ?
            "the S&P 500" :
            "a 60/40 portfolio"

        const relReturn = 100 * (parseFloat(metrics[4][2]) / parseFloat(metrics[4][3]) - 1)
        const relRisk = -100 * (parseFloat(metrics[10][2]) / parseFloat(metrics[10][3]) - 1)

        setData({
            title: info?.title,
            slug: portfolio,
            label,
            chart,
            description,
            comparison: `Compared to ${benchmark}`,
            compReturn: `${relReturn.toFixed(0)}% higher return`,
            compRisk: `${relRisk.toFixed(0)}% lower downside`,
        })
    }, [info, metrics, chart, label, description, portfolio])

    return theData
}
export const usePortfolioPicks = () => {
    const pf1 = usePortfolioPick(
        "tt-stocks-on-a-stroll",
        "Conservative",
        "A low-volatility mix of stocks and bonds that rebalances its positions weekly. We combine a momentum strategy on individual stocks with a healthy position in a managed bond strategy."
    )
    /*const pf2 = usePortfolioPick(
        "tt-all-stars-tr",
        "Balanced",
        "The perfect balance between high returns and low risk. Rebalanced daily, this meta-portfolio diversifies across asset classes and investment styles, namely momentum, mean-reversion, and volatility targeting."
    )*/
    const pf2 = usePortfolioPick(
        "tt-all-stars-xl",
        "Balanced",
        "The perfect balance between high returns and low risk. Rebalanced daily, this meta-portfolio diversifies across asset classes and investment styles, namely momentum, mean-reversion, and volatility targeting."
    )
    const pf3 = usePortfolioPick(
        "tt-all-stars-lev",
        "Aggressive",
        "A leveraged portfolio delivering outsized returns to aggressive investors. With a varied rebalancing schedule, the portfolio keeps risk at bay by combining two momentum strategies with a strategy based on mean-variance optimization."
    )

    const [theData, setData] = useState()
    useEffect(() => {
        setData([pf1, pf2, pf3])
    }, [pf1, pf2, pf3])

    return theData
}

//------------------------------------------------------------------------------
export const useMarketOverview = () => {
    const [theData, setData] = useState()

    const lastUpdate = useTextFile("indicators", "last-update.txt")
    useEffect(() => {
        if (!lastUpdate ||
            theData?.lastUpdate === lastUpdate) return

        const mergedData = {
            ...theData,
            lastUpdate,
        }

        setData(mergedData)

    }, [lastUpdate, theData])

    const stockMarketChart = useImageUrl("indicators", "stock-market.jpg")
    useEffect(() => {
        if (!stockMarketChart ||
            theData?.stockMarketChart === stockMarketChart) return

        const mergedData = {
            ...theData,
            stockMarketChart,
        }

        setData(mergedData)
    }, [stockMarketChart, theData])

    const stockMarketChartSm = useImageUrl("indicators", "stock-market-sm.jpg")
    useEffect(() => {
        if (!stockMarketChartSm ||
            theData?.stockMarketChartSm === stockMarketChartSm) return

        const mergedData = {
            ...theData,
            stockMarketChartSm,
        }

        setData(mergedData)
    }, [stockMarketChartSm, theData])

    const bondMarketChart = useImageUrl("indicators", "bond-market.jpg")
    useEffect(() => {
        if (!bondMarketChart ||
            theData?.bondMarketChart === bondMarketChart) return

        const mergedData = {
            ...theData,
            bondMarketChart,
        }

        setData(mergedData)
    }, [bondMarketChart, theData])

    const bondMarketChartSm = useImageUrl("indicators", "bond-market-sm.jpg")
    useEffect(() => {
        if (!bondMarketChartSm ||
            theData?.bondMarketChartSm === bondMarketChartSm) return

        const mergedData = {
            ...theData,
            bondMarketChartSm,
        }

        setData(mergedData)
    }, [bondMarketChartSm, theData])

    const commoditiesChart = useImageUrl("indicators", "commodities.jpg")
    useEffect(() => {
        if (!commoditiesChart ||
            theData?.commoditiesChart === commoditiesChart) return

        const mergedData = {
            ...theData,
            commoditiesChart,
        }

        setData(mergedData)
    }, [commoditiesChart, theData])

    const commoditiesChartSm = useImageUrl("indicators", "commodities-sm.jpg")
    useEffect(() => {
        if (!commoditiesChartSm ||
            theData?.commoditiesChartSm === commoditiesChartSm) return

        const mergedData = {
            ...theData,
            commoditiesChartSm,
        }

        setData(mergedData)
    }, [commoditiesChartSm, theData])

    const currenciesChart = useImageUrl("indicators", "currencies.jpg")
    useEffect(() => {
        if (!currenciesChart ||
            theData?.currenciesChart === currenciesChart) return

        const mergedData = {
            ...theData,
            currenciesChart,
        }

        setData(mergedData)
    }, [currenciesChart, theData])

    const currenciesChartSm = useImageUrl("indicators", "currencies-sm.jpg")
    useEffect(() => {
        if (!currenciesChartSm ||
            theData?.currenciesChartSm === currenciesChartSm) return

        const mergedData = {
            ...theData,
            currenciesChartSm,
        }

        setData(mergedData)
    }, [currenciesChartSm, theData])

    const economyChart = useImageUrl("indicators", "economy.jpg")
    useEffect(() => {
        if (!economyChart ||
            theData?.economyChart === economyChart) return

        const mergedData = {
            ...theData,
            economyChart,
        }

        setData(mergedData)
    }, [economyChart, theData])

    const economyChartSm = useImageUrl("indicators", "economy-sm.jpg")
    useEffect(() => {
        if (!economyChartSm ||
            theData?.economyChartSm === economyChartSm) return

        const mergedData = {
            ...theData,
            economyChartSm,
        }

        setData(mergedData)
    }, [economyChartSm, theData])

    return theData
}

//------------------------------------------------------------------------------
/**
 * Reat hook to provide Market Vane status.
 * 
 * This hook returns the current status of Market Vane and its components.
 * 
 * @returns object with Market Vane status
 */
export const useMarketVane = () => {
    const [theData, setData] = useState()

    const rawMarketVane = useJsonFile("tt-market-vane", "status.json")
    useEffect(() => {
        if (!rawMarketVane ||
            JSON.stringify(theData?._rawMarketVane) === JSON.stringify(rawMarketVane)) return

        setData({
            ...theData,
            _rawMarketVane: rawMarketVane,
            marketRegime: rawMarketVane[1][1],
            stockMarketMomentum: rawMarketVane[2][1],
            economicMomentum: rawMarketVane[3][1],
            stocksOverBonds: rawMarketVane[4][1],
            vixMargin: rawMarketVane[5][1],
            drawdownMargin: rawMarketVane[6][1],
        })
    }, [rawMarketVane, theData])

    const monthlyIndicatorsChart = useImageUrl("tt-market-vane", "monthly-indicators.jpg")
    useEffect(() => {
        if (!monthlyIndicatorsChart ||
            theData?.monthlyIndicatorsChart === monthlyIndicatorsChart) return

        const mergedData = {
            ...theData,
            monthlyIndicatorsChart,
        }

        setData(mergedData)
    }, [monthlyIndicatorsChart, theData])

    const dailyIndicatorsChart = useImageUrl("tt-market-vane", "daily-indicators.jpg")
    useEffect(() => {
        if (!dailyIndicatorsChart ||
            theData?.dailyIndicatorsChart === dailyIndicatorsChart) return

        const mergedData = {
            ...theData,
            dailyIndicatorsChart,
        }

        setData(mergedData)
    }, [dailyIndicatorsChart, theData])

    const portfolioPerformanceChart = useImageUrl("tt-market-vane", "performance-drawdown.jpg")
    useEffect(() => {
        if (!portfolioPerformanceChart ||
            theData?.portfolioPerformanceChart === portfolioPerformanceChart) return

        const mergedData = {
            ...theData,
            portfolioPerformanceChart,
        }

        setData(mergedData)
    }, [portfolioPerformanceChart, theData])

    return theData
}

//------------------------------------------------------------------------------
export const usePortfolioDownloadChartUrl = (slug) => {
    const url = useDownloadChartApi(slug)
    return url
}

export const usePortfolioDownloadAllocUrl = (slug) => {
    const url = useDownloadAllocApi(slug)
    return url
}

//==============================================================================
// end of file
