//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/table
// Description: Data table.
// Created:     FUB, May 05, 2022
//==============================================================================

import React, {useState, useEffect} from "react"
import { FaSort } from "react-icons/fa"

//------------------------------------------------------------------------------
// debug stuff

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`TABLE: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`TABLE: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
export const TableSimple = ({ data, onHeaderClick }) => {
    const sort = onHeaderClick ? <>&nbsp;<FaSort/></> : null;

    return data ? (<table>
        <thead><tr>
            {data.columns.map((item, index) => (
                <th 
                    key={index}
                    onClick={onHeaderClick ? () => onHeaderClick(item) : null}
                    style={onHeaderClick ? {cursor: "pointer"} : null}
                >
                    {item}{sort}
                </th>
            ))}
        </tr></thead>
        <tbody>
            {data[data.columns[0]].map((item, index) => (
                <tr key={index}>
                    {data.columns.map((item2, index2) => (
                        <td key={index2}>{data[item2][index]}</td>
                    ))}
                </tr>
            ))}
        </tbody>
    </table>) : null
}

export const TableSortable = ({ data, sort }) => {
    const [sortedData, setSortedData] = useState(null)
    const [sortCol, setSortCol] = useState(sort[sort.defaultColumn].sortBy ?? sort.defaultColumn)
    const [sortNum, setSortNum] = useState(!sort[sort.defaultColumn].alpha)
    const [sortAsc, setSortAsc] = useState(sort[sort.defaultColumn].ascending)

    const onChangeSort = (column) => {
        const sortBy = sort[column].sortBy ?? column

        if (sortBy === sortCol) {
            setSortAsc(!sortAsc)
        } else {
            setSortCol(sortBy)
            setSortNum(!sort[column].alpha)
            setSortAsc(sort[column].ascending)
        }
    }
    DEBUG_MSG(`col=${sortCol} num=${sortNum} asc=${sortAsc}`)

    useEffect(() => {
        if (!data || !sort) return

        const indices = data[data.columns[0]].map((item, index) => index)
        indices.sort((a, b) => {
            if (sortNum === true) {
                const valA = parseFloat(data[sortCol][a])
                const valB = parseFloat(data[sortCol][b])
                const cmp = valA > valB ? 1 :
                    (valA < valB ? -1 : 0)
                return sortAsc ? cmp : -cmp
            } else {
                const valA = data[sortCol][a]
                const valB = data[sortCol][b]
                const cmp = valA > valB ? 1 :
                    (valA < valB ? -1 : 0)
                return sortAsc ? cmp : -cmp
            }
        })

        let tmpData = {
            columns: data.columns,
        }
        data.columns.forEach((item, index) => {
            const sortedCol = indices.map(i => data[item][i])
            tmpData[item] = sortedCol
        })

        setSortedData(tmpData)
    }, [data, sort, sortCol, sortNum, sortAsc])

    return (<TableSimple data={sortedData} onHeaderClick={onChangeSort}/>)
}

//==============================================================================
// end of file
