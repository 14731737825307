//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/seo.js
// Description: SEO primitive.
// Created:     FUB, May 06, 2022
//==============================================================================

import React from "react"
import Helmet from "react-helmet"

// use https://metatags.io/ to create & validate the meta data
// see https://jsonld.com/website/ and https://jsonld.com/web-page/ for examples re/ JSON-LD
// use https://technicalseo.com/tools/schema-markup-generator/ to create JSON-LD
// use https://validator.schema.org/ to validate JSON-LD

export const Seo = ({ title, description, location }) => {
    const lang = "en-US"
    const metaTitle = (title ?? "Profit More, Risk Less") + " - TuringTrader"
    const image = "https://www.turingtrader.com/brand/logo+text-1200x627.png"
    const url = location?.href ?? "https://www.turingtrader.com/"

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={metaTitle}
            meta={[
                //----- HTML meta tags
                {
                    name: "robots",
                    content: "noindex,follow",
                },
                {
                    name: `description`,
                    content: description,
                },

                //----- Google meta tags -- what are these?
                /*{
                    itemprop: `name`,
                    content: metaTitle,
                }, {
                    itemprop: `description`,
                    content: description,
                }, {
                    itemprop: `image`,
                    content: image,
                },*/

                //----- Open Graph / Facebook
                {
                    property: `og:title`,
                    content: title,
                }, {
                    property: `og:description`,
                    content: description,
                }, {
                    property: `og:image`,
                    content: image,
                    // https://www.linkedin.com/help/linkedin/answer/46687
                    // linkedin does not pick up the image, unless given the 'name' tag
                    name: `image`,
                }, {
                    property: `og:type`,
                    content: `website`,
                }, {
                    property: `og:url`,
                    content: url,
                }, {
                    property: `og:locale`,
                    content: `en_US`,
                }, {
                    property: `og:site_name`,
                    content: `TuringTrader`,
                },

                //----- Twitter
                {
                    name: `twitter:title`,
                    content: title,
                }, {
                    name: `twitter:description`,
                    content: description,
                }, {
                    name: `twitter:image`,
                    content: image,
                }, {
                    name: `twitter:card`,
                    content: `summary`, // how about summary_large_image?
                }, {
                    name: `twitter:creator`,
                    content: `Bertram Enterprises LLC`
                }, {
                    name: `twitter:url`,
                    content: url,
                }
            ]}
        >

            {/* TODO: turn JSON+LD into graph, see https://logical-invest.com */}

            {/*----- JSON LD: WebSite, see https://schema.org/WebSite */}
            <script type="application/ld+json">{JSON.stringify({
                "@context": "https://schema.org",
                "@type": "WebSite",
                name: "TuringTrader.com",
                url: "https://www.turingtrader.com",
                image: image,
                sameAs: [
                    "https://www.facebook.com/turingtrader/",
                    "https://www.youtube.com/channel/UCcEpocSCQklPPw6-ybCkSfw",
                    "https://www.turingtrader.org/",
                    "https://github.com/fbertram/TuringTrader/",
                    "https://www.linkedin.com/in/felixbertram/",
                ],
            })}</script>

            {/*----- JSON LD: WebPage, see https://schema.org/WebPage */}
            <script type="application/ld+json">{JSON.stringify({
                "@context": "https://schema.org",
                "@type": "WebPage",
                name: title,
                description: description,
                url: url,
            })}</script>

            {/*----- JSON LD: Organization, see https://schema.org/Organization */}
            <script type="application/ld+json">{JSON.stringify({
                "@context": "https://schema.org",
                "@type": "Organization",
                brand: "TuringTrader",
                description: "Tactical Portfolios for DIY Investors",
                email: "info@turingtrader.com",
                founder: "Felix Bertram",
                legalName: "Bertram Enterprises LLC (dba TuringTrader.com)",
                logo: image,
                url: "https://www.turingtrader.com",
            })}</script>

            {/*----- JSON LD: Person, see https://schema.org/Person */}
            <script type="application/ld+json">{JSON.stringify({
                "@context": "https://schema.org",
                "@type": "Person",
                givenName: "Felix",
                additionalName: "Uwe",
                familyName: "Bertram",
                jobTitle: "Founder",
                worksFor: "Bertram Enterprises LLC",
                url: "https://www.linkedin.com/in/felixbertram/",
            })}</script>
        </Helmet>
    )
}

//==============================================================================
// end of file
