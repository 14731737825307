//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/accordion
// Description: Accordion widget.
// History:     2022 May 09, FUB, created
//==============================================================================

// Visible, non-interactive elements with click handlers must have at least one keyboard listener
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Static HTML elements with event handlers require a role
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from "react"
import styled from "styled-components"
import { FaChevronRight, FaChevronDown } from "react-icons/fa"

//------------------------------------------------------------------------------
/*const AccordionStyle = styled.div`
    h2 {
        &:before {
        }
    }
    > * {
        &:first-child {
            &:before {
                content: ${props => props.isOpen === true ? "'- '" : "'+ '"};
            }
        }
        &:not(:first-child) {
            display: ${props => props.isOpen === true ? "block" : "none"};
            margin-left: ${props => props.theme.spacings.normal};
        }
    }  
 `*/

const Heading = styled.h2`
    cursor: pointer;
    /*margin-top: 1.5rem;*/
    font-weight: bold;
    color: ${props => props.theme.colors.active };
    &:hover {
        text-decoration: underline;
    }
`

const Toggle = styled.div`
    max-height: ${props => props.isVisible ? "5000px" : "0"};
    opacity: ${props => props.isVisible ? "1" : "0"};
    border-left: ${props =>props.theme.dimensions.lineThickness} solid ${props => props.theme.colors.decorative};
    overflow-y: hidden;
    margin-left: 0.7rem;
    padding-left: ${props => props.theme.spacings.normal};
    transition: all 0.5s ease;
`

//------------------------------------------------------------------------------
export const Accordion = ({ title, children }) => {
    const [isOpen, setOpen] = useState(false)
    const icon = isOpen === true ? (<FaChevronDown/> ) : (<FaChevronRight/>)

    return (<>
        <Heading onClick={() => setOpen(!isOpen)}>
            {icon}&nbsp;{title}
        </Heading>
        <Toggle isVisible={isOpen}>
            {children}
        </Toggle>
    </>)
}

 //==============================================================================
 // end of file
