//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/portfolio/account-wiz
// Description: New account wizard.
// Created:     FUB, May 24, 2022
//==============================================================================

import React, { useState } from "react"
import { navigate } from "gatsby"

import { ButtonPrimary } from "../button"
import { Popup } from "../popup"
import { SignUpButton } from "../sign-up-button"
import { usePortfolioData } from "../../../utils/portfolio-data"
import { useMemberdata, useMembership } from "../../../utils/member-data"
import { getPortfolioBase } from "../../../utils/portfolio-list"
//import { formatCurrency, cleanCurrency } from "../../../utils/format-helpers"

//------------------------------------------------------------------------------
// debug stuff

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`ACCOUNT-WIZ: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`ACCOUNT-WIZ: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
const NeedsBasic = ({ setStep }) => (
    <Popup title="Amost There..." onCancel={setStep}>
        <p>
            You can invest in this portfolio with
            our <strong>free Basic membership</strong>.
        </p>
        <SignUpButton free={true} />
    </Popup>
)

const NeedsTrial = ({ setStep }) => (
    <Popup title="Almost There..." onCancel={setStep}>
        <p>
            You can try this portfolio with
            our <strong>14-day free trial</strong>.
        </p>
        <SignUpButton />
    </Popup>
)

const NeedsPremium = ({ setStep }) => (
    <Popup title="Almost There..." onCancel={setStep}>
        <p>
            To invest in this portfolio, you need to upgrade to
            our <strong>Premium membership</strong>.
        </p>
        <SignUpButton />
    </Popup>
)

//------------------------------------------------------------------------------
const Step1 = ({ setStep, accountName, setAccountName }) => {
    const membership = useMembership()
    const next = () => setStep(2)

    return (
        <Popup title="Create Account (1/2)" onCancel={setStep}>
            <p>
                Please enter the name of the account you will be using
                for this investment:
            </p>
            <p>
                <input
                    type="text"
                    autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                    value={accountName}
                    onChange={(event) => setAccountName(event.target.value)}
                    key="account-name"
                />
            </p>
            <p><small><em>
                Choose a name that helps you identify your investments, especially
                when you are planning to invest in more than one portfolio or account.
                An example for a good nickname is <em>{membership?.user?.firstName}'s IRA Account</em>.
            </em></small></p>

            <ButtonPrimary
                text="Next"
                onClick={next}
            />
        </Popup>
    )
}

const Step2 = ({ setStep, portfolio, accountName, accountValue, setAccountValue }) => {
    const [memberdata, setMemberdata] = useMemberdata()
    const portfolioData = usePortfolioData(portfolio)
    const portfolioMetrics = portfolioData?.metricsTable
    const portfolioNav = portfolioMetrics?.[portfolioMetrics?.columns[1]]?.[1]
        ?.replace("$", "")
        ?.replace(",", "")
    DEBUG_MSG(`portfolioNav=${portfolioNav}`)

    const next = () => {
        const accounts = {
            list: [],
            ...memberdata.accounts,
        }

        const id = [...Array(100).keys()]
            .filter(id => !accounts.list.includes(id))
            ?.[0]

        const name = accountName.length > 1 ?
            accountName :
            `My Account #${id + 1}`
        const dollarValue = parseFloat(accountValue) > 1 ? parseFloat(accountValue) : 25000
        const value = dollarValue / portfolioNav
        DEBUG_MSG(`dollarValue=${dollarValue}, portfolioNav=${portfolioNav}, value=${value}`)

        const newAccount = {
            name,
            value,
            portfolio: getPortfolioBase(portfolio),
            holdings: "x",
        }
        accounts[id] = newAccount
        accounts.list.push(id)

        DEBUG_MSG(`create account: id=${id}, account=${JSON.stringify(newAccount)}`)
        setMemberdata({ accounts: accounts })
        setStep()

        //navigate("/dashboard/rebalance/?id=${id}")
        //navigate("/dashboard/")
        setTimeout(() => navigate("/dashboard/"), 1000);
    }

    return portfolioMetrics ? (
        <Popup title="Create Account (2/2)" onCancel={setStep}>
            <p>
                Please enter the amount of capital you will be allocating
                to this investment:
            </p>
            <p>
                <input
                    type="number"
                    autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                    value={accountValue}
                    onChange={(event) => setAccountValue(event.target.value)}
                /> US$
            </p>
            <p><small><em>
                We need to know your account's value, so that we can
                calculate the exact number of shares to hold for you.
            </em></small></p>

            <ButtonPrimary 
                text="Next"
                onClick={next}
            />
        </Popup>
    ) : null
}

//------------------------------------------------------------------------------
export const AccountWiz = ({ portfolio, access, onCancel }) => {
    const [step, setStep] = useState(1)
    const [accountName, setAccountName] = useState("")
    const [accountValue, setAccountValue] = useState("")

    const setResetStep = (n) => {
        if (n) {
            setStep(n)
        } else {
            setStep(1)
            onCancel()
        }
    }

    return (<>
        {step === 1 && access?.needsBasic && (<NeedsBasic setStep={setResetStep} />)}
        {step === 1 && access?.needsTrial && (<NeedsTrial setStep={setResetStep} />)}
        {step === 1 && access?.needsPremium && (<NeedsPremium setStep={setResetStep} />)}

        {step === 1 && access?.hasAccess && (<Step1
            setStep={setResetStep}
            accountName={accountName}
            setAccountName={setAccountName}
        />)}
        {step === 2 && (<Step2
            setStep={setResetStep}
            portfolio={portfolio}
            accountName={accountName}
            accountValue={accountValue}
            setAccountValue={setAccountValue}
        />)}
    </>)
}

//==============================================================================
// end of file
