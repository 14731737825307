//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/help-popup
// Description: Accordion widget.
// History:     2022 May 09, FUB, created
//==============================================================================

 // Visible, non-interactive elements with click handlers must have at least one keyboard listener
 /* eslint-disable jsx-a11y/click-events-have-key-events */
 // Static HTML elements with event handlers require a role
 /* eslint-disable jsx-a11y/no-static-element-interactions */

 import React, { useState } from "react"
//import styled from "styled-components"

import { Popup } from "./popup"
import { Link } from "./link"

//------------------------------------------------------------------------------
export const HelpPopup = ({ title, children }) => {
    const [isVisible, setVisible] = useState(false)

    return (<>
        <span onClick={() => setVisible(true)}><Link>{title}</Link></span>
        {isVisible === true && (
            <Popup title={title} onCancel={() => setVisible(false)}>
                {children}
            </Popup>
        )}
    </>)
}

//==============================================================================
// end of file
