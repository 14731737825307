//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/layout/global-styles.js
// Description: Global layout styles.
// Created:     FUB, Apr 16, 2022
//==============================================================================

import { createGlobalStyle } from "styled-components"
//import "@fontsource/open-sans" // Defaults to weight 400.
//import "@fontsource/open-sans/400.css" // default weight
//import "@fontsource/open-sans/600.css" // headers
//import "@fontsource/open-sans/700.css" // bold
import "@fontsource/open-sans/variable.css" // variable weight
import "@fontsource/open-sans/variable-italic.css" // italic variant

// global typography taken from https://type-scale.com/
// with the following settings:
// - Open Sans 600
// - body font same as headings
// - body weight 400
// - 16px base size
// - 1.125 scale for better readability on mobile
// also see https://www.gridlover.net/
// and https://www.hongkiat.com/blog/web-typography-tools/

// fluid typography taken from https://websemantics.uk/tools/responsive-font-calculator/
// with the following settings:
// - px
// - :root
// - font-size
// - 12 to 16 px
// - 280 to 800 px
// - end query
// - safari fix
// - switch back to rem

// NOTE: we are importing the font above

// NOTE: we changed the font-face from 'Open Sans' to 'Open SansVariable'
//       see https://fontsource.org/docs/variable-fonts
//       we do not check for support for variable fonts. Instead,
//       we fall back to the browser's sans-serif font

//------------------------------------------------------------------------------
export const GlobalStyles = createGlobalStyle`
/*html {font-size: 100%;} /*16px*/

@media (min-width: 17.5em) {
  :root {
    font-size: calc(0.75rem + ((1vw - 0.175rem) * 0.7692));
    min-height: 0vw;
  }
}
@media (min-width: 50em) {
  :root {
    font-size: 1rem;
  }
}

body {
  background: white;
  font-family: 'Open SansVariable', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
}

p {margin-bottom: 1rem;}

h1, h2, h3, h4, h5 {
  margin: 3rem 0 1.38rem;
  font-family: 'Open SansVariable', sans-serif;
  font-weight: 600;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 1.802rem;
}

h2 {font-size: 1.602rem;}

h3 {font-size: 1.424rem;}

h4 {font-size: 1.266rem;}

h5 {font-size: 1.125rem;}

small, .text_small {font-size: 0.889rem;}

/*----- FUB overrides -----*/
body {
  margin: 0;
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.bgWindow};
}
h1 {
  margin-left: -${props => props.theme.spacings.normal};
  margin-right: -${props => props.theme.spacings.normal};
  padding-left: ${props => props.theme.spacings.normal};
  padding-right: ${props => props.theme.spacings.normal};
  background: ${props => props.theme.gradients.light};
}
h2, h3, p {&:first-child {
  margin-top: 0;
}}

h1, h2, h3 {
  color: ${props => props.theme.colors.accent};
}

a {
  color: ${props => props.theme.colors.active};
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  &:hover {
      text-decoration: underline;
  }
}

blockquote {
  color: ${props => props.theme.colors.accent};
  font-style: italic;
}

ul {
  list-style-type: disc;
}

table {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1.0em;
  padding-bottom: 1.5em;
  width: 100%;
  font-size: 0.889rem;
}
thead {}
tbody {}
tr {
  &:hover {
    background: ${props => props.theme.gradients.light};
  }
}
td {
  border-bottom: dotted 1px lightgrey;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
th {
  color: ${props => props.theme.colors.accent};
  border-bottom: solid 1px ${props => props.theme.colors.decorative};
  text-align: left;
  font-weight: 600;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

input {
  color: ${props => props.theme.colors.active};
  font-weight: 600;
  border-bottom: solid 1px ${props => props.theme.colors.active};
  border-top: none;
  border-left: none;
  border-right: none;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: none;
    border-bottom: solid 3px ${props => props.theme.colors.active};
    border-top: none;
    border-left: none;
    border-right: none;
    }
}
`

//==============================================================================
// end of file
