//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/annual-returns
// Description: Widget to show annual returns
//              see https://codesandbox.io/s/github/reactchartjs/react-chartjs-2/tree/master/sandboxes/bar/vertical?from-embed
// Created:     FUB, July 20, 2022
//==============================================================================

import React, { useState, useEffect, useMemo } from "react"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { theme } from "../layout/theme"
import { formatPercent } from "../../utils/format-helpers"

//------------------------------------------------------------------------------
// debug stuff

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`ANNUAL-CHART: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`ANNUAL-CHART: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
export const useAnnualReturns = (data) => {
    const [theAnnualChart, setAnnualChart] = useState()

    useEffect(() => {
        if (!data) return null

        const dates = data[data.columns[0]]
        const navs = data[data.columns[1]]
        const benchs = data[data.columns[2]]

        let datePrev = new Date(dates[0])
        let navPrev = navs[0]
        let benchPrev = benchs[0]

        let years = []
        let portfReturns = []
        let benchReturns = []

        dates.forEach((dateStr, idx) => {
            const date = new Date(dateStr)

            if (datePrev.getYear() !== date.getYear() || idx === dates.length - 1) {
                //DEBUG_MSG(`date=${date}, prev=${datePrev}`)

                // we get here on the first of the new year
                // let's use the last of the prev year (full years only)
                const nav = idx !== dates.length - 1
                    ? navs[Math.max(0, idx - 1)]
                    : navs[idx]
                const bench = idx !== dates.length - 1
                    ? benchs[Math.max(0, idx - 1)]
                    : benchs[idx]

                years.push(1900 + datePrev.getYear())
                portfReturns.push((100 * (nav / navPrev - 1)).toFixed(2))
                benchReturns.push((100 * (bench / benchPrev - 1)).toFixed(2))

                datePrev = date
                navPrev = nav
                benchPrev = bench
            }
        })

        const table = {
            columns: ["Year", data.columns[1], data.columns[2]],
            "Year": years,
            [data.columns[1]]: portfReturns,
            [data.columns[2]]: benchReturns,
        }

        setAnnualChart(table)
    }, [data])

    return theAnnualChart
}

//------------------------------------------------------------------------------
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const AnnualReturns = ({ data }) => {

    //----- chart default data
    const defaultChartData = useMemo(() => ({
        labels: [2020, 2021],
        datasets: [{
            label: "Portfolio",
            data: [33, 40],
            borderColor: theme.colors.blue.base,
            backgroundColor: theme.colors.blue.base,
        }, {
            label: "Benchmark",
            data: [18, 29],
            borderColor: theme.colors.green.base,
            backgroundColor: 'white', //theme.colors.green.base,
            borderWidth: 2, //theme.dimensions.lineThickness,
            borderSkipped: false,
        }]
    }), [])

    //----- prepare chart data
    const [theChartData, setChartData] = useState(defaultChartData)
    useEffect(() => {
        if (!data) return

        const chartData = {
            ...defaultChartData,
            labels: data.Year,
            datasets: [{
                ...defaultChartData.datasets[0],
                label: data.columns[1],
                data: data[data.columns[1]],
            }, {
                ...defaultChartData.datasets[1],
                label: data.columns[2],
                data: data[data.columns[2]],
            }]
        }

        setChartData(chartData)
    }, [data, defaultChartData])

    //----- prepare chart options
    const [theChartOptions, setChartOptions] = useState()
    useEffect(() => {
        if (!data) return

        const chartOptions = {
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 1.78,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                x: {
                    grid: {
                        borderColor: theme.colors.decorative,
                    },
                },
                y: {
                    position: 'right',
                    ticks: {
                        // see https://www.chartjs.org/docs/latest/axes/labelling.html
                        callback: (value, index, ticks) => formatPercent(value, 0),
                    },
                    grid: {
                        borderColor: theme.colors.decorative,
                    },
                },
            },
            plugins: {
                title: {
                    display: true,
                    text: 'Annual Returns',
                },
                legend: {
                    position: 'top',
                },
                tooltip: {
                    callbacks: {
                        label: context => {
                            //const xLabel = context.label
                            //const yValue = context.parsed.y
                            const yValue = context.raw
                            const dataLabel = context.dataset.label
                            //const dataIndex = context.datasetIndex

                            return `${dataLabel}: ${yValue}%`

                        }
                    }
                },
            },
            animation: false, // see https://www.chartjs.org/docs/latest/configuration/animations.html
            animations: {
                colors: false,
                x: false,
            },
            transitions: {
                active: {
                    animation: {
                        duration: 0,
                    }
                }
            },
        }

        //DEBUG_MSG(`chartOptions=${JSON.stringify(chartOptions)}`)
        setChartOptions(chartOptions)
    }, [data])

    //----- render chart
    // FIXME: this looks convoluted. can we simplify the nested divs somehow?
    return (
        <div css={`position:relative;`}>
            <div css={`display:inline-block;width:0px;padding-bottom:56%;`} />
            <div css={`position:absolute;top:0;left:0;width:100%;height:100%;`}>
                <div css={`position:relative;`}>
                    {theChartOptions && theChartData && (
                        <Bar options={theChartOptions} data={theChartData} />
                    )}
                </div>
            </div>
        </div>
    )
}

//==============================================================================
// end of file
