//==============================================================================
// Project:     www.TuringTrader.com
// Name:        utils/account-data
// Description: Access to user's investment accounts.
// History:     2022viiii23, FUB, created
//==============================================================================

import React, { createContext, useContext, useState, useEffect } from "react"

import { useJsonFile, useBacktestApi } from "./firebase"
import { formatCurrency, formatPercent } from "./format-helpers"
import { useMemberdata, useMembership } from "./member-data"
import { getPortfolioBase, usePortfolioList } from "./portfolio-list"

//------------------------------------------------------------------------------
const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`ACCOUNT-DATA: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`ACCOUNT-DATA: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
// * * *   I n t e r n a l   h o o k s   * * * * * * * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

/**
 * Hook to provide basic information for all portfolios.
 * 
 * The object only contains information for the latest release of any
 * portfolio or strategy. This is useful for the portfolio wizard and the
 * investor's dashboard. The object returned has the following format:
 * 
 *     {
 *         slug: ["tt-all-stars-leveraged", "tt-stocks-on-the-loose", ...],
 *         name: ["All-Stars Leveraged","Stocks on the Loose", ...],
 *         author: ["TuringTrader's", "TuringTrader's", ...],
 *         cagr: [...],
 *         cagrMin: [[], [], ...],
 *         mdd: [...],
 *         martin: [...],
 *         navLast: [...],
 *         dateLast: [...],
 *         rebalLast: [...],
 *         updatedLast: [...],
 *     }
 * @param {*} refresh 
 * @returns 
 */
const usePortfolioOverviewRaw = (refresh = 60 * 60 * 1000) => {
    const [thePortfolioMetrics, setPortfolioMetrics] = useState()

    const portfolioList = usePortfolioList()
    const allMetrics = useJsonFile(null, "portfolio-comp.json", refresh)
    const allRebals = useJsonFile(null, "portfolio-rebal.json", refresh)

    useEffect(() => {
        if (!allMetrics || !allRebals) return

        /*
            allMetrics = {
                "portfolio": ["TT's All-Stars Leveraged v2"],
                "slug": ["tt-all-stars-lev-v2", ...],
                "cagr-1w": [],
                "cagr-1m": [],
                "cagr-3m": [],
                "cagr-1y": [],
                "cagr-2y": [],
                "cagr-5y": [],
                "cagr-10y": [],
                "cagr-max": [],
                "stdev": [],
                "mdd": [],
                "ulcer": [],
                "sharpe": [],
                "martin": [],
            }
        */
        /*
             allRebals = {
                 "tt-all-stars-tax-v2": "09/16/2022",
                 "tt-all-stars-monthly-v2": "09/14/2022",
                 "tt-all-stars-stocks-v2": "09/14/2022",
             }
        */

        const portfolioMetrics = portfolioList.reduce(
            (acc, pfInfo) => {
                const metricsIdx = allMetrics.slug.indexOf(pfInfo.slug2)
                return ({
                    slug: [...acc.slug, pfInfo.slug],
                    slug2: [...acc.slug2, pfInfo.slug2],
                    title: [...acc.title, pfInfo.title],
                    author: [...acc.author, pfInfo.author],
                    tags: [...acc.tags, pfInfo.tags],
                    cagr: [...acc.cagr, allMetrics["cagr-max"][metricsIdx]],
                    cagr1wk: [...acc.cagr1wk, allMetrics["cagr-1w"][metricsIdx]],
                    cagr1mo: [...acc.cagr1mo, allMetrics["cagr-1m"][metricsIdx]],
                    cagr3mo: [...acc.cagr3mo, allMetrics["cagr-3m"][metricsIdx]],
                    cagr1yr: [...acc.cagr1yr, allMetrics["cagr-1y"][metricsIdx]],
                    //cagrMin: [...acc.cagrMin, (function () { try { return JSON.parse(allMetrics?.["cagr-5th"]?.[metricsIdx]) } catch (err) { return null } })()],
                    mdd: [...acc.mdd, allMetrics["mdd"][metricsIdx]],
                    martin: [...acc.martin, allMetrics["martin"][metricsIdx]],
                    dateLast: [...acc.dateLast, allMetrics["date-end"][metricsIdx]],
                    navLast: [...acc.navLast, allMetrics["nav-end"][metricsIdx]],
                    rebalLast: [...acc.rebalLast, allRebals[pfInfo.slug2]],
                    updatedLast: [...acc.updatedLast, allMetrics["updated"][metricsIdx]],
                })
            },
            {
                slug: [],
                slug2: [],
                title: [],
                author: [],
                tags: [],
                cagr: [],
                cagr1wk: [],
                cagr1mo: [],
                cagr3mo: [],
                cagr1yr: [],
                cagrMin: [],
                mdd: [],
                martin: [],
                dateLast: [],
                navLast: [],
                rebalLast: [],
                updatedLast: [],
            }
        )

        setPortfolioMetrics(portfolioMetrics)
        //DEBUG_MSG(`usePortfolioOverview=${JSON.stringify(portfolioMetrics)}`)
    }, [allMetrics, allRebals, portfolioList])

    return thePortfolioMetrics
}

/**
 * Hook to retrieve portfolio asset allocation.
 * 
 * The object returned has the following format:
 * 
 *     {
 *         XLE: {
 *             name: "S&P Energy Select Sector ETF",
 *             allocation: 0.10,
 *             price: 95.80,
 *         },
 *         "---": {
 *             name: "Idle Cash",
 *             allocation: 0.90,
 *             price: null,
 *         }
 *     }
 * 
 * @param {*} portfolio 
 * @returns 
 */
const usePortfolioAllocRaw = (portfolio) => {
    const [theAlloc, setAlloc] = useState()

    const rawAllocation = useJsonFile(portfolio, "portfolio-holdings.json")
    useEffect(() => {
        if (!portfolio)
            return

        if (!rawAllocation)
            return

        // table format received:
        /* const rawAllocation = [
            ["Symbol","Name","Allocation","Price"],
            ["AGG","<a href='https://...' target='_blank' rel='noopener noreferrer'>iShares Core US Aggregate Bond ETF</a>","32.63%","102"],
            ["SPYV","<a href='https://...' target='_blank' rel='noopener noreferrer'>SPDR Portfolio S&P 500 Value ETF</a>","17.16%","40.029998779296875"],
            ["XLV","<a href='https://...' target='_blank' rel='noopener noreferrer'>Health Care Select Sector SPDR ETF</a>","17.01%","130.19000244140625"],
            ["BIL","<a href='https://...' target='_blank' rel='noopener noreferrer'>SPDR Bloomberg 1-3 Month T-Bill ETF</a>","13.22%","91.44000244140625"],
            ["IEF","<a href='https://....' target='_blank' rel='noopener noreferrer'>iShares 7-10 Year Treasury Bond ETF</a>","4.96%","101.66999816894531"],
            ["VIXY","<a href='https://...' target='_blank' rel='noopener noreferrer'>ProShares VIX Short-Term Futures ETF</a>","4.96%","20.450000762939453"],
            ["SPY","<a href='https://...' target='_blank' rel='noopener noreferrer'>SPDR S&P 500 Trust ETF</a>","4.96%","413.80999755859375"],
            ["TIP","<a href='https://...' target='_blank' rel='noopener noreferrer'>iShares TIPS Bond ETF</a>","1.65%","118.48999786376953"],
            ["GLD","<a href='https://...' target='_blank' rel='noopener noreferrer'>SPDR Gold Shares ETF</a>","1.65%","175.1300048828125"],
            ["DBC","<a href='https://...' target='_blank' rel='noopener noreferrer'>Invesco DB Commodity Index Tracking ETF</a>","1.65%","28.270000457763672"]
        ] */

        // format table as follows:
        /* const allocationTable = {
            columns: ["Symbol", "Name", "Allocation", "Price"],
            "Symbol":     ["row 1", "row 2", "row 3"],
            "Name":       ["row 1", "row 2", "row 3"],
            "Allocation": ["row 1", "row 2", "row 3"],
            "Price":      ["row 1", "row 2", "row 3"],
        } */

        const allocationTotal = rawAllocation ? rawAllocation
            .filter((item, index) => index > 0)
            .reduce((prev, item) => {
                const num = parseFloat(item[2])
                return isNaN(num) ? prev : (prev + num / 100)
            }, 0) : 1
        const allocationScale = Math.max(1, allocationTotal)

        const allocWithoutCash = rawAllocation
            .filter((item, index) => index > 0) // skip header row
            .reduce(
                (acc, item) => ({
                    portfolio: acc.portfolio,
                    allocation: {
                        ...acc.allocation,
                        [item[0]]: {
                            name: item[1].replaceAll(/<[^>]+>/g, ""),
                            allocation: ((isNaN(parseFloat(item[2])) ? 0 : parseFloat(item[2])) / 100 / allocationScale).toFixed(4),
                            price: parseFloat(item[3].replace(/[$,]/g, "")),
                        }
                    }
                }), { portfolio: portfolio, allocation: {} }
            )

        const allocWithCash = allocationTotal > 0.95 ?
            {
                ...allocWithoutCash
            } : {
                portfolio: allocWithoutCash.portfolio,
                allocation: {
                    ...allocWithoutCash.allocation,
                    "---": {
                        name: "Idle Cash",
                        allocation: (1 - allocationTotal).toFixed(4),
                        price: null,
                    }
                }
            }

        setAlloc(allocWithCash)
        //DEBUG_MSG(`usePortfolioAlloc(${portfolio})=${JSON.stringify(allocWithCash)}`)
    }, [rawAllocation, portfolio])

    return portfolio &&
        theAlloc?.portfolio === portfolio &&
        theAlloc.allocation
}

export const accountGroupUngrouped = "UnGrOuPeD"
export const accountGroupAllAccounts = "AlLAcCoUnTs"

const useAccountDataRaw = () => {
    const portfolioOverview = usePortfolioOverviewRaw()
    const [memberMeta, /*setMemberMeta*/] = useMemberdata()
    const membership = useMembership()
    const hasInfinite = membership?.features?.infinite?.hasAccess

    //---------- load asset allocation for all portfolios used by user's accounts
    const [thePortfolioSlug2, setPortfolioSlug2] = useState()
    const thePortfolioAlloc = usePortfolioAllocRaw(thePortfolioSlug2)
    const [thePortfolioAllocCache, setPortfolioAllocCache] = useState({ portfolios: [] })
    useEffect(() => {
        if (!portfolioOverview || !memberMeta)
            return

        if (thePortfolioSlug2 && thePortfolioAlloc) {
            // we received a new asset allocation
            const slug = getPortfolioBase(thePortfolioSlug2)
            const allocCache = {
                ...thePortfolioAllocCache,
                [slug]: thePortfolioAlloc,
            }

            setPortfolioAllocCache(allocCache)
            //DEBUG_MSG(`received alloc for slug2=${thePortfolioSlug2} => slug=${slug}`)
        }

        // note that allPortfolios might contain duplicates
        const allPortfolios = memberMeta?.accounts?.list
            ?.map(id => memberMeta.accounts[id].portfolio)
        //DEBUG_MSG(`allPortfolios=${JSON.stringify(allPortfolios)}`)

        const missingPortfolios = allPortfolios
            ?.filter(portfolio => !thePortfolioAllocCache.hasOwnProperty(portfolio))
        //DEBUG_MSG(`missingPortfolios=${JSON.stringify(missingPortfolios)}`)

        const portfolioToLoad = missingPortfolios?.[0]

        const slug2 = portfolioToLoad ?
            portfolioOverview.slug2[portfolioOverview.slug.indexOf(portfolioToLoad)] : null

        setPortfolioSlug2(slug2 ?? undefined) // must use undefined when finished!
        //DEBUG_MSG(`load alloc for slug2=${slug2}`)
    }, [portfolioOverview, memberMeta,
        thePortfolioSlug2, thePortfolioAlloc, thePortfolioAllocCache
    ])

    //---------- calculate the asset allocation for user's accounts
    const [theAccountAllocs, setAccountAllocs] = useState({ accounts: [] })
    useEffect(() => {
        if (!portfolioOverview || !memberMeta || !memberMeta.accounts)
            return

        //if (thePortfolioAllocCache.portfolios.length === 0)
        //    return

        const accountAllocs = memberMeta.accounts.list.reduce(
            (accAccountAllocs, accountIdx) => {
                const name = memberMeta.accounts[accountIdx].name
                const portfolio = memberMeta.accounts[accountIdx].portfolio
                const overviewIdx = portfolioOverview.slug.indexOf(portfolio)
                const accountTotal = memberMeta.accounts[accountIdx].value * portfolioOverview.navLast[overviewIdx]
                const portfolioAlloc = thePortfolioAllocCache[portfolio] ?? {}
                const accountHoldings = {
                    assets: [],
                    ...memberMeta.accounts[accountIdx].holdings2
                }
                // BUGBUG: it seems that accountHoldings?.assets can be undefined
                //         crash in the next line reported by Jon Hancock on Brave browser
                const allAssets = accountHoldings.assets.reduce(
                    (accAllAssets, ticker) => accAllAssets.includes(ticker) ? accAllAssets : [...accAllAssets, ticker],
                    Object.keys(portfolioAlloc)
                )
                //DEBUG_MSG(`accountIdx=${accountIdx}, portfolioAlloc=${JSON.stringify(portfolioAlloc)}`)
                //DEBUG_MSG(`accountIdx=${accountIdx}, allAssets=${JSON.stringify(allAssets)}`)
                //DEBUG_MSG(`accountIdx=${accountIdx}, accountHoldings=${JSON.stringify(accountHoldings)}`)
                const accountAlloc = allAssets?.reduce(
                    (accAccountAlloc, ticker) => ({
                        ...accAccountAlloc,
                        assets: [...accAccountAlloc.assets, ticker],
                        [ticker]: {
                            name: portfolioAlloc[ticker]?.name ?? accountHoldings[ticker]?.name,
                            allocation: portfolioAlloc[ticker]?.allocation ?? 0,
                            shares: portfolioAlloc[ticker] ? accountTotal * portfolioAlloc[ticker].allocation / portfolioAlloc[ticker].price : 0,
                        }
                    }),
                    {
                        name: name,
                        portfolio: portfolio,
                        value: accountTotal,
                        lastRebalanced: memberMeta.accounts[accountIdx].holdings ?? "n/a",
                        assets: [],
                    }
                )
                //DEBUG_MSG(`accountIdx=${accountIdx}, accountAlloc=${JSON.stringify(accountAlloc)}`)
                const accountDelta = allAssets?.reduce(
                    (accAccountDelta, ticker) => ({
                        ...accAccountDelta,
                        //needsRebalancing: accAccountDelta.needsRebalancing || (ticker !== "---" && Math.abs(accountAlloc[ticker].shares - (accountHoldings[ticker]?.shares ?? 0)) >= 1),
                        needsRebalancing: accAccountDelta.needsRebalancing || (ticker !== "---" &&
                            Math.abs(accountAlloc[ticker].shares -
                                (accountHoldings.assets.includes(ticker) ? accountHoldings[ticker]?.shares : 0)) >= 1),
                        [ticker]: {
                            ...accAccountDelta[ticker],
                            current: accountHoldings.assets.includes(ticker) ? accountHoldings[ticker]?.shares : 0,
                            order: accountAlloc[ticker].shares - (accountHoldings.assets.includes(ticker) ? accountHoldings[ticker]?.shares : 0),
                        }
                    }),
                    {
                        ...accountAlloc,
                        needsRebalancing: false,
                    }
                )

                return ({
                    ...accAccountAllocs,
                    [accountIdx]: accountDelta,
                })
            },
            {
                accounts: memberMeta.accounts.list,
            }
        )
        setAccountAllocs(accountAllocs)
        //DEBUG_MSG(`accountAllocs=${JSON.stringify(accountAllocs)}`)
    }, [thePortfolioAllocCache, portfolioOverview, memberMeta])

    //---------- calculate account grorups
    // TODO: implement this!
    const [theAccountGroups, setAccountGroups] = useState({ groups: [] })
    useEffect(() => {
        if (!memberMeta || !memberMeta.accounts || !memberMeta.accounts.list) return

        // split account names at @-sign and create preliminiary groups
        let groups0 = {}
        for (var idx of memberMeta.accounts.list) {
            var fullName = memberMeta.accounts[idx].name
            const groupName = hasInfinite ?
                fullName.match(/@\s*.*/)?.toString()?.replace(/@\s*/, "") ?? accountGroupUngrouped :
                accountGroupUngrouped

            groups0[groupName] = groups0[groupName] ?
                [...groups0[groupName], idx] :
                [idx]
        }

        // cleanup: remove groups of single accounts
        let groups = {}
        for (const group in groups0) {
            const toGroup = groups0[group].length > 1 ? group : accountGroupUngrouped

            for (const idx of groups0[group]) {
                groups[toGroup] = groups[toGroup] ?
                    [...groups[toGroup], idx] :
                    [idx]
            }
        }

        setAccountGroups(groups)
    }, [memberMeta, hasInfinite, portfolioOverview])

    //---------- combine components
    const [theAccountData, setAccountData] = useState()
    useEffect(() => {
        if (!portfolioOverview || !theAccountAllocs)
            return

        const accountData = {
            overview: portfolioOverview,
            accounts: theAccountAllocs,
            groups: theAccountGroups,
        }
        setAccountData(accountData)
        //DEBUG_MSG(`accountData=${JSON.stringify(accountData)}`)
    }, [portfolioOverview, theAccountAllocs, theAccountGroups])
    return theAccountData
}

//------------------------------------------------------------------------------
// * * *   C o n t e x t   P r o v i d e r   * * * * * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

export const AccountDataContext = createContext(null)

export const AccountDataProvider = ({ children }) => {
    const accountData = useAccountDataRaw()

    return (
        <AccountDataContext.Provider value={accountData}>
            {children}
        </AccountDataContext.Provider>
    )
}

//------------------------------------------------------------------------------
// * * *   P o r t f o l i o   i n f o   h o o k s   * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

export const usePortfolioOverview = () => {
    const accountData = useContext(AccountDataContext)
    return accountData && accountData?.overview
}

export const usePortfolioInfo = (slug) => {
    const overview = usePortfolioOverview()

    const [theInfo, setInfo] = useState()
    useEffect(() => {
        const idx = overview?.slug?.indexOf(slug)
        setInfo(overview && Object.keys(overview).reduce(
            (acc, prop) => ({ ...acc, [prop]: overview[prop][idx] }),
            {}
        ))
    }, [slug, overview])

    return theInfo
}

//------------------------------------------------------------------------------
// * * *   A c c o u n t   d a t a   h o o k s   * * * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

/**
 * Hook providing info regarding all accounts, their holdings, and pending orders.
 * 
 *     {
 *             "accounts": [2],
 *             "2": {
 *                 "name": "My Account #1",
 *                 "portfolio": "tt-all-stars-xl",
 *                 "value": 49724.37359890488,
 *                 "lastRebalanced": "10/04/2022",
 *                 "assets": ["BIL"],
 *                 "BIL": {
 *                     "name": "SPDR Bloomberg 1-3 Month T-Bill ETF",
 *                     "allocation": "0.5265",
 *                     "shares": 286.21277304517974,
 *                     "current": 190.60231021970262,
 *                     "order": 95.61046282547713
 *             },
 *     }
 * @returns object w/ account info
 */
export const useAccountData = () => {
    const accountData = useContext(AccountDataContext)
    return accountData && accountData.accounts
}

/**
 * Hook providing list with names of account groups.
 * 
 * The returned list has the following format and includes
 * an element to represent ungrouped accounts:
 * 
 *     [ "my group #1", "my group #2",  accountGroupUngrouped]
 * 
 * @returns list of group names
 */
export const useAccountGroupList = () => {
    const accountData = useContext(AccountDataContext)
    return accountData && Object.keys(accountData?.groups)
    //.filter(group => group !== accountGroupUngrouped)
}


//------------------------------------------------------------------------------

/**
 * Hook providing list of account indices for a given group.
 * 
 * As input parameters, the hook accepts any of the account group names
 * returned by useAccountGroupList. In addition, two special names are
 * supported: accountGroupUngrouped and accountGroupAllAccounts. The
 * returned list has the following format:
 * 
 *     [ 0, 8, 1, 5]
 * 
 * @param {*} groupName name of account group 
 * @returns list of account indices
 */
export const useAccountGroupIndices = (groupName = accountGroupAllAccounts) => {
    const accountData = useContext(AccountDataContext)
    return groupName !== accountGroupAllAccounts ?
        accountData.groups[groupName] :
        accountData?.accounts && accountData.accounts.accounts
}

//------------------------------------------------------------------------------

/**
 * Hook returning all info required to interact with a group of accounts.
 * 
 * The returned object has the following format:
 * 
 *     {
 *         name: "My Mean Kitty",
 *         portfolio: "tt-mean-kitty",
 *         totalValue: 1000,
 *         return3mo: +0.85, // in %
 *         rebalScheduled: "09/23/2022",
 *         rebalConfirmed: "09/23/2022",
 *         needsRebalancing: false,
 *         assets: ["XLE", "SPY"],
 *         XLE: {
 *             name: "Energy Select SPDR Sector ETF",
 *             allocation: 0.18,
 *             shares: 2.5,
 *             current: 2.4,
 *             delta: 0.1,
 *         },
 *     }
 * 
 * @param {*} ids array with account indices
 * @returns object with account info
 */
export const useAccountGroupInfo = (ids) => {
    const accountData = useContext(AccountDataContext)
    const [memberMeta, setMemberMeta] = useMemberdata()

    const [theGroupInfo, setGroupInfo] = useState({
        // FIXME: we'd rather start with an undefined value here
        name: "",
        portfolio: "",
        value: 0,
    })
    useEffect(() => {
        if (!ids || !accountData || !accountData.accounts) return

        const totalValue = ids.reduce((acc, id) => acc + accountData.accounts[id]?.value, 0)
        const info = ids.reduce(
            (accAccounts, id) => accountData.accounts[id]?.assets.reduce(
                (accAssets, ticker) => ({
                    ...accAssets,
                    assets: accAssets.assets.includes(ticker) ? accAssets.assets : [...accAssets.assets, ticker],
                    [ticker]: {
                        ...accAssets[ticker],
                        name: accAssets[ticker]?.name ?? accountData.accounts[id][ticker].name,
                        allocation: (accAssets[ticker]?.allocation ?? 0) + accountData.accounts[id].value / totalValue * accountData.accounts[id][ticker].allocation,
                        shares: (accAssets[ticker]?.shares ?? 0) + accountData.accounts[id][ticker].shares,
                        current: (accAssets[ticker]?.current ?? 0) + accountData.accounts[id][ticker].current,
                        order: (accAssets[ticker]?.order ?? 0) + accountData.accounts[id][ticker].order,
                    }
                }),
                {
                    ...accAccounts,
                    value: accAccounts.value + accountData.accounts[id].value,
                    needsRebalancing: accAccounts.needsRebalancing || accountData.accounts[id].needsRebalancing,
                    portfolios: accAccounts.portfolios.includes(accountData.accounts[id].portfolio) ? accAccounts.portfolios : [...accAccounts.portfolios, accountData.accounts[id].portfolio],
                    [accountData.accounts[id].portfolio]: {
                        author: accountData.overview.author[accountData.overview.slug.indexOf(accountData.accounts[id].portfolio)],
                        name: accountData.overview.title[accountData.overview.slug.indexOf(accountData.accounts[id].portfolio)],
                        allocation: (accAccounts[accountData.accounts[id].portfolio]?.allocation ?? 0) + accountData.accounts[id].value / totalValue,
                        value: (accAccounts[accountData.accounts[id].portfolio]?.value ?? 0) + accountData.accounts[id].value,
                    }
                }
            ),
            {
                name: ids.length === 1 ? accountData.accounts[ids[0]]?.name : JSON.stringify(ids),
                portfolio: ids.length === 1 ? accountData.accounts[ids[0]]?.portfolio : undefined,
                value: 0,
                assets: [],
                portfolios: [],
                lastRebalanced: ids.length === 1 ? accountData.accounts[ids[0]]?.lastRebalanced : undefined,
                needsRebalancing: false,
            }
        )

        const info2 = {
            ...info,
            finishRebalancing: () => {
                //DEBUG_MSG(`accountData=${JSON.stringify(accountData)}`)
                //DEBUG_MSG(`memberMeta.accounts=${JSON.stringify(memberMeta.accounts)}`)
                //return

                // FIXME: when asset positions are eliminated, they
                // are only removed from the holdings2.assets array,
                // but the [ticker] property remains
                const newAccounts = ids.reduce(
                    (accAccounts, id) => {
                        const assets = accountData.accounts[id].assets
                            .filter(ticker => ticker !== "---")
                            .filter(ticker => accountData.accounts[id][ticker].shares !== 0)
                        return assets.reduce(
                            (accAssets, ticker) => ({
                                ...accAssets,
                                [id]: {
                                    ...accAssets[id],
                                    holdings2: {
                                        ...accAssets[id].holdings2,
                                        [ticker]: {
                                            name: accountData.accounts[id][ticker].name,
                                            shares: Math.round(accountData.accounts[id][ticker].shares * 1000) / 1000,
                                        }
                                    }
                                }
                            }),
                            {
                                ...accAccounts,
                                [id]: {
                                    ...memberMeta.accounts[id],
                                    holdings: accountData.overview.rebalLast[accountData.overview.slug.indexOf(accountData.accounts[id].portfolio)],
                                    holdings2: {
                                        assets,
                                    }
                                }
                            }
                        )
                    },
                    {
                        //list: memberMeta?.accounts?.list,
                        ...memberMeta?.accounts,
                    }
                )
                //DEBUG_MSG(`newAccounts=${JSON.stringify(newAccounts)}`)
                setMemberMeta({ accounts: newAccounts })
            }
        }

        //DEBUG_MSG(JSON.stringify(info2))
        setGroupInfo(info2)
    }, [ids, accountData, memberMeta, setMemberMeta])

    return theGroupInfo
}

//------------------------------------------------------------------------------

export const useAccountInfo = (id) => {
    const [theIds, setIds] = useState(undefined)
    useEffect(() => {
        setIds([id])
    }, [id])

    const accountInfo = useAccountGroupInfo(theIds)
    const [memberMeta, setMemberMeta] = useMemberdata()
    const portfolioInfo = usePortfolioInfo(accountInfo?.portfolio)

    const [theInfo, setInfo] = useState()
    useEffect(() => {
        setInfo({
            ...accountInfo,
            setTotalValue: (value) => {
                DEBUG_MSG(`value=${value}`)
                const newAccounts = {
                    ...memberMeta?.accounts,
                    [id]: {
                        ...memberMeta?.accounts?.[id],
                        value: value / portfolioInfo.navLast,
                        holdings: null,
                    }
                }
                setMemberMeta({
                    accounts: newAccounts,
                })
            },
            setName: (name) => {
                DEBUG_MSG(`name=${name}`)
                const newAccounts = {
                    ...memberMeta?.accounts,
                    [id]: {
                        ...memberMeta?.accounts?.[id],
                        name: name,
                    }
                }
                setMemberMeta({
                    accounts: newAccounts,
                })
            },
            delete: () => {
                const newAccounts = {
                    ...memberMeta?.accounts,
                    list: memberMeta?.accounts?.list?.filter(i => i !== parseInt(id)),
                    [id]: null,
                }
                setMemberMeta({
                    accounts: newAccounts
                })
            },
        })
    }, [id, accountInfo, portfolioInfo, memberMeta, setMemberMeta])

    return theInfo
}

//------------------------------------------------------------------------------
/**
 * Hook to create backtest spec for use w/ backtesting cloud function.
 * 
 *     {   totalValue: 5975.577212833086,
 *         alloc: {
 *             tt-mean-kitty: 0.16604601551275877,
 *             tt-quick-change: 0.8339539844872413
 *     }
 * @param {*} ids account IDs to consolidate
 * @returns backtesting spec
 */
export const useBacktestSpec = (ids = null) => {
    const accountInfo = useAccountGroupInfo(ids)
    const portfolioOverview = usePortfolioOverview()

    const [theSpec, setSpec] = useState()
    useEffect(() => {
        //DEBUG_MSG(`accountInfo=${JSON.stringify(accountInfo)}`)
        if (!accountInfo?.portfolios)
            return

        const alloc = accountInfo.portfolios
            .reduce((acc, slug) => ({
                ...acc,
                [portfolioOverview.slug2[portfolioOverview.slug.indexOf(slug)]]: accountInfo[slug].allocation
            }), {})
        const spec = {
            totalValue: accountInfo.value,
            alloc,
        }
        setSpec(spec)
        DEBUG_MSG(`spec=${JSON.stringify(spec)}`)
    }, [accountInfo, portfolioOverview])

    return theSpec
}

//------------------------------------------------------------------------------
/**
 * React hook to execute custom backtest.
 * 
 * This hook takes in a portfolio spec, and runs a custom backtest on
 * the backend. It then returns the backtest result.
 * 
 * @param {*} spec object with backtest spec
 * @returns object with backtest result
 */
export const useBacktest = (spec) => {
    const backtest = useBacktestApi(spec)

    // backtest format:
    // {
    //     metrics: {},
    //     spec: {},
    //     chartData: {
    //         t: [],
    //         c: [],
    //         benchmark: {
    //             c: [],
    //         },
    //     },
    //     assetAlloc: {},
    //     timestamp: "",
    // }

    const [theData, setData] = useState()
    useEffect(() => {
        if (!backtest) return

        const intlDate = new Intl.DateTimeFormat('en-US', {
            timeZone: "America/New_York"
        });
        const metricsTable = {
            columns: ["Metric", "Total Portfolio", "S&P 500 TR"],
            "Metric": [
                `Simulation Start (${intlDate.format(new Date(Date.parse(backtest.metrics.firstBar)))})`,
                `Simulation End (${intlDate.format(new Date(Date.parse(backtest.metrics.lastBar)))})`,
                "Simulation Period",
                "Compound Annual Growth Rate",
                "Stdev of Returns (Monthly, Annualized)",
                "Maximum Drawdown (Daily)",
                "Maximum Flat Days",
                "Sharpe Ratio (Rf=T-Bill, Monthly, Annualized)",
                "Beta (To S&P 500, Monthly)",
                "Ulcer Index",
                "Ulcer Performance Index (Martin Ratio)",
            ],
            "Total Portfolio": [
                formatCurrency(backtest.metrics.startValue[0]),
                formatCurrency(backtest.metrics.endValue[0]),
                `${backtest.metrics.reportYears.toFixed(1)} years`,
                formatPercent(backtest.metrics.cagr[0]),
                formatPercent(backtest.metrics.stdev[0]),
                formatPercent(backtest.metrics.mdd[0]),
                `${backtest.metrics.mfd[0]} days`,
                backtest.metrics.sharpe[0].toFixed(2),
                backtest.metrics.beta.toFixed(2),
                formatPercent(backtest.metrics.ulcer[0]),
                backtest.metrics.martin[0].toFixed(2),
            ],
            "S&P 500 TR": [
                formatCurrency(1000),
                formatCurrency(1000 * backtest.metrics.endValue[1] / backtest.metrics.startValue[1]),
                `${backtest.metrics.reportYears.toFixed(1)} years`,
                formatPercent(backtest.metrics.cagr[1]),
                formatPercent(backtest.metrics.stdev[1]),
                formatPercent(backtest.metrics.mdd[1]),
                `${backtest.metrics.mfd[1]} days`,
                backtest.metrics.sharpe[1].toFixed(2),
                (1).toFixed(2),
                formatPercent(backtest.metrics.ulcer[1]),
                backtest.metrics.martin[1].toFixed(2),
            ],
        }
        const chartTable = {
            columns: ["Date", "Total Portfolio", "S&P 500"],
            "Date": backtest.chartData.t.map(t => {
                const dt = t.split('T')
                const d = dt[0].split('-')
                return `${d[1]}/${d[2]}/${d[0]}`
            }),
            "Total Portfolio": backtest.chartData.c,
            "S&P 500": backtest.chartData.benchmark.c.map(c => 1000 * c / backtest.chartData.benchmark.c[0]),
        }
        setData({
            metricsTable,
            chartTable,
        })
        //setData(backtest)
    }, [backtest])

    return theData
}

//==============================================================================
// end of file
