import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`What's included:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Trade with Basic portfolios`}</strong><br />{`
Choose from over a dozen portfolios designed by renowned industry experts`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#charts-metrics"
          }}>{`Evaluate charts and metrics for all portfolios`}</a></strong><br />{`
Review historical performance and drawdowns vs. benchmarks`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#dashboard"
          }}>{`Customize your dashboard`}</a></strong><br />{`
Quickly compare equity charts and review the recent performance of your portfolios`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#position-size-calc"
          }}>{`Calculate your position sizes`}</a></strong><br />{`
Rebalance easily by calculating the correct number of shares to hold`}</li>
      <li parentName="ul"><strong parentName="li">{`Get a 14-day trial of all Premium features`}</strong><br />{`
Get access to all Premium portfolios - no credit card required`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      