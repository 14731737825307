//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/container
// Description: Layout primitives.
// Created:     FUB, May 10, 2022
//==============================================================================

//import React from "react"
import styled from "styled-components"

//------------------------------------------------------------------------------
export const Container = styled.div`
    padding: ${props => props.theme.spacings.normal};
    overflow: hidden;
`

export const Border = styled(Container)`
    border-radius: ${props => props.theme.dimensions.cornerRadius};
    border: ${props => props.theme.dimensions.lineThickness} solid ${props => props.theme.colors.accent};
`

export const Box = styled(Container)`
    //border: ${props => props.theme.dimensions.lineThickness} solid ${props => props.theme.colors.decorative};
    border-radius: ${props => props.theme.dimensions.cornerRadius};
    background: ${props => props.theme.gradients.light};
    box-shadow: ${props => props.theme.shadows.box};
`

export const BoxBorder = styled(Container)`
    border: ${props => props.theme.dimensions.lineThickness} solid ${props => props.theme.colors.accent};
    border-radius: ${props => props.theme.dimensions.cornerRadius};
    background: ${props => props.theme.gradients.light};
    box-shadow: ${props => props.theme.shadows.box};
`
//==============================================================================
// end of file
