//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/portfolio/monte-carlo
// Description: Widgets for portfolio display
// Created:     FUB, May 05, 2022
//==============================================================================

import React from "react"
//import styled from "styled-components"

import { usePortfolioData } from "../../../utils/portfolio-data"
import { Lightbox } from "../lightbox"

//------------------------------------------------------------------------------
export const MonteCarlo = ({ portfolio }) => {
    const data = usePortfolioData(portfolio)
    //const imgUrl = data?.monteCarloChart
    const imgUrl = data?.monteCarloChart2

    /*return imgUrl ? (<div>
        <Img
            src={imgUrl}
            alt="TuringTrader.com"
        />
    </div>) : null*/

    return imgUrl ? (<div>
        <Lightbox title="Monte Carlo Analysis of Returns and Drawdowns" src={imgUrl}>
            <img css={`width:100%;`}
                src={imgUrl}
                alt="TuringTrader.com"
            />
        </Lightbox>
    </div>) : null
}

//==============================================================================
// end of file
