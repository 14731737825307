//==============================================================================
// Project:     www.TuringTrader.com
// Name:        cookies.js
// Description: Utility functions for cookies.
// History:     2020ii22, FUB, created
//==============================================================================

export function setCookie(name, value, exdays = 9999) {
    if (typeof document !== `undefined`) {
        //let exdays = 9999
        let d = new Date()
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
        let expires = "expires=" + d.toUTCString()
        document.cookie = name + "=" + value + ";" + expires + ";path=/"
    }
}

export function getCookie(cname) {
    if (typeof document !== `undefined`) {
        let name = cname + "="
        let decodedCookie = decodeURIComponent(document.cookie)
        let ca = decodedCookie.split(";")
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === " ") {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length)
            }
        }
    }
    return ""
}

export function deleteCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
}

//==============================================================================
// end of file
