//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/indicators
// Description: Widgets for indicator display
// Created:     FUB, June 23, 2022
//==============================================================================

import React from "react"
//import styled from "styled-components"

import { useMarketOverview } from "../../utils/portfolio-data"
import { Lightbox } from "./lightbox"


//------------------------------------------------------------------------------
const IndicatorChart = (chartField, title) => {
    const data = useMarketOverview()
    const imgUrl = data?.[chartField]

    return imgUrl ? (<div>
        <Lightbox title={title} src={imgUrl}>
            <img css={`width:100%;`}
                src={imgUrl}
                alt="TuringTrader.com"
            />
        </Lightbox>
    </div>) : null
}

//------------------------------------------------------------------------------
export const StockMarketChart = () =>
    IndicatorChart(
        "stockMarketChart",
        "Stock Market: Cumulative Returns & Volatility"
    )

export const BondMarketChart = () =>
    IndicatorChart(
        "bondMarketChart",
        "Bond Market: Cumulative Returns & Yields"
    )

export const CommoditiesChart = () =>
    IndicatorChart(
        "commoditiesChart",
        "Commodities: Cumulative Returns & Sub-Indices"
    )

export const CurrenciesChart = () =>
    IndicatorChart(
        "currenciesChart",
        "US Dollar: USD Index & Currency Pairs"
    )

export const EconomyChart = () =>
    IndicatorChart(
        "economyChart",
        "US Economy: Big-Four Indicators"
    )

//==============================================================================
// end of file
