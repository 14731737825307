//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/tt-branding
// Description: TuringTrader Logo
// Created:     FUB, July 02, 2022
//==============================================================================

import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const Logo = ({ className }) => (
    <div
        css={`
        `}
    >
        <StaticImage 
            className={className} 
            src="../../images/brand/logo-512x512.png"
            alt="TuringTrader.com"
        />
    </div>
)

export const LogoPlusText = ({ className }) => (
    <div
        css={`
        `}
    >
        <StaticImage 
            className={className} 
            //src="../../images/brand/logo+text-1600x500.png" alt="TuringTrader.com"
            src="../../images/brand/logo+text-200x50.png"
            alt="TuringTrader.com"
            //loading="eager"
        />
    </div>
)

//==============================================================================
// end of file
