import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { FiAlertTriangle } from "react-icons/fi";
import { BiMessageSquareAdd, BiBookmarkHeart, BiTime } from "react-icons/bi";
//import { BsInfoCircle } from "react-icons/bs"
import { BsExclamationTriangle } from "react-icons/bs";
import { BsSignStop } from "react-icons/bs";
//import { GiChampagneCork } from "react-icons/gi"
//import { BsCalendarCheck } from "react-icons/bs"
//import {  FaTools } from "react-icons/fa"
import { Container } from "../widgets/container";
import { useMembership } from "../../utils/member-data";
import { ButtonPrimary } from "../widgets/button";
import { Link } from "../widgets/link";
export const BannerContainer = styled(Container)`
    display: flex;
    gap: 2rem;
    xx-align-items: center;
    color: ${props => props.theme.colors.bgCanvas};
    background-color: ${props => props.theme.colors.text};
`;
export const BannerMsg = styled.div`
    text-align: center;
    flex: 1;
    a, strong, em {
        color: ${props => props.theme.colors.decorative};
    }
`;
export const BannerIcon = styled.div`
    flex: 0 0 4rem;
    font-size: 3rem;
    color: ${props => props.theme.colors.decorative};
`;
export const BannerCondition = ({
  children
}) => {
  const membership = useMembership();
  const [hasCoupon, setCoupon] = useState(false);
  useEffect(() => {
    setCoupon(
    //Date.now() < 1701158340000 && // Mon, 11/27/2023, 23:59 Pacific
    //Date.now() < 1704182340000 && // Mon, 01/01/2024, 23:59 Pacific
    Date.now() < 1704787199000 &&
    // Mon, 01/08/2024, 23:59:59 Pacific
    membership?.level?.paid === 1 && false);
  }, [membership]);
  const [trialDays, setTrialDays] = useState(0);
  useEffect(() => {
    setTrialDays((Date.parse(membership?.user?.created) + 14 * 24 * 60 * 60 * 1000 - Date.now()) / (24 * 60 * 60 * 1000));
  }, [membership]);
  const isBlacklisted = false; // membership?.user?.isBlacklisted
  const isTrialing = false; // membership?.features?.premium?.hasTrialAccess
  const showUpsell = hasCoupon || isBlacklisted || isTrialing;
  const showLoggedIn = membership?.level?.paid > 0;
  const showLoggedOut = membership?.level?.paid === 0;
  const showBanner = showLoggedIn || showLoggedOut || showUpsell;
  return showBanner === true ? <BannerContainer mdxType="BannerContainer">
        <BannerIcon mdxType="BannerIcon">
            <BsExclamationTriangle mdxType="BsExclamationTriangle" />
        </BannerIcon>
        <BannerMsg mdxType="BannerMsg">
            {isBlacklisted && <div>
                <strong>
                    {membership?.user?.firstName} - your free trial has been blocked
                </strong>.
                To continue using our Premium features, please upgrade your account,
                or <Link href="/company/contact/" mdxType="Link">contact us for help</Link>.
            </div>}
            {isTrialing && <div>
                    Welcome to TuringTrader, {membership?.user?.firstName}! You have{" "}
                    <strong>{trialDays.toFixed(0)} days</strong> left
                    to try our <em>Premium</em> features and portfolios.
            </div>}
            {hasCoupon && <div>
                Use coupon code <strong>HOLIDAYS2023</strong> before Sunday, January 7th,
                to receive <strong>20% off</strong> your first bill.
            </div>}
            {showUpsell && <div>
                    <br />
                    <ButtonPrimary text="Upgrade Now" to="/sign-up" mdxType="ButtonPrimary" />
            </div>}
            {children}
        </BannerMsg>
    </BannerContainer> : null;
};
export const _frontmatter = {};
const layoutProps = {
  BannerContainer,
  BannerMsg,
  BannerIcon,
  BannerCondition,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <BannerCondition mdxType="BannerCondition">
      <p>{`This site is only available as a fallback. Head over to our `}<a parentName="p" {...{
          "href": "https://turingtrader.com/"
        }}>{`main site`}</a>{` for a new and refreshed experience.`}</p>
    </BannerCondition>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      